import React from 'react';
import cx from 'classnames';

interface SwitchProps {
  className?: string;
  value: boolean;
  label: string;
  onChange: (value: boolean) => void;
  dataToolTipId?: string;
  dataToolTipHtml?: string;
}

const Switch: React.FC<SwitchProps> = ({
  className,
  value,
  label,
  onChange,
  dataToolTipId,
  dataToolTipHtml,
}) => {
  return (
    <div
      onClick={() => onChange(!value)}
      className={cx(
        'flex items-center gap-x-3 cursor-pointer hover:text-green-100',
        className
      )}
    >
      <button
        className={`w-10 h-5 rounded-full flex items-center transition duration-300 focus:outline-none ${value ? 'bg-green-100' : 'bg-neutral-120'}`}
      >
        <div
          className={`w-4 h-4 relative rounded-full transition duration-500 transform bg-white ${value ? 'translate-x-[22px]' : 'translate-x-0.5'}`}
        ></div>
      </button>
      <span data-tooltip-id={dataToolTipId} data-tooltip-html={dataToolTipHtml}>
        {label}
      </span>
    </div>
  );
};

export default Switch;
