import { Asset } from '@/interfaces/Cluster';
import '@/styles/components/videoTile.scss';
import Analytics from 'src/utils/analytics';

interface VideoTile {
  video: Asset;
}

const VideoTile: React.FC<VideoTile> = ({ video }) => {
  const videoDate = new Date(0);
  videoDate.setUTCSeconds(video.date_added);

  return (
    <div className="video_tile">
      {/* <img
        width="170"
        height="106"
        src="https://videopromotion.club/assets/images/default-video-thumbnail.jpg"
        alt="video_thumbnail"
      /> */}

      <div className="content">
        <a
          href={`/search/${video.id}`}
          target="_blank"
          onClick={() => {
            Analytics.log('zeitgeist_video_card_view', {
              video_id: video.id,
              video_timestamp: video.date_added,
            });
          }}
        >
          <div className="video-title">{video.title}</div>
          <div className="video-summary">{video.summary}</div>
          <div className="row">
            <div className="added">{videoDate.toLocaleDateString('en-US')}</div>
            <div className="view">
              View&nbsp;
              <i className="block w-3 h-3 bg-ico-view-video bg-no-repeat bg-center"></i>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default VideoTile;
