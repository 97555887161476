/**
 *
 * @param seconds float
 * @returns string
 * This function accepts a duration in seconds and returns a string in the format 'mm:ss'.
 */
export default function secondsToMinutes(seconds: number): string {
  const minutes = Math.floor(seconds / 60),
    remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}
