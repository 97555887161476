import Root from './root';
import Zeitgeist from './zeitgeist';
import EDayZeitgeist from './eday_zeitgeist';
import Contact from './contact';
import Notifications from './notifications';
import ClusterDebug from './cluster_debug';
import PrivacyInfo from './privacy';
import Terms from './terms';
import VideoSubmission from './video_submission';
import NotFound from './not-found';

export {
  ClusterDebug,
  Contact,
  Notifications,
  Root,
  Zeitgeist,
  EDayZeitgeist,
  PrivacyInfo,
  Terms,
  VideoSubmission,
  NotFound,
};
