import React from 'react';

export const VideoCardSuspense: React.FC = () => (
  <div
    className={`transition-all bg-white border border-[#CDCDCD] rounded-[10px] overflow-hidden relative group w-[calc(50%-6px)]`}
  >
    <div className="flex">
      <div className="w-full">
        <div className="block w-full h-0 relative pb-[56.25%] bg-neutral-80 animate-pulse"></div>
        <div className="block p-5">
          <span className="block h-4 w-full rounded-md bg-neutral-80 mb-1 animate-pulse"></span>
          <span className="block rounded-md bg-neutral-80 mb-4 h-16 animate-pulse"></span>
          <div className="block flex flex-wrap gap-1 mb-2.5">
            <span className="h-4 w-32 bg-neutral-80 rounded-md animate-pulse relative shrink-0"></span>
            <span className="h-4 w-36 bg-neutral-80 rounded-md animate-pulse relative shrink-0 "></span>
            <span className="h-4 w-20 bg-neutral-80 rounded-md animate-pulse relative shrink-0"></span>
            <span className="h-4 w-64 bg-neutral-80 rounded-md animate-pulse relative shrink-0"></span>
            <span className="h-4 w-28 bg-neutral-80 rounded-md animate-pulse relative shrink-0"></span>
          </div>
          <div className="flex gap-2 flex-wrap">
            <span className="animate-pulse w-28 h-6 py-1 px-2 rounded-md bg-neutral-80"></span>
            <span className="animate-pulse w-28 h-6 py-1 px-2 rounded-md bg-neutral-80"></span>
            <span className="animate-pulse w-28 h-6 py-1 px-2 rounded-md bg-neutral-80"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
);
