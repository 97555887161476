import { User } from 'firebase/auth';
import { AssetCollection, EditingConfig, Overlay } from 'shared-models';
import { FIRESTORE_COLLECTION_NAMES } from 'src/defaults';
import FLAGS from 'src/featureFlags';
import Asset from 'src/interfaces/Asset';
import Utterance from 'src/interfaces/UtteranceWithId';
import Analytics from 'src/utils/analytics';
import EventBus from 'src/utils/eventBus';
import { API_URL, getActiveAssetLibrary } from './api';


export interface PineconeAsset {
  id: string;
}

export interface VideoIdSearchParams extends OptionalVideoIdSearchParams {
  searchQuery: string;
  localSearchType: string;
  localK: number;
}

export interface OptionalVideoIdSearchParams {
  since?: string;
  with_score?: string;
}

interface submitEditRequestParams {
  assets: Asset[];
  selectedUtterances: Utterance[];
  config: EditingConfig;
  onFailure: () => void;
  onSuccess: () => void;
}

export default class MachineApi {
  user: User | null;

  constructor(user: User | null) {
    this.user = user;
  }

  async fetchVideoIds(params: VideoIdSearchParams): Promise<PineconeAsset[]> {
    if (this.user === null) {
      return [];
    }
    try {
      const idToken = await this.user.getIdToken();

      const url = new URL(`${API_URL}/video_ids`);
      url.searchParams.append('query', params.searchQuery);
      url.searchParams.append('search_type', params.localSearchType);
      url.searchParams.append('k', params.localK.toString());
      url.searchParams.append(
        'asset_library',
        FIRESTORE_COLLECTION_NAMES.ASSETS
      );

      if (params.since) {
        url.searchParams.append('since', params.since);
      }
      if (params.with_score) {
        url.searchParams.append('with_score', params.with_score);
      }

      const response = await fetch(url.toString(), {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      const data: { results: PineconeAsset[] } = await response.json();
      if (!('results' in data)) {
        console.error('Error: invalid API response');
        return [];
      }

      return data.results;
    } catch (error) {
      console.error('Error fetching videos:', error);
      EventBus.$emit('modal:edit:show', 'error');
      return [];
    }
  }

  async fetchOverlays(): Promise<Overlay[]> {
    if (this.user === null) {
      return [];
    }
    try {
      const idToken = await this.user.getIdToken();
      const response = await fetch(`${API_URL}/overlays`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch overlays');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      EventBus.$emit('modal:edit:show', 'error');
      console.error('Error fetching overlays:', error);
      return [];
    }
  }

  async createUserCollection({ id, title }: { id?: string; title: string }) {
    return await this.createCollectionRaw({
      id,
      title,
      asset_library: getActiveAssetLibrary(),
    });
  }

  async createOrganizationCollection({
    id,
    title,
    owning_organization_id,
  }: {
    id?: string;
    title: string;
    owning_organization_id: string;
  }) {
    return await this.createCollectionRaw({
      id,
      title,
      asset_library: getActiveAssetLibrary(),
      owning_organization_id,
    });
  }

  async createCollectionRaw(input: Partial<AssetCollection>) {
    if (!this.user) throw new Error('must be logged in.');
    const idToken = await this.user.getIdToken();
    const url = new URL(`${API_URL}/collections`);
    const response = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(input),
    });
    if (!response.ok) {
      console.error(
        'Failed to create collection. Response code: %d. Response body: %s',
        response.status,
        await response.text()
      );
      throw new Error(`Failed to create collection: ${response.status}`);
    }
    return await response.json();
  }

  async getCollection(collectionId: string): Promise<AssetCollection> {
    if (!this.user) throw new Error('must be logged in');
    const idToken = await this.user.getIdToken();
    const url = new URL(`${API_URL}/collections/${collectionId}`);
    const response = await fetch(url.toString(), {
      headers: { Authorization: `Bearer ${idToken}` },
      method: 'GET',
    });
    if (!response.ok) {
      console.error(
        'Failed to get collection. Response code: %d. Response body: %s',
        response.status,
        await response.text()
      );
      throw new Error(`Failed to get collection: ${response.status}`);
    }
    return (await response.json()) as AssetCollection;
  }

  async getCollections({
    onlyEditable,
  }: { onlyEditable?: boolean } = {}): Promise<{ results: AssetCollection[] }> {
    if (!this.user) throw new Error('must be logged in');
    const idToken = await this.user.getIdToken();

    const url = new URL(`${API_URL}/collections`);
    if (onlyEditable !== undefined) {
      url.searchParams.set('only_editable', onlyEditable ? '1' : '0');
    }
    const response = await fetch(url.toString(), {
      headers: { Authorization: `Bearer ${idToken}` },
      method: 'GET',
    });
    if (!response.ok) {
      console.error(
        'Failed to get collection. Response code: %d. Response body: %s',
        response.status,
        await response.text()
      );
      throw new Error(`Failed to get collection: ${response.status}`);
    }
    return await response.json();
  }

  async addVideosToCollection(
    collectionId: string,
    assetIds: string[]
  ): Promise<void> {
    if (!this.user) throw new Error('must be logged in');
    const idToken = await this.user.getIdToken();
    const url = new URL(`${API_URL}/collections/${collectionId}/videos`);
    const response = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        add_video_ids: assetIds,
      }),
    });
    if (!response.ok) {
      console.error(
        'Failed to add videos to collection. Response code: %d. Response body: %s',
        response.status,
        await response.text()
      );
      throw new Error(`Failed to add videos to collection: ${response.status}`);
    }
  }

  async removeVideosFromCollection(
    collectionId: string,
    assetIds: string[]
  ): Promise<void> {
    if (!this.user) throw new Error('must be logged in');
    const idToken = await this.user.getIdToken();
    const url = new URL(`${API_URL}/collections/${collectionId}/videos`);
    const response = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        remove_video_ids: assetIds,
      }),
    });
    if (!response.ok) {
      console.error(
        'Failed to remove videos from collection. Response code: %d. Response body: %s',
        response.status,
        await response.text()
      );
      throw new Error(
        `Failed to remove videos to collection: ${response.status}`
      );
    }
  }

  async submitEditRequest(params: submitEditRequestParams) {
    if (FLAGS.ASSET_LIBRARY.MULTICLIP_EDITS) {
      throw new Error('Multiclip edits are not supported');
    }
    const { assets, selectedUtterances, config, onSuccess, onFailure } = params,
      asset = assets[0];

    if (!asset.transcription) {
      console.error('No transcription available for this asset');
      onFailure();
      return;
    }

    if (!this.user) {
      alert('You must be logged in to submit an asset.');
      onFailure();
      return;
    }

    try {
      const idToken = await this.user.getIdToken();

      const derefUtterances = selectedUtterances.map((u) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, assetId, ...rest } = u;
        return rest;
      });

      const data = {
        asset_id: asset.id,
        title: asset?.annotations?.title ?? asset.id,
        selected_utterances: derefUtterances,
        editing_config: config,
      };

      const url = `${API_URL}/library/${getActiveAssetLibrary()}/edit_video`;
      console.log('Submitting edit request to %s: %o', url, data);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to submit video editing job');
      }

      const result = await response.json();
      console.log('Job submitted successfully:', result);
    } catch (error) {
      console.error('Error submitting video editing job:', error);
      Analytics.log('video_submit_for_editing_error', {
        video_id: asset.id,
        video_timestamp: asset.date_added,
        editing_config: config,
      });
      EventBus.$emit('modal:edit:show', 'error');
      alert('Failed to submit video editing job. Please try again.');
    }
    onSuccess();
  }
}
