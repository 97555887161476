export default {
  ASSET_LIBRARY: {
    MULTICLIP_CARDS: false,
    MULTICLIP_EDITS: false,
    SHOW_ANNOTATIONS_SWITCH: true,
    SHOW_FILTERS_SWITCH: true,
    SORT_BY: true,
    SORT_BY_RELEVANCE: true,
    SEARCH_TRANSCRIPT: true,
    TRENDING: false,
    TRANSCRIPT_HIGHLIGHTS: true,
    TRANSCRIPT_BLUE_ROSE_SCORES: false,
    SHOW_SOURCE_VIDEO: false,
    SHOW_BOOKMARK_BUTTON: true,
  },
  NOTIFICATIONS: {
    FILTERS: false,
  },
  CONTACT_US: true,
  VIDEO_SUBMISSION: true,
  TERMS_AND_CONDITIONS: false,
  ZEITGEIST: {
    DEBUG: false,
    ALL_RUNS: false,
  },
  ASSET_SEARCH: {
    DATE: true,
    SCORE: true,
  }
};
