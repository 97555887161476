import privacyPdf from '@/assets/docs/privacy.pdf';
import '@/styles/privacy_terms.scss';

const PrivacyInfo: React.FC = () => {
  const tableData = {
    headers: [
      'Category of Personal Data (and Examples)',
      'Business or Commercial Purpose(s) for Collection',
      'Categories of Third Parties With Whom We Disclose this Personal Data',
    ],
    rows: [
      [
        'Profile or Contact Data such as first and last name, email, phone number, Google email address.',
        [
          'Providing, Customizing and Improving the Services',
          'Marketing the Services',
          'Corresponding with You',
        ],
        [
          'Service Providers',
          'Analytics Partners',
          'Parties You Authorize, Access or Authenticate',
        ],
      ],
      [
        'Identifiers such as your login information to our Services.',
        [
          'Providing, Customizing and Improving the Services',
          'Marketing the Services',
          'Corresponding with You',
        ],
        [
          'Service Providers',
          'Analytics Partners',
          'Parties You Authorize, Access or Authenticate',
        ],
      ],
      [
        'Payment Data such as financial account information, payment card type, last 4 digits of payment card, and billing address, phone number, and email.',
        [
          'Providing, Customizing and Improving the Services',
          'Corresponding with You',
        ],
        [
          'Service Providers (specifically our payment processing partner, currently Stripe, Inc.)',
        ],
      ],
      [
        'Device/IP Data such as IP address, device ID, domain server, and type of device/ operating system/browser used to access the Services.',
        ['Providing, Customizing and Improving the Services'],
        [
          'Service Providers',
          'Analytics Partners',
          'Parties You Authorize, Access or Authenticate',
        ],
      ],
      [
        'Web Analytics such as web page interactions, referring webpage/source through which you accessed the Services, non-identifiable request IDs, and statistics associated with the interaction between device or browser and the Services.',
        [
          'Providing, Customizing and Improving the Services',
          'Marketing the Services',
          'Corresponding with You',
        ],
        [
          'Service Providers',
          'Analytics Partners',
          'Parties You Authorize, Access or Authenticate',
        ],
      ],
      [
        'Professional or Employment-Related Data such as your employer.',
        [
          'Providing, Customizing and Improving the Services',
          'Marketing the Services',
          'Corresponding with You',
        ],
        [
          'Service Providers',
          'Analytics Partners',
          'Parties You Authorize, Access or Authenticate',
        ],
      ],
      [
        'Geolocation Data such as IP-address-based location information.',
        ['Providing, Customizing and Improving the Services'],
        [
          'Service Providers',
          'Analytics Partners',
          'Parties You Authorize, Access or Authenticate',
        ],
      ],
      [
        'Other Identifying Information that You Voluntarily Choose to Provide such as emails, letters, texts, or other communications you send us.',
        [
          'Providing, Customizing and Improving the Services',
          'Marketing the Services',
          'Corresponding with You',
        ],
        [
          'Service Providers',
          'Analytics Partners',
          'Parties You Authorize, Access or Authenticate',
        ],
      ],
    ],
  };

  return (
    <div id="privacy">
      <h1>Platform Privacy Policy</h1>
      <h4>Effective date: October 1st, 2024</h4>
      <section>
        <p>
          At Open Labs, we take your privacy seriously. Please read this Privacy
          Policy to learn how we treat your personal data. By using or accessing
          our Services in any manner, you acknowledge that you accept the
          practices and policies outlined below, and you hereby consent that we
          will collect, use and disclose your information as described in this
          Privacy Policy. This Privacy Policy governs your use of the Services
          offered by Open Labs, aside from surveys offered by Open Labs,
          including but not limited to your use of&nbsp;
          <a href="https://www.machineapp.ai/" target="_blank">
            https://www.machineapp.ai/
          </a>
          &nbsp;and&nbsp;
          <a href="https://www.machineapp.ai/login" target="_blank">
            https://www.machineapp.ai/login
          </a>
          .
        </p>
        <p>
          Remember that your use of Open Labs’ Services is at all times subject
          to our&nbsp;
          <a href="/terms" target="_blank">
            Terms of Use
          </a>
          , which incorporates this Privacy Policy. Any terms we use in this
          Policy without defining them have the definitions given to them in the
          Terms of Use.
        </p>
        <p>
          You may print a copy of this Privacy Policy by&nbsp;
          <a href={privacyPdf} target="_blank">
            clicking here
          </a>
          .
        </p>
        <p>
          As we continually work to improve our Services, we may need to change
          this Privacy Policy from time to time. We will alert you to any
          material changes by placing a notice on the Open Labs website, by
          sending you an email and/or by some other means. Please note that if
          you’ve opted not to receive legal notice emails from us (or you
          haven’t provided us with your email address), those legal notices will
          still govern your use of the Services, and you are still responsible
          for reading and understanding them. If you use the Services after any
          changes to the Privacy Policy have been posted, that means you agree
          to all of the changes.
        </p>
      </section>

      <h2>Privacy Policy Table of Contents</h2>
      <section>
        <ul>
          <li>
            <a href="#what_it_covers">What this Privacy Policy Covers</a>
          </li>
          <li>
            <a href="#personal_data">Personal Data</a>
          </li>
          <ul>
            <li>
              <a href="#personal_data-categories">
                Categories of Personal Data We Collect
              </a>
            </li>
            <li>
              <a href="#personal_data-business_purpose">
                Our Commercial or Business Purposes for Collecting or Disclosing
                Personal Data
              </a>
            </li>
            <li>
              <a href="#personal_data-source_categories">
                Categories of Sources of Personal Data
              </a>
            </li>
            <li>
              <a href="#personal_data-other">
                Other Permitted Purposes for Processing Personal Data
              </a>
            </li>
          </ul>
          <li>
            <a href="#disclose_personal_data">
              How We Disclose Your Personal Data
            </a>
          </li>
          <li>
            <a href="#tracking_tools">Tracking Tools and Opt-Out</a>
          </li>
          <li>
            <a href="#data_security">Data Security</a>
          </li>
          <li>
            <a href="#child_data">Personal Data of Children</a>
          </li>
          <li>
            <a href="#us_privacy_rights">U.S. Consumer Privacy Rights</a>
          </li>
          <li>
            <a href="#exercising_your_rights">
              Exercising Your Rights under U.S. Privacy Laws
            </a>
          </li>
          <li>
            <a href="#other_state_privacy">Other State Law Privacy Rights</a>
          </li>
          <li>
            <a href="#contact">Contact Information</a>
          </li>
        </ul>
      </section>
      <h2 id="what_it_covers">What this Privacy Policy Covers</h2>
      <section>
        <p>
          This Privacy Policy covers how we treat Personal Data that we gather
          when you access or use our Services. “Personal Data” means any
          information that identifies or relates to a particular individual and
          also includes information referred to as “personally identifiable
          information,” “personal information,” or “sensitive personal
          information” under applicable data privacy laws, rules or regulations.
          This Privacy Policy does not cover the practices of companies we don’t
          own or control or people we don’t manage.
        </p>
      </section>
      <h2 id="personal_data">Personal Data</h2>
      <h3 id="personal_data-categories">
        Categories of Personal Data We Collect
      </h3>
      <section>
        <p>
          This chart details the categories of Personal Data that we collect and
          have collected over the past 12 months:
        </p>

        <table>
          <thead>
            <tr>
              {tableData.headers.map((header: string, index: number) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.rows.map((row, index) => (
              <tr key={index}>
                <td>{row[0]}</td>
                <td>
                  <ul>
                    {(row[1] as string[]).map((item: string, index: number) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {(row[2] as string[]).map((item: string, index: number) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <h3 id="personal_data-source_categories">
        Categories of Sources of Personal Data
      </h3>
      <section>
        <p>
          We collect Personal Data about you from the following categories of
          sources:
        </p>
        <ul>
          <li>You</li>
          <ul>
            <li>
              <strong>When you provide such information directly to us.</strong>
            </li>
            <ul>
              <li>
                When you create an account or use our interactive tools and
                Services.
              </li>
              <li>
                When you voluntarily provide information in free-form text boxes
                through the Services.
              </li>
              <li>When you send us an email or otherwise contact us.</li>
            </ul>
            <li>
              <strong>
                When you use the Services and such information is collected
                automatically.
              </strong>
            </li>
            <ul>
              <li>
                Through Cookies (defined in the “Tracking Tools and Opt-Out”
                section below).
              </li>
              <li>
                If you use a location-enabled browser, we may receive
                information about your location.
              </li>
            </ul>
          </ul>
          <li>Third Parties</li>
          <ul>
            <li>
              <strong>Vendors</strong>
            </li>
            <ul>
              <li>
                We may use analytics providers to analyze how you interact and
                engage with the Services, or third parties may help us provide
                you with customer support.
              </li>
            </ul>
            <li>
              <strong>Third Party Networks</strong>
            </li>
            <ul>
              <li>
                If you provide third party network account credentials (such as
                your Google Sign-In) to us or otherwise sign in to the Services
                through a third-party site or service, some content and/or
                information in those accounts may be transmitted into your
                account with us. For the avoidance of doubt, we do not have
                access to your third party account credentials.
              </li>
            </ul>
          </ul>
        </ul>
      </section>

      <h3 id="personal_data-business_purpose">
        Our Commercial or Business Purposes for Collecting or Disclosing
        Personal Data
      </h3>
      <section>
        <ul>
          <li>Providing, Customizing and Improving the Services</li>
          <ul>
            <li>Creating and managing your account or other user profiles. </li>
            <li>
              Providing you with the products, services or information you
              request.
            </li>
            <li>
              Meeting or fulfilling the reason you provided the information to
              us.
            </li>
            <li>Providing support and assistance for the Services.</li>
            <li>
              Improving the Services, including testing, research, internal
              analytics and product development.
            </li>
            <li>Doing fraud protection, security and debugging.</li>
            <li>
              Carrying out other business purposes stated when collecting your
              Personal Data or as otherwise set forth in applicable data privacy
              laws, such as the California Consumer Privacy Act, as amended by
              the California Privacy Rights Act of 2020 (the “CCPA”), the
              Virginia Consumer Data Protection Act (the “VCDPA”), the Colorado
              Privacy Act (the “CPA”), the Connecticut Data Privacy Act (the
              “CTDPA”), or Utah Consumer Privacy Act (the “UCPA”) (collectively,
              “State Privacy Laws”).
            </li>
          </ul>
          <li>Marketing the Services</li>
          <ul>
            <li>Marketing and selling the Services.</li>
          </ul>
          <li>Corresponding with You</li>
          <ul>
            <li>
              Responding to correspondence that we receive from you, contacting
              you when necessary or requested, and sending you information about
              Open Labs or the Services.
            </li>
            <li>
              Sending emails and other communications according to your
              preferences or that display content that we think will interest
              you.
            </li>
          </ul>
        </ul>
      </section>

      <h3 id="personal_data-other">
        Other Permitted Purposes for Processing Personal Data
      </h3>
      <section>
        <p>
          In addition, each of the above referenced categories of Personal Data
          may be collected, used, and disclosed with the government, including
          law enforcement, or other parties to meet certain legal requirements
          and enforcing legal terms including: fulfilling our legal obligations
          under applicable law, regulation, court order or other legal process,
          such as preventing, detecting and investigating security incidents and
          potentially illegal or prohibited activities; protecting the rights,
          property or safety of you, Open Labs or another party; enforcing any
          agreements with you; responding to claims that any posting or other
          content violates third-party rights; and resolving disputes.
        </p>
        <p>
          We will not collect additional categories of Personal Data or use the
          Personal Data we collected for materially different, unrelated or
          incompatible purposes without providing you notice.
        </p>
      </section>

      <h2 id="disclose_personal_data">How We Disclose Your Personal Data</h2>
      <section>
        <p>
          We disclose your Personal Data to the categories of service providers
          and other parties listed in this section. Depending on state laws that
          may be applicable to you, some of these disclosures may constitute a
          “sale” of your Personal Data. For more information, please refer to
          the state-specific sections below.
        </p>
        <ul>
          <li>
            Service Providers. These parties help us provide the Services or
            perform business functions on our behalf. They include:
          </li>
          <ul>
            <li>Hosting, technology and communication providers.</li>
            <li>Support and customer service vendors. </li>
          </ul>
          <li>
            Analytics Partners. These parties provide analytics on web traffic
            or usage of the Services. They include:
          </li>
          <ul>
            <li>
              Companies that track how users found or were referred to the
              Services.
            </li>
            <li>Companies that track how users interact with the Services.</li>
          </ul>
        </ul>
      </section>

      <h3 id="legal_obligations">Legal Obligations</h3>
      <section>
        <p>
          We may disclose any Personal Data that we collect with third parties
          in conjunction with any of the activities set forth under “Other
          Permitted Purposes for Processing Personal Data” section above.
        </p>
      </section>

      <h3>Business Transfers</h3>
      <section>
        <p>
          All of your Personal Data that we collect may be transferred to a
          third party if we undergo a merger, acquisition, bankruptcy or other
          transaction in which that third party assumes control of our business
          (in whole or in part).
        </p>
      </section>

      <h3>Data that is Not Personal Data</h3>
      <section>
        <p>
          We may create aggregated, de-identified or anonymized data from the
          Personal Data we collect, including by removing information that makes
          the data personally identifiable to a particular user. We may use such
          aggregated, de-identified or anonymized data and disclose it to third
          parties for our lawful business purposes, including to analyze, build
          and improve the Services and promote our business, provided that we
          will not disclose such data in a manner that could identify you.
        </p>
      </section>

      <h2 id="tracking_tools">Tracking Tools and Opt-Out</h2>
      <section>
        <p>
          The Services use cookies and similar technologies such as pixel tags,
          web beacons, clear GIFs and JavaScript (collectively, “Cookies”) to
          enable our servers to recognize your web browser, tell us how and when
          you visit and use our Services, analyze trends, learn about our user
          base and operate and improve our Services. Cookies are small pieces of
          data– usually text files – placed on your computer, tablet, phone or
          similar device when you use that device to access our Services. We may
          also supplement the information we collect from you with information
          received from third parties, including third parties that have placed
          their own Cookies on your device(s). Please note that because of our
          use of Cookies, the Services do not support “Do Not Track” requests
          sent from a browser at this time.
        </p>
        <p>We use the following types of Cookies:</p>
        <ul>
          <li>
            <strong>Essential Cookies.</strong> Essential Cookies are required
            for providing you with features or services that you have requested.
            For example, certain Cookies enable you to log into secure areas of
            our Services. Disabling these Cookies may make certain features and
            services unavailable.
          </li>
          <li>
            <strong>Functional Cookies.</strong> Functional Cookies are used to
            record your choices and settings regarding our Services, maintain
            your preferences over time and recognize you when you return to our
            Services. These Cookies help us to personalize our content for you,
            greet you by name and remember your preferences (for example, your
            choice of language or region).
          </li>
          <li>
            <strong>Performance/Analytical Cookies.</strong>&nbsp;
            Performance/Analytical Cookies allow us to understand how visitors
            use our Services. They do this by collecting information about the
            number of visitors to the Services, what pages visitors view on our
            Services and how long visitors are viewing pages on the Services.
            Performance/Analytical Cookies also help us measure the performance
            of our advertising campaigns in order to help us improve our
            campaigns and the Services’ content for those who engage with our
            advertising. For example, Google LLC (“Google”) uses cookies in
            connection with its Google Analytics services. Google’s ability to
            use and share information collected by Google Analytics about your
            visits to the Services is subject to the Google Analytics Terms of
            Use and the Google Privacy Policy. You have the option to opt-out of
            Google’s use of Cookies by visiting the Google advertising opt-out
            page at&nbsp;
            <a href="http://www.google.com/privacy_ads.html" target="_blank">
              www.google.com/privacy_ads.html
            </a>
            &nbsp;or the Google Analytics Opt-out Browser Add-on at&nbsp;
            <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">
              https://tools.google.com/dlpage/gaoptout/
            </a>
            .
          </li>
        </ul>
        <p>
          You can decide whether or not to accept Cookies through your internet
          browser’s settings. Most browsers have an option for turning off the
          Cookie feature, which will prevent your browser from accepting new
          Cookies, as well as (depending on the sophistication of your browser
          software) allow you to decide on acceptance of each new Cookie in a
          variety of ways. You can also delete all Cookies that are already on
          your device. If you do this, however, you may have to manually adjust
          some preferences every time you visit our website and some of the
          Services and functionalities may not work.
        </p>
        <p>
          To find out more information about Cookies generally, including
          information about how to manage and delete Cookies, please visit&nbsp;
          <a href="http://www.allaboutcookies.org/" target="_blank">
            http://www.allaboutcookies.org/
          </a>
          .
        </p>
      </section>

      <h2 id="data_security">Data Security</h2>
      <section>
        <p>
          We seek to protect your Personal Data from unauthorized access, use
          and disclosure using appropriate physical, technical, organizational
          and administrative security measures based on the type of Personal
          Data and how we are processing that data. You should also help protect
          your data by appropriately selecting and protecting your password
          and/or other sign-on mechanism; limiting access to your computer or
          device and browser; and signing off after you have finished accessing
          your account. Although we work to protect the security of your account
          and other data that we hold in our records, please be aware that no
          method of transmitting data over the internet or storing data is
          completely secure.
        </p>
      </section>

      <h2>Data Retention</h2>
      <section>
        <p>
          We retain Personal Data about you for as long as necessary to provide
          you with our Services or to perform our business or commercial
          purposes for collecting your Personal Data. When establishing a
          retention period for specific categories of data, we consider who we
          collected the data from, our need for the Personal Data, why we
          collected the Personal Data, and the sensitivity of the Personal Data.
          In some cases we retain Personal Data for longer, if doing so is
          necessary to comply with our legal obligations, resolve disputes or
          collect fees owed, or is otherwise permitted or required by applicable
          law, rule or regulation. We may further retain information in an
          anonymous or aggregated form where that information would not identify
          you personally.
        </p>
        <p>For example:</p>
        <ul>
          <li>
            We retain your profile information and credentials for as long as
            you have an account with us.
          </li>
          <li>
            We retain your payment data for as long as we need to process your
            purchase or subscription.
          </li>
          <li>
            We retain your device/IP data for as long as we need it to ensure
            that our systems are working appropriately, effectively and
            efficiently.
          </li>
        </ul>
      </section>

      <h2 id="child_data">Personal Data of Children</h2>
      <section>
        <p>
          As noted in the&nbsp;
          <a href="/terms" target="_blank">
            Terms of Use
          </a>
          , we do not knowingly collect or solicit Personal Data from children
          under 18 years of age; if you are a child under the age of 18, please
          do not attempt to register for or otherwise use the Services or send
          us any Personal Data. If we learn we have collected Personal Data from
          a child under 18 years of age, we will delete that information as
          quickly as possible. If you believe that a child under 18 years of age
          may have provided Personal Data to us, please contact us at&nbsp;
          <a href="mailto:privacy@openlabsusa.org?subject=Privacy%20Rights%20Appeal">
            privacy@openlabsusa.org
          </a>
          .
        </p>
      </section>

      <h2 id="us_privacy_rights">U.S. Consumer Privacy Rights</h2>
      <section>
        <p>
          If you reside in California, Colorado, Connecticut, Virginia, and
          Utah, you may have certain rights afforded to you (as described below)
          depending on your state of residence. Please see the&nbsp;
          <a href="#exercising_your_rights">
            Exercising Your Rights under U.S. Privacy Laws
          </a>
          &nbsp; section below for instructions regarding how to exercise these
          rights. Please note that we may process Personal Data of our
          customers’ end users or employees in connection with our provision of
          certain services to our customers. If we are processing your Personal
          Data as a service provider, you should contact the entity that
          collected your Personal Data in the first instance to address your
          rights with respect to such data. Please note that your rights may be
          subject to certain conditions or exceptions in accordance with
          applicable law.
        </p>
        <p>
          If you have any questions about this section or whether any of the
          following rights apply to you, please contact us at&nbsp;
          <a href="mailto:privacy@openlabsusa.org">privacy@openlabsusa.org</a>.
        </p>

        <ul>
          <li>
            <strong>Access:</strong> You have the right to request confirmation
            of or access to the Personal Data that we process about you. You can
            also request access to a portable copy of your Personal Data.
          </li>
          <li>
            <strong>Deletion:</strong> You have the right to request that we
            delete the Personal Data that we have collected about you.
          </li>
          <li>
            <strong>Correction:</strong> You have the right to request that we
            correct any inaccurate Personal Data we have collected about you.
          </li>
          <li>
            <strong>Portability:</strong> You have the right to request a copy
            of your Personal Data in a machine-readable format, to the extent
            technically feasible.
          </li>
        </ul>
      </section>

      <h3>Targeted Advertising, “Sharing,” and “Selling”</h3>
      <section>
        We do not “sell” (as defined in the applicate State Privacy Law) or
        “share” (as defined in the CCPA) your Personal Data, or process your
        Personal Data for the purposes of cross-contextual behavioral or
        targeted advertising, whether for monetary or other valuable
        consideration, and have not done so over the last 12 months. To our
        knowledge, we do not sell, share, or process for the purposes of
        targeted advertising the Personal Data of minors under 16 years of age.
      </section>

      <h3>Limit, Opt In and/or Opt-Out of Certain Processing Activities</h3>
      <section>
        <ul>
          <li>
            Notice of Financial Incentive: From time to time, we may offer
            certain financial incentives for your use of our Services (which may
            include the collection of your Personal Data). These financial
            incentives include free trials of the Services, discounted Services
            or other promotions, as described in the ‘Free Trials and Other
            Promotions Section’ of the&nbsp;
            <a href="/terms" target="_blank">
              Terms of Use
            </a>
            . To manage your participation, including to revoke your consent to
            participate in our financial incentive program at any time, please
            contact us at privacy@openlabsusa.org.
          </li>
          <ul>
            <li>
              Our good faith estimate of the value of your Personal Data is
              reasonably related to the financial incentives. Our good faith
              estimate of the value of your Personal Data is based on the value
              of the financial incentives provided to you (via our service
              providers), less the expense related to our offering of those
              financial incentives.
            </li>
          </ul>
          <li>
            Profiling and Automated Processing: You have the right to opt-out
            from the processing of your Personal Data for the purposes of
            profiling in furtherance of decisions that produce legal or
            similarly significant effects to you, if applicable. However, we do
            not process your Personal Data in this manner.
          </li>
        </ul>
      </section>

      <h3>Anti-Discrimination</h3>
      <section>
        <p>
          We will not discriminate against you for exercising your rights under
          applicable privacy laws. We will not deny you our goods or services,
          charge you different prices or rates, or provide you a lower quality
          of goods and services if you exercise your rights under applicable
          privacy laws. However, we may offer different tiers of our Services as
          allowed by applicable data privacy laws with varying prices, rates or
          levels of quality of the goods or services you receive related to the
          value of Personal Data that we receive from you.
        </p>
      </section>

      <h3>Appealing a Denial</h3>
      <section>
        <p>
          If we refuse to take action on a request within a reasonable period of
          time after receiving your request in accordance with this section, you
          may appeal our decision. In such appeal, you must (1) provide
          sufficient information to allow us to verify that you are the person
          about whom the original request pertains and to identify the original
          request, and (2) provide a description of the basis of your appeal.
        </p>
        <p>You may appeal a decision by us using the following methods:</p>
        <ul>
          <li>
            Email us at: privacy@openlabsusa.org (title must include “Privacy
            Rights Appeal”)
          </li>
        </ul>
        <p>
          Please note that your appeal will be subject to your rights and
          obligations afforded to you under the applicable privacy law. We will
          respond to your appeal within the timer period required by applicable
          privacy law. If we deny your appeal, you have the right to contact the
          Attorney General in your state of residence (for example, the Attorney
          Generals in Virginia, Colorado, Connecticut, and Utah).
        </p>
      </section>

      <h2 id="exercising_your_rights">
        Exercising Your Rights under U.S. Privacy Laws
      </h2>
      <section>
        <p>
          To exercise the rights described in this Privacy Policy, you or, if
          you are a California, Colorado, or Connecticut resident, your
          Authorized Agent (defined below) must send us a request that (1)
          provides sufficient information to allow us to verify that you are the
          person about whom we have collected Personal Data, and (2) describes
          your request in sufficient detail to allow us to understand, evaluate
          and respond to it. Each request that meets both of these criteria will
          be considered a “Valid Request.” We may not respond to requests that
          do not meet these criteria. We will only use Personal Data provided in
          a Valid Request to verify your identity and complete your request. You
          do not need an account to submit a Valid Request.
        </p>
        <p>
          We will work to respond to your Valid Request within the time period
          required by applicable law. We will not charge you a fee for making a
          Valid Request unless your Valid Request(s) is excessive, repetitive or
          manifestly unfounded. If we determine that your Valid Request warrants
          a fee, we will notify you of the fee and explain that decision before
          completing your request.
        </p>
        <p>
          You may submit a Valid Request by emailing us at&nbsp;
          <a href="mailto:privacy@openlabsusa.org">privacy@openlabsusa.org</a>.
        </p>
        <p>
          If you are a California, Colorado, or Connecticut resident, you may
          also authorize an agent (an “Authorized Agent”) to exercise your
          rights on your behalf. To do this, you must provide your Authorized
          Agent with written permission to exercise your rights on your behalf,
          and we may request a copy of this written permission from your
          Authorized Agent when they make a request on your behalf.
        </p>
      </section>

      <h2 id="other_state_privacy">Other State Law Privacy Rights</h2>
      <h3>California Resident Rights</h3>
      <section>
        <p>
          Under California Civil Code Sections 1798.83-1798.84, California
          residents are entitled to contact us to prevent disclosure of Personal
          Data to third parties for such third parties’ direct marketing
          purposes; in order to submit such a request, please contact us
          at&nbsp;
          <a href="mailto:privacy@openlabsusa.org">privacy@openlabsusa.org</a>.
        </p>
      </section>

      <h3>Nevada Resident Rights</h3>
      <section>
        <p>
          If you are a resident of Nevada, you have the right to opt-out of the
          sale of certain Personal Data to third parties. You can exercise this
          right by contacting us at&nbsp;
          <a href="mailto:privacy@openlabsusa.org?subject=Nevada%20Do%20Not%20Sell%20Request">
            privacy@openlabsusa.org
          </a>
          &nbsp;with the subject line “Nevada Do Not Sell Request” and providing
          us with your name and the email address associated with your account.
        </p>
      </section>

      <h2 id="contact">Contact Information</h2>
      <section>
        <p>
          If you have any questions or comments about this Privacy Policy, the
          ways in which we collect and use your Personal Data or your choices
          and rights regarding such collection and use, please do not hesitate
          to contact us at:
        </p>

        <ul>
          <li>
            <a href="https://blueroseresearch.org" target="_blank">
              https://blueroseresearch.org
            </a>
          </li>
          <li>
            <a href="mailto:privacy@openlabsusa.org">privacy@openlabsusa.org</a>
          </li>
          <li>1800 M ST NW FRNT 1, #33127, Washington DC 20036-5828</li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyInfo;
