import React from 'react';
import { EditRequestNotification } from 'src/interfaces/EditRequestNotification';
import cx from 'classnames';
import Analytics from 'src/utils/analytics';

interface NotificationCardProps {
  notification: EditRequestNotification;
  checked: boolean;
  onChange: (value: EditRequestNotification) => void;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  checked,
  onChange,
}) => {
  const renderTitleLink = (title: string | null, assetId: string) => (
    <a
      href={`/search/${assetId}`}
      className="text-[#2f2f2e] hover:text-[#2a5246] underline"
    >
      {title ?? assetId}
    </a>
  );

  const uCaseStatus =
    notification.status.charAt(0).toUpperCase() +
    notification.status.slice(1),
  complete = notification.status === 'completed',
  notificationStatus = complete
    ? <>Edit request for {renderTitleLink(notification.title || notification.assetId, notification.assetId)} completed</>
    : <>Edit request for {renderTitleLink(notification.title || notification.assetId, notification.assetId)} is {notification.status}</>,
  logDownload = () => {
    Analytics.log('video_download_after_editing', {
      assetId: notification.assetId,
    });
  };

  return (
    <div
      className={cx(
        { 'bg-white/80': !notification.read },
        'transition-colors flex items-start gap-x-3 block p-5 border-t border-neutral-110 hover:bg-neutral-100'
      )}
    >
      <div className="w-4 shrink-0">
        <input
          checked={checked}
          className="w-4 h-4 rounded border-neutral-110 text-green-100 focus:ring-0 focus:outline-0 focus:ring-offset-0"
          type="checkbox"
          onChange={() => onChange(notification)}
        />
      </div>
      <div className="grow pt-1">
        <span className="block font-inter font-medium text-base text-neutral-180 mb-2">
          {uCaseStatus}
        </span>
        <span className="block font-inter text-base text-neutral-180 mb-2">
          {notificationStatus}
        </span>
        <span className="block font-inter text-xs text-neutral-140">
          {new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          }).format(notification.createdAt.toDate())}
        </span>
      </div>
      <div className="w-40 shrink-0 flex justify-between items-center">
        {complete && (
          <a
            href={notification.editedVideoUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={logDownload}
          >
            {' '}
            <button className="transition-colors flex justify-between items-center gap-x-3 rounded-md bg-green-100 py-2 px-3 hover:bg-green-120">
              <span className="font-inter text-xs text-white">Download</span>{' '}
              <i className="block w-4 h-4 bg-ico-download-white bg-center bg-no-repeat bg-contain"></i>
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default NotificationCard;
