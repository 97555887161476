/* eslint-disable @typescript-eslint/no-explicit-any */

import { analytics } from '../firebase';
import { setUserId, logEvent, setUserProperties } from 'firebase/analytics';
import { User } from 'firebase/auth';
import posthog from 'posthog-js';

class Analytics {
  user: User | null;
  warningGiven: boolean;

  constructor() {
    this.user = null;
    this.warningGiven = false;
    console.log(`[ANALYTICS] initialized`);
    if (this.isDev()) {
      console.log(`[ANALYTICS] in dev environment, no events will be logged`);
    }
  }

  log(eventName: string, eventParams?: { [key: string]: any }) {
    if (!this.shouldLogEvent()) {
      console.log(`[ANALYTICS] would logEvent ${eventName}`, eventParams);
      return;
    }
    if (eventParams) {
      logEvent(analytics, eventName, eventParams);
      posthog.capture(eventName, eventParams);
      console.log(`[ANALYTICS] logEvent ${eventName}`, eventParams);
    } else {
      logEvent(analytics, eventName);
      posthog.capture(eventName);
      console.log(`[ANALYTICS] logEvent ${eventName}`);
    }
  }

  isDev() {
    return process.env.NODE_ENV === 'development';
  }

  isTeamEmail() {
    if (!this.user) {
      return true;
    }
    const disallowedDomains = ['@3-tone.com', '@oestech.io'];
    for (const domain of disallowedDomains) {
      if (this.user.email?.includes(domain)) {
        if (!this.warningGiven) {
          console.log(
            `[ANALYTICS] blocklisted email domain found ${this.user.email}`
          );
          this.warningGiven = true;
        }
        return true;
      }
    }
    const disallowedAddresses = [
      'tudortru@gmail.com',
      'brandon.silverman@gmail.com',
      'stafford@4d53.io',
      'matthew.stafford@gmail.com',
      'russ.fagaly@gmail.com',
    ];
    for (const address of disallowedAddresses) {
      if (this.user.email === address) {
        if (!this.warningGiven) {
          console.log(`[ANALYTICS] blocklisted email found ${this.user.email}`);
          this.warningGiven = true;
        }
        return true;
      }
    }
    return false;
  }

  shouldLogEvent() {
    return !this.isDev() && !this.isTeamEmail();
  }

  setUser(user: User | null) {
    this.user = user;
    const isNotDev = !this.isDev();
    const isTeam = this.isTeamEmail();
   
    if (user) {
      const user_properties = {
        account_created_at: user.metadata.creationTime,
        last_sign_in_at: user.metadata.lastSignInTime,
        email: user.email,
      };
      if (isNotDev) {
        this.setUserId(user.uid);
        this.setUserProperties(user_properties);
        console.log(`[ANALYTICS] setUserId ${user.uid}`);
        if (isTeam) {
          console.log(`[ANALYTICS] Team email ${user.email} identified`);
          this.setUserProperties({
            is_team: true
          })
        }
      } else {
        console.log(`[ANALYTICS] would setUserId ${user.uid}`);
        console.log(`[ANALYTICS] would setUserProperties`, user_properties);
      }
    } else {
      if (isNotDev) {
        this.setUserId(null);
        console.log(`[ANALYTICS] setUserId null`);
      } else {
        console.log(`[ANALYTICS] would setUserId null`);
      }
    }
  }

  setUserId(userId: string | null) {
    setUserId(analytics, userId);
    posthog.identify(userId);
  }

  setUserProperties(properties: { [key: string]: any }) {
    setUserProperties(analytics, properties);
    posthog.setPersonProperties(properties);
  }
}

export default new Analytics();
