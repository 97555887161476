interface SearchDropdown {
  options: { name: string; value: string }[];
  selected: string;
  onChange: (newValue: string) => void;
}

const SearchDropdown: React.FC<SearchDropdown> = ({
  options,
  selected,
  onChange,
}) => {
  const findOptionByValue = (value: string) => {
    return options.find((option) => option.value === value) || options[0];
  };

  return (
    <div className="group cursor-pointer text-neutral-170 relative bg-neutral-20 rounded-xl outline-none focus:ring-0">
      <div className="flex items-center justify-between gap-x-3 h-[100%] px-4 py-2 min-w-[160px]">
        <span className="font-inter text-base">
          {findOptionByValue(selected).name}
        </span>
        <i className="block w-4 h-4 bg-ico-arrow-down bg-center bg-no-repeat"></i>
      </div>

      {/* dropdown */}
      <div className="absolute w-auto whitespace-nowrap hidden z-50 rounded-b-md top-full left-0 bg-neutral-20 border-none group-hover:block">
        <ul className="w-full">
          {options.map((option, index) => (
            <li
              key={option.value}
              className="transition-colors block font-inter text-base text-neutral-160 py-2 pl-4 pr-11 border-none  hover:bg-neutral-80 hover:text-neutral-170"
              onClick={() => onChange(options[index].value)}
            >
              {options[index].name}
            </li>
          ))}
        </ul>
      </div>
      {/* //dropdown */}
    </div>
  );
};

export default SearchDropdown;
