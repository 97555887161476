import {
  ASSET_LIBRARY_VERSION,
  FIRESTORE_COLLECTION_NAMES,
} from 'src/defaults';
import featureFlags from 'src/featureFlags';

export const API_URL = process.env.REACT_APP_API_URL;

export function getVideoUrlForClip(
  assetId: string,
  format: 'raw' | 'streaming'
): string {
  return _internalGetVideoUrlForClip(
    assetId,
    format,
    shouldShowSourceVideo() ? 'source' : 'own'
  );
}

/** Indicates whether the video shown should be the source video or the segment video. */
export function shouldShowSourceVideo(): boolean {
  return (
    featureFlags.ASSET_LIBRARY.SHOW_SOURCE_VIDEO &&
    ASSET_LIBRARY_VERSION() !== 'v1'
  );
}

function _internalGetVideoUrlForClip(
  assetId: string,
  format: 'raw' | 'streaming',
  mode: 'own' | 'source'
): string {
  const kindQualifier = mode == 'source' ? '/source' : '';
  return `${API_URL}/library/${getActiveAssetLibrary()}/clip/${encodeURIComponent(assetId)}${kindQualifier}/${format}`;
}

/**
 * Returns the active asset library.
 */
export function getActiveAssetLibrary() {
  return FIRESTORE_COLLECTION_NAMES.ASSETS;
}
