import React, { useState } from 'react';
import VideoCard from '../VideoCard';
import InputRange from './InputRange';
import Switch from '../../../shared-components/Switch';
import { useEditorConfigContext } from 'src/contexts/editorConfigProvider';
import FLAGS from 'src/featureFlags';
import DEFAULTS from 'src/defaults';
import pluralize from 'pluralize';
import { EditingConfig, Overlay } from 'shared-models';
import cx from 'classnames';
import { useAuth } from 'src/contexts/authProvider';
import EventBus from 'src/utils/eventBus';
import Analytics from 'src/utils/analytics';
import ToolTip from 'src/routes/shared-components/ToolTip';
import TOOLTIP_CONTENT from 'src/tooltips';

const EditDialog: React.FC = () => {
  const editorContext = useEditorConfigContext();

  let asset = null;
  if (FLAGS.ASSET_LIBRARY.MULTICLIP_EDITS) {
    throw new Error('Multiclip edits not supported');
  } else {
    asset = editorContext.selectedAssets[0];
  }

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(true),
    [config, setConfig] = useState<EditingConfig>(
      DEFAULTS.editConfigSelections as EditingConfig
    ),
    assetId = asset.id,
    toggle = (field: keyof EditingConfig) => {
      const newConfig: EditingConfig = { ...config };

      if (field === 'face_tracking') {
        if (!config.face_tracking) {
          newConfig.face_tracking = true;
          newConfig.scaling = 'scaled';
        } else {
          newConfig.face_tracking = false;
        }
      } else {
        // TODO figure out why Typescript is beefing about this
        newConfig[field] = !config[field] as boolean;
      }
      setConfig(newConfig);
    },
    handleChange = (
      field: keyof EditingConfig,
      value: Array<number>[] | string | Overlay | undefined
    ) => {
      setConfig({ ...config, [field]: value });
    },
    { machineApi } = useAuth(),
    submitHandler = () => {
      machineApi.submitEditRequest({
        assets: editorContext.selectedAssets,
        selectedUtterances: editorContext.selectedUtterances,
        config,
        onFailure: () => {
          console.log('Failed to submit edit request');
          EventBus.$emit('modal:edit:show', 'error');
        },
        onSuccess: () => {
          EventBus.$emit('modal:edit:show', 'edit_submitted');
          console.log('Successfully submitted edit request');
        },
      });
      Analytics.log('video_submit_for_editing', {
        assetId,
        editing_config: config,
      });
      editorContext.deactivateEditor();
    };

  return (
    <div>
      <i
        onClick={editorContext.deactivateEditor}
        className="block float-right w-4 h-4 bg-ico-close bg-center bg-no-repeat bg-contain cursor-pointer"
      ></i>
      <div className="block font-source_serif_pro text-xl text-neutral-170 mb-10">
        Edit video
      </div>

      <div className="block mb-4 max-w-[780px]">
        <p>
          Hit Submit to request a cut of your selected clips. If you’d like to
          make adjustments to things like video scaling, overlays and captions,
          make those selections before submitting.
        </p>
      </div>
      <div className="flex justify-center items-center bg-white mb-5">
        <VideoCard assetId={assetId} metadata={asset} forceExpanded={true} />
      </div>

      {/* Optional features */}
      <div className="block border border-neutral-110 rounded-[10px] overflow-hidden bg-neutral-80 mb-5">
        <div className="h-14 px-6 flex justify-between items-center border-b border-neutral-110">
          <span
            className="font-source_serif_pro text-xl text-neutral-170"
            data-tooltip-id="tooltip-optional-features"
            data-tooltip-html={TOOLTIP_CONTENT.ASSET_LIBRARY.optionalFeatures}
          >
            Optional features{' '}
            <i className="inline-block w-[13px] h-[13px] bg-center bg-contain bg-no-repeat bg-ico-info"></i>
          </span>
          <ToolTip id="tooltip-optional-features" />
          {/* <span
            onClick={setShowAdvancedOptions.bind(this, true)}
            className="font-inter font-medium text-base text-green-100 cursor-pointer"
          >
            See advanced options
          </span> */}
        </div>
        <div className="flex">
          <div className="w-1/3 px-6 py-5 border-r border-neutral-110">
            <Switch
              value={config.face_tracking}
              label="Enable face tracking"
              onChange={toggle.bind(this, 'face_tracking')}
              dataToolTipId="tooltip-face-tracking"
              dataToolTipHtml={TOOLTIP_CONTENT.ASSET_LIBRARY.enableFaceTracking}
            />
            <ToolTip id="tooltip-face-tracking" />
            <Switch
              className="mt-2"
              value={config.captioning}
              label="Add captions"
              onChange={toggle.bind(this, 'captioning')}
              dataToolTipId="tooltip-add-captions"
              dataToolTipHtml={TOOLTIP_CONTENT.ASSET_LIBRARY.addCaptions}
            />
            <ToolTip id="tooltip-add-captions" />
            <Switch
              className="mt-2"
              value={config.slideshow}
              label="Enable slideshow"
              onChange={toggle.bind(this, 'slideshow')}
              dataToolTipId="tooltip-enable-slideshow"
              dataToolTipHtml={TOOLTIP_CONTENT.ASSET_LIBRARY.enableSlideShow}
            />
            <ToolTip id="tooltip-enable-slideshow" />
          </div>
          <div className="w-1/3 px-6 py-5 border-r border-neutral-110">
            <label className="w-full" htmlFor="scaling">
              <span
                className="block font-inter text-base text-neutral-190 mb-2"
                data-tooltip-id="tooltip-video-scaling"
                data-tooltip-html={TOOLTIP_CONTENT.ASSET_LIBRARY.videoScaling}
              >
                Video scaling
              </span>
              <ToolTip id="tooltip-video-scaling" />
              <select
                className="transition-all min-w-56 bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                id="scaling"
                value={config.scaling}
                disabled={config.face_tracking}
                onChange={(evt) => handleChange('scaling', evt.target.value)}
              >
                <option value="scaled">Scaled</option>
                <option value="letterbox">Letterbox</option>
              </select>
            </label>
          </div>
          <div className="w-1/3 px-6 py-5">
            <label className="w-full" htmlFor="overlay">
              <span
                className="block font-inter text-base text-neutral-190 mb-2"
                data-tooltip-id="tooltip-overlays"
                data-tooltip-html={TOOLTIP_CONTENT.ASSET_LIBRARY.overlays}
              >
                Overlay
              </span>
              <ToolTip id="tooltip-overlays" />
              <select
                className="transition-all min-w-56 bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                id="overlay"
                value={config.overlay?.description || ''}
                onChange={(evt) =>
                  handleChange(
                    'overlay',
                    editorContext.overlays.find(
                      (a) => a.description === evt.target.value
                    )
                  )
                }
              >
                <option>None</option>
                {editorContext.overlays.map((overlay) => (
                  <option key={overlay.description} value={overlay.description}>
                    {overlay.description}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
      </div>
      {/* Optional features */}

      {/* Advanced options */}
      <div
        className={cx(
          {
            'grid-rows-[1fr] border': showAdvancedOptions,
            'grid-rows-[0fr]': !showAdvancedOptions,
          },
          'border-neutral-110 rounded-[10px] overflow-hidden grid transition-[grid-template-rows] mb-5'
        )}
      >
        <div className="block  overflow-hidden bg-neutral-80">
          <div className="h-14 px-6 flex justify-between items-center border-b border-neutral-110">
            <span className="font-source_serif_pro text-xl text-neutral-170">
              Advanced options
            </span>
            <i
              onClick={setShowAdvancedOptions.bind(this, false)}
              className="block w-2.5 h-2.5 bg-ico-close bg-center bg-no-repeat bg-contain cursor-pointer"
            ></i>
          </div>
          <div className="flex">
            <div className="w-1/3 px-6 py-5 border-r border-neutral-110">
              <Switch
                value={config.maintain_height}
                label="Maintain clip height during tracking"
                onChange={toggle.bind(this, 'maintain_height')}
              />
            </div>
            <div className="w-1/3 px-6 py-5 border-r border-neutral-110">
              <div className="w-full">
                <span className="block font-inter text-base text-neutral-190 mb-4">
                  Target resolution
                </span>
                <InputRange
                  value={config.target_resolution[0]}
                  onChange={(newVal) =>
                    handleChange('target_resolution', [
                      newVal,
                      config.target_resolution[1],
                    ])
                  }
                  step={DEFAULTS.editConfigOptions.targetResolution.width.step}
                  min={DEFAULTS.editConfigOptions.targetResolution.width.min}
                  max={DEFAULTS.editConfigOptions.targetResolution.width.max}
                  label="Width"
                  units="px"
                />
                <InputRange
                  value={config.target_resolution[1]}
                  onChange={(newVal) =>
                    handleChange('target_resolution', [
                      config.target_resolution[0],
                      newVal,
                    ])
                  }
                  step={DEFAULTS.editConfigOptions.targetResolution.height.step}
                  min={DEFAULTS.editConfigOptions.targetResolution.height.min}
                  max={DEFAULTS.editConfigOptions.targetResolution.height.max}
                  label="Height"
                  units="px"
                />
              </div>
            </div>
            <div className="w-1/3 px-6 py-5">
              <div className="w-full">
                <span className="block font-inter text-base text-neutral-190 mb-4">
                  Clip buffer
                </span>
                <InputRange
                  value={config.buffer}
                  onChange={(newVal) => handleChange('buffer', newVal)}
                  step={DEFAULTS.editConfigOptions.clipBuffer.step}
                  min={DEFAULTS.editConfigOptions.clipBuffer.min}
                  max={DEFAULTS.editConfigOptions.clipBuffer.max}
                  label="Buffer"
                  units="seconds"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Advanced options */}

      <div className="flex justify-between items-center fixed bottom-0 right-0 w-[calc(100%-144px)] xl:w-[calc(100%-384px)] px-20 py-4 bg-neutral-110">
        <span className="font-inter text-base text-neutral-190">
          {pluralize('clip', editorContext.selectedUtterances.length, true)}{' '}
          selected
        </span>
        <button
          onClick={submitHandler}
          className="transition-colors flex justify-between items-center gap-x-3 rounded-[10px] bg-green-100 py-3 px-4 hover:bg-green-120"
        >
          <span className="font-inter text-base text-white">Submit</span>{' '}
          <i className="block w-5 h-5 bg-ico-arrow-right bg-center bg-no-repeat bg-contain"></i>
        </button>
      </div>
    </div>
  );
};

export default EditDialog;
