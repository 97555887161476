const TOOLTIP_CONTENT = {
  ASSET_LIBRARY: {
    optionalFeatures:
      '<strong>How to use optional features</strong><br>Optional features allow you to customize<br> and enhance your video edit. Hover in each section for tips on how to use features.',
    mmr: 'Maximal Marginal Relevance (MMR) will prioritize variety in search results. This can be useful if your search is super specific and you’re finding too many cuts of the same videos.',
    similarity:
      'Similarity search finds the most similar documents to a query. In most cases similarity search will get you what you’re looking for.',
    enableFaceTracking:
      '<strong>Enable face tracking</strong><br>This will center key figures if the video is cropped or scaled',
    addCaptions:
      '<strong>Add captions</strong><br>This will add transcript captions to your edit',
    enableSlideShow: '<strong>Enable slideshow</strong><br>This will TKTKTKTK',
    videoScaling:
      '<strong>Video scaling</strong><br>Videos default to Scaled proportions, which will adjust the aspect ratio to fit a crop. When scaling videos it’s a good idea to check “Enable face tracking” to ensure the speaker isn’t cropped out.<br><br>If you choose to Letterbox your video its aspect ratio will be retained using dark bands at the sides or top and bottom, with content displayed in the middle.',
    overlays:
      '<strong>Overlays</strong><br>Select an overlay to display your video within a framed mockup, like a computer screen.',
  },
};

export default TOOLTIP_CONTENT;
