import Cluster from '@/interfaces/Cluster';
import { Dispatch, SetStateAction } from 'react';

interface QuickSearchTile {
  cluster: Cluster;
  index: number;
  doQuickSearch: () => void;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  setSearchScore: Dispatch<SetStateAction<string>>;
  setTimeRange: Dispatch<SetStateAction<string>>;
}

const QuickSearchTile: React.FC<QuickSearchTile> = ({
  cluster,
  index,
  doQuickSearch,
  setSearchQuery,
  setSearchScore,
  setTimeRange,
}) => {
  const query = cluster.interpretation.query;
  const subject = cluster.interpretation.subject;

  return (
    <div
      className="group w-80 hover:bg-green-20 px-4 py-3 cursor-pointer"
      onClick={() => {
        setSearchQuery(query);
        setSearchScore('');
        setTimeRange('');
        doQuickSearch();
      }}
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex gap-x-2">
          <span className="font-source_serif_pro text-xl text-neutral-150 no-underline">
            {index + 1}.
          </span>
          <span className="text-neutral-160 font-inter text-base group-hover:underline text-ellipsis h-[70px] line-clamp-3">
            {subject}
          </span>
        </div>
        <div className="pl-6 flex items-center gap-x-1">
          <i className="block w-3 h-3 bg-ico-view-video bg-no-repeat bg-center"></i>
          <span className="font-inter text-xs text-neutral-160">
            View videos
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuickSearchTile;
