import React, { useState } from 'react';
import cx from 'classnames';

interface FilterAccordionItemProps {
  label: string;
  children: React.ReactNode;
}

const FilterAccordionItem: React.FC<FilterAccordionItemProps> = ({
  label,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="py-5 border-b border-neutral-110">
      <div
        onClick={() => setExpanded(!expanded)}
        className="flex items-center justify-between cursor-pointer"
      >
        <span className="font-inter text-base text-neutral-170">{label}</span>
        <i
          className={cx(
            { 'rotate-180': expanded },
            'block w-6 h-6 bg-ico-arrow-down bg-center bg-no-repeat bg-cover'
          )}
        ></i>
      </div>
      <div className={cx({ hidden: !expanded }, 'pt-5')}>{children}</div>
    </div>
  );
};

export default FilterAccordionItem;
