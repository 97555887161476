/**
 *
 * PageContainer
 * @description wraps the various routes, includes the header and sidebar.
 *
 */

import React from 'react';
import { useAuth } from '../contexts/authProvider';
import NotificationBanner from './NotificationBanner';
import Sidebar from './Sidebar';

interface PageContainerProps {
  children: React.ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  const { user } = useAuth();

  return (
    <div className="flex flex-wrap antialiased">
      {user && <Sidebar />}
      <div className="relative w-[calc(100%-144px)] min-h-screen bg-neutral-60 py-20 px-8 xl:px-20 xl:w-[calc(100%-384px)]">
        <NotificationBanner />
        {children}
      </div>
    </div>
  );
};

export default PageContainer;
