import React from 'react';
import { Tooltip } from 'react-tooltip';

interface ToolTipProps {
  id: string;
}

const ToolTip: React.FC<ToolTipProps> = ({ id }) => {
  return <Tooltip id={id} style={{ maxWidth: '300px', zIndex: '100' }} />;
};

export default ToolTip;
