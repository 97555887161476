import '@/styles/privacy_terms.scss';

const Terms: React.FC = () => {
  return (
    <div id="terms">
      <h1>Terms of Use</h1>
      <h4>Effective date: October 1st, 2024</h4>
      <section>
        <p>
          Welcome to Open Labs. Please read on to learn the rules and
          restrictions that govern your use of our website(s), products,
          services and applications (the “Services”). If you have any questions,
          comments, or concerns regarding these terms or the Services, please
          contact us at:
        </p>
        <p>
          Email: privacy@openlabsusa.org Address: 1800 M Street NW, FRNT 1,
          #33127, Washington, District of Columbia 20036
        </p>
        <p>
          These Terms of Use (the “Terms”) are a binding contract between you
          and Open Labs LLC (“Open Labs,” “we” and “us”). Your use of the
          Services in any way means that you agree to all of these Terms, and
          these Terms will remain in effect while you use the Services. These
          Terms include the provisions in this document as well as those in
          the&nbsp;
          <a href="/privacy" target="_blank">
            Privacy Policy
          </a>
          . Your use of or participation in certain Services may also be subject
          to additional policies, rules and/or conditions (“Additional Terms”),
          which are incorporated herein by reference, and you understand and
          agree that by using or participating in any such Services, you agree
          to also comply with these Additional Terms.
        </p>

        <p>
          Please read these Terms carefully. They cover important information
          about Services provided to you. These Terms include information about
          future changes to these Terms, limitations of liability, a class
          action waiver and resolution of disputes by arbitration instead of in
          court. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE
          SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE
          FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
        </p>
        <p>
          ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES
          OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU
          AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
          INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A
          CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
        </p>
      </section>

      <h2>Will these Terms ever change?</h2>
      <section>
        <p>
          We are constantly trying to improve our Services, so these Terms may
          need to change along with our Services. We reserve the right to change
          the Terms at any time, but if we do, we will place a notice on our
          site located at&nbsp;
          <a href="https://www.machineapp.ai/" target="_blank">
            https://www.machineapp.ai/
          </a>
          , send you an email, and/or notify you by some other means.
        </p>
        <p>
          If you don’t agree with the new Terms, you are free to reject them;
          unfortunately, that means you will no longer be able to use the
          Services. If you use the Services in any way after a change to the
          Terms is effective, that means you agree to all of the changes.
        </p>
        <p>
          Except for changes by us as described here, no other amendment or
          modification of these Terms will be effective unless in writing and
          signed by both you and us.
        </p>
      </section>

      <h2>What about my privacy?</h2>
      <section>
        <p>
          Open Labs takes the privacy of its users very seriously. For the
          current Open Labs Privacy Policy, please click here.
        </p>
      </section>

      <h3>Children’s Online Privacy Protection Act</h3>
      <section>
        The Children’s Online Privacy Protection Act (“COPPA”) requires that
        online service providers obtain parental consent before they knowingly
        collect personally identifiable information online from children who are
        under 13 years of age. We do not knowingly collect or solicit personally
        identifiable information from children under 18 years of age; if you are
        a child under 18 years of age, please do not attempt to register for or
        otherwise use the Services or send us any personal information. If we
        learn we have collected personal information from a child under 18 years
        of age, we will delete that information as quickly as possible. If you
        believe that a child under 18 years of age may have provided us personal
        information, please contact us at privacy@openlabsusa.org.
      </section>

      <h2>What are the basics of using Open Labs?</h2>
      <section>
        <p>
          Additionally, you may be able to access certain parts or features of
          the Services by using your account credentials from other services
          (each, a “Third Party Account”), such as those offered by Google. By
          using the Services through a Third Party Account, you permit us to
          access certain information from such account for use by the Services.
          You are ultimately in control of how much information is accessible to
          us and may exercise such control by adjusting your privacy settings on
          your Third Party Account.
        </p>
        <p>
          ou represent and warrant that you are an individual of legal age to
          form a binding contract (or if not, you’ve received your parent’s or
          guardian’s permission to use the Services and have gotten your parent
          or guardian to agree to these Terms on your behalf). If you’re
          agreeing to these Terms on behalf of an organization or entity, you
          represent and warrant that you are authorized to agree to these Terms
          on that organization’s or entity’s behalf and bind them to these Terms
          (in which case, the references to “you” and “your” in these Terms,
          except for in this sentence, refer to that organization or entity).
        </p>
        <p>
          You will only use the Services subject to usage restrictions set forth
          herein, and only in a manner that complies with all laws that apply to
          you. If your use of the Services is prohibited by applicable laws,
          then you aren’t authorized to use the Services. We can’t and won’t be
          responsible for your using the Services in a way that breaks the law.
        </p>
      </section>

      <h2>What about messaging?</h2>
      <section>
        <p>
          As part of the Services, you may receive communications through the
          Services, including messages that Open Labs sends you (for example,
          via email).
        </p>
      </section>

      <h2>Are there restrictions in how I can use the Services?</h2>
      <section>
        <p>
          You represent, warrant, and agree that you will not provide or
          contribute anything, including any Content (as that term is defined
          below), to the Services, or otherwise use or interact with the
          Services, in a manner that:
        </p>
        <ul>
          <li>
            infringes or violates the intellectual property rights or any other
            rights of anyone else (including Open Labs);
          </li>
          <li>
            violates any law or regulation, including, without limitation, any
            applicable export control laws, federal or state federal campaign
            finance laws, privacy laws or any other purpose not reasonably
            intended by Open Labs;
          </li>
          <li>
            is dangerous, harmful, fraudulent, deceptive, threatening,
            harassing, defamatory, obscene, or otherwise objectionable;
          </li>
          <li>
            attempts, in any manner, to obtain the password, account, or other
            security information from any other user;
          </li>
          <li>
            violates the security of any computer network, or cracks any
            passwords or security encryption codes;
          </li>
          <li>
            runs Maillist, Listserv, any form of auto-responder or “spam” on the
            Services, or any processes that run or are activated while you are
            not logged into the Services, or that otherwise interfere with the
            proper working of the Services (including by placing an unreasonable
            load on the Services’ infrastructure);
          </li>
          <li>
            “crawls,” “scrapes,” or “spiders” any page, data, or portion of or
            relating to the Services or Content (through use of manual or
            automated means);
          </li>
          <li>copies or stores any significant portion of the Content;</li>
          <li>
            decompiles, reverse engineers, or otherwise attempts to obtain the
            source code or underlying ideas or information of or relating to the
            Services;
          </li>
          <li>competes with Open Labs; or</li>
          <li>
            does not support the progressive agenda or Democratic party, or
            otherwise poses reputational harm to Open Labs (each of the
            foregoing based on our sole and reasonable judgment).
          </li>
        </ul>

        <p>
          A violation of any of the foregoing is grounds for termination of your
          right to use or access the Services.
        </p>
      </section>

      <h2>What are my rights in the Services?</h2>
      <section>
        <p>
          The materials displayed or performed or available on or through the
          Services, including, but not limited to, our content library, search,
          annotations, tags, categorization, scoring and so forth (all of the
          foregoing, the “Content”) are protected by copyright and/or other
          intellectual property laws. You promise to abide by all copyright
          notices, trademark rules, information, and restrictions contained in
          any Content you access through the Services, and you won’t use, copy,
          reproduce, modify, translate, publish, broadcast, transmit,
          distribute, perform, upload, display, license, sell, commercialize or
          otherwise exploit for any purpose any Content not owned by you (which,
          for the purpose of the Terms, includes any predictive scoring or
          rating that our Services generate), (i) without the prior consent of
          the owner of that Content or (ii) in a way that violates someone
          else’s (including Open Labs’) rights.
        </p>
        <p>
          ubject to these Terms, we grant each user of the Services a worldwide,
          non-exclusive, non-sublicensable and non-transferable license to use
          (i.e., to download and display locally) Content. You understand that
          Open Labs owns the Services. Except as expressly set forth in the
          Terms, you shall not (and shall not permit any third party to),
          directly or indirectly (i) reverse engineer, decompile or discover the
          source code or underlying component of our Services, including
          algorithms, systems, user interface designs, architecture, software
          (in source and object forms), objects and documentation (except to the
          extent applicable laws specifically prohibit such restriction). The
          Services may allow you to copy, modify, or download certain Content,
          but please remember that even where these functionalities exist, all
          the restrictions in this section still apply, and Open Labs will
          retain all right, title, and interest in and to its Services,
          including any copies and derivative works of the foregoing.
        </p>
        <p>
          We may (i) internally use (but not disclose) the data, queries,
          information or other material you provide, upload or submit for the
          purposes of providing the Services to you, and (ii) freely use and
          make available Aggregated Anonymous Data for our business purposes
          (including without limitation, for purposes of improving, testing,
          operating our Services). “Aggregated Anonymous Data” means data
          collected by, or generated by us in connection with your use of the
          Services, but only in aggregate, anonymized form which can in no way
          be linked specifically to you.
        </p>
      </section>

      <h2>Who is responsible for what I see and do on the Services?</h2>
      <section>
        <p>
          Any information or Content publicly posted or privately transmitted
          through the Services is the sole responsibility of the person from
          whom such Content originated, and you access all such information and
          Content at your own risk, and we aren’t liable for any errors or
          omissions in that information or Content or for any damages or loss
          you might suffer in connection with it. We cannot control and have no
          duty to take any action regarding how you may interpret and use the
          Content or what actions you may take as a result of having been
          exposed to the Content, and you hereby release us from all liability
          for you having acquired or not acquired Content through the Services.
          We can’t guarantee the identity of any users with whom you interact in
          using the Services.
        </p>
        <p>
          You are responsible for all Content you contribute, in any manner, to
          the Services, and you represent and warrant you have all rights
          necessary to do so, in the manner in which you contribute it.
        </p>
        <p>
          The Services may contain links or connections to third-party websites
          or services that are not owned or controlled by Open Labs. When you
          access third-party websites or use third-party services, you accept
          that there are risks in doing so, and that Open Labs is not
          responsible for such risks.
        </p>
        <p>
          Open Labs has no control over, and assumes no responsibility for, the
          content, accuracy, privacy policies, or practices of or opinions
          expressed in any third-party websites or by any third party that you
          interact with through the Services. In addition, Open Labs will not
          and cannot monitor, verify, censor or edit the content of any
          third-party site or service. We encourage you to be aware when you
          leave the Services and to read the terms and conditions and privacy
          policy of each third-party website or service that you visit or
          utilize. By using the Services, you release and hold us harmless from
          any and all liability arising from your use of any third-party website
          or service.
        </p>
        <p>
          Your interactions with organizations and/or individuals found on or
          through the Services, including payment and delivery of goods or
          services, and any other terms, conditions, warranties or
          representations associated with such dealings, are solely between you
          and such organizations and/or individuals. You should make whatever
          investigation you feel necessary or appropriate before proceeding with
          any online or offline transaction with any of these third parties. You
          agree that Open Labs shall not be responsible or liable for any loss
          or damage of any sort incurred as the result of any such dealings.
        </p>
        <p>
          If there is a dispute between participants on this site or Services,
          or between users and any third party, you agree that Open Labs is
          under no obligation to become involved. In the event that you have a
          dispute with one or more other users, you release Open Labs, its
          directors, officers, employees, agents, and successors from claims,
          demands, and damages of every kind or nature, known or unknown,
          suspected or unsuspected, disclosed or undisclosed, arising out of or
          in any way related to such disputes and/or our Services. You shall and
          hereby do waive California Civil Code Section 1542 or any similar law
          of any jurisdiction, which says in substance: “A general release does
          not extend to claims that the creditor or releasing party does not
          know or suspect to exist in his or her favor at the time of executing
          the release and that, if known by him or her, would have materially
          affected his or her settlement with the debtor or released party.”
        </p>
      </section>

      <h2>Will Open Labs ever change the Services?</h2>
      <section>
        <p>
          We’re always trying to improve our Services, so they may change over
          time. We may suspend or discontinue any part of the Services, or we
          may introduce new features or impose limits on certain features or
          restrict access to parts or all of the Services. We’ll try to give you
          notice when we make a material change to the Services that would
          adversely affect you, but this isn’t always practical. We reserve the
          right to remove any Content from the Services at any time, for any
          reason (including, but not limited to, if someone alleges you
          contributed that Content in violation of these Terms), in our sole
          discretion, and without notice.
        </p>
      </section>

      <h2>Do the Services cost anything?</h2>
      <section>
        <p>
          The Services may be free or we may charge a fee for using the
          Services. If you are using a free version of the Services, we will
          notify you before any Services you are then using begin carrying a
          fee, and if you wish to continue using such Services, you must pay all
          applicable fees for such Services. Note that if you elect to receive
          text messages through the Services, data and message rates may apply.
          Any and all such charges, fees or costs are your sole responsibility.
          You should consult with your wireless carrier to determine what rates,
          charges, fees or costs may apply to your use of the Services.
        </p>

        <ul>
          <li>
            <strong>Paid Services.</strong> Certain of our Services may be
            subject to payments now or in the future (the “Paid Services”).
            Please note that any payment terms presented to you in the process
            of using or signing up for a Paid Service are deemed part of these
            Terms.
          </li>
          <li>
            <strong>Billing.</strong> We use a third-party payment processor
            (the “Payment Processor”) to bill you through a payment account
            linked to your account on the Services (your “Billing Account”) for
            use of the Paid Services. The processing of payments will be subject
            to the terms, conditions and privacy policies of the Payment
            Processor in addition to these Terms. Currently, we use Stripe, Inc.
            as our Payment Processor. You can access Stripe’s Terms of Service
            at&nbsp;
            <a href="https://stripe.com/us/checkout/legal " target="_blank">
              https://stripe.com/us/checkout/legal
            </a>
            &nbsp; and their Privacy Policy at&nbsp;
            <a href="https://stripe.com/us/privacy" target="_blank">
              https://stripe.com/us/privacy
            </a>
            . We are not responsible for any error by, or other acts or
            omissions of, the Payment Processor. By choosing to use Paid
            Services, you agree to pay us, through the Payment Processor, all
            charges at the prices then in effect for any use of such Paid
            Services in accordance with the applicable payment terms, and you
            authorize us, through the Payment Processor, to charge your chosen
            payment provider (your “Payment Method”). You agree to make payment
            using that selected Payment Method. We reserve the right to correct
            any errors or mistakes that the Payment Processor makes even if it
            has already requested or received payment.
          </li>
          <li>
            <strong>Payment Method.</strong> The terms of your payment will be
            based on your Payment Method and may be determined by agreements
            between you and the financial institution, credit card issuer or
            other provider of your chosen Payment Method. If we, through the
            Payment Processor, do not receive payment from you, you agree to pay
            all amounts due on your Billing Account upon demand.
          </li>
          <li>
            <strong>Recurring Billing.</strong> Some of the Paid Services may
            consist of an initial period, for which there is a one-time charge,
            followed by recurring period charges as agreed to by you. By
            choosing a recurring payment plan, you acknowledge that such
            Services have an initial and recurring payment feature and you
            accept responsibility for all recurring charges prior to
            cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT
            FURTHER AUTHORIZATION FROM YOU, UNTIL YOU MODIFY THE SETTINGS VIA
            THE PAYMENT PROCESSOR OR PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS
            CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH
            TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES
            SUBMITTED BEFORE WE REASONABLY COULD ACT.
          </li>
          <li>
            <strong>Current Information Required.</strong> YOU MUST PROVIDE
            CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT.
            YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING
            ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING
            ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND
            YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT
            METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE
            OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED
            DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH
            INFORMATION CAN BE MADE VIA THE PAYMENT PROCESSOR. IF YOU FAIL TO
            PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY
            CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR
            BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET
            FORTH ABOVE.
          </li>
          <li>
            <strong>Change in Amount Authorized.</strong> If the amount to be
            charged to your Billing Account varies from the amount you
            preauthorized (other than due to the imposition or change in the
            amount of state sales taxes), you have the right to receive, and we
            shall provide, notice of the amount to be charged and the date of
            the charge before the scheduled date of the transaction. Any
            agreement you have with your payment provider will govern your use
            of your Payment Method. You agree that we may accumulate charges
            incurred and submit them as one or more aggregate charges during or
            at the end of each billing cycle.
          </li>
          <li>
            <strong>Auto-Renewal for Paid Services.</strong> Unless you opt out
            of auto-renewal, which can be done through the Payment Processor,
            any Paid Services you have signed up for will be automatically
            extended for successive renewal periods of the same duration as the
            subscription term originally selected, at the then-current
            non-promotional rate. If you terminate a Paid Service, you may use
            your subscription until the end of your then-current term, and your
            subscription will not be renewed after your then-current term
            expires. However, you will not be eligible for a prorated refund of
            any portion of the subscription fee paid for the then-current
            subscription period. IF YOU DO NOT WANT TO CONTINUE TO BE CHARGED ON
            A RECURRING MONTHLY BASIS, YOU MUST CANCEL THE APPLICABLE PAID
            SERVICE THROUGH THE PAYMENT PROCESSOR OR BY NOTIFYING US BEFORE THE
            END OF THE RECURRING TERM. PAID SERVICES CANNOT BE TERMINATED BEFORE
            THE END OF THE PERIOD FOR WHICH YOU HAVE ALREADY PAID, AND EXCEPT AS
            EXPRESSLY PROVIDED IN THESE TERMS, MYCELIAL WILL NOT REFUND ANY FEES
            THAT YOU HAVE ALREADY PAID.
          </li>
          <li>
            <b>Reaffirmation of Authorization.</b> Your non-termination or
            continued use of a Paid Service reaffirms that we are authorized to
            charge your Payment Method for that Paid Service. We may submit
            those charges for payment and you will be responsible for such
            charges. This does not waive our right to seek payment directly from
            you. Your charges may be payable in advance, in arrears, per usage,
            or as otherwise described when you initially selected to use the
            Paid Service.
          </li>
          <li>
            <strong>Free Trials and Other Promotions.</strong> Any free trial or
            other promotion that provides access to a Paid Service must be used
            within the specified time of the trial. You must stop using a Paid
            Service before the end of the trial period in order to avoid being
            charged for that Paid Service. If you cancel prior to the end of the
            trial period and are inadvertently charged for a Paid Service,
            please contact us at privacy@openlabsusa.org.
          </li>
        </ul>
      </section>

      <h2>What if I want to stop using the Services?</h2>
      <section>
        <p>
          You’re free to do that at any time; please refer to our&nbsp;
          <a href="/privacy" target="_blank">
            Privacy Policy
          </a>
          , as well as the licenses above, to understand how we treat
          information you provide to us after you have stopped using our
          Services.
        </p>
        <p>
          Open Labs is also free to terminate (or suspend access to) your use of
          the Services for any reason in our discretion, including your breach
          of these Terms. Open Labs has the sole right to decide whether you are
          in violation of any of the restrictions set forth in these Terms.
        </p>
        <p>
          Provisions that, by their nature, should survive termination of these
          Terms shall survive termination. By way of example, all of the
          following will survive termination: any obligation you have to
          indemnify us, any limitations on our liability, any terms regarding
          ownership or intellectual property rights, and terms regarding
          disputes between us, including without limitation the arbitration
          agreement.
        </p>
      </section>

      <h2>What else do I need to know?</h2>
      <section>
        <p>
          <strong>Warranty Disclaimer.</strong> Open Labs and its licensors,
          suppliers, partners, parent, subsidiaries or affiliated entities, and
          each of their respective officers, directors, members, employees,
          consultants, contract employees, representatives and agents, and each
          of their respective successors and assigns (Open Labs and all such
          parties together, the “Open Labs Parties”) make no representations or
          warranties concerning the Services, including without limitation
          regarding any Content contained in or accessed through the Services,
          and the Open Labs Parties will not be responsible or liable for the
          accuracy, copyright compliance, legality, or decency of material
          contained in or accessed through the Services or any claims, actions,
          suits procedures, costs, expenses, damages or liabilities arising out
          of use of, or in any way related to your participation in, the
          Services. The Open Labs Parties make no representations or warranties
          regarding suggestions or recommendations of services or products
          offered or purchased through or in connection with the Services. THE
          SERVICES AND CONTENT ARE PROVIDED BY OPEN LABS (AND ITS LICENSORS AND
          SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR
          ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN
          IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        </p>
        <p>
          <strong>Limitation of Liability.</strong> TO THE FULLEST EXTENT
          ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL
          THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT
          LIABILITY, OR OTHERWISE) SHALL ANY OF THE OPEN LABS PARTIES BE LIABLE
          TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL,
          INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING
          DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF
          GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR
          MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY
          AMOUNT, IN THE AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS OR
          (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW
          THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN
          OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO
          YOU.
        </p>

        <p>
          <strong>Indemnity.</strong> To the fullest extent allowed by
          applicable law, You agree to indemnify and hold the Open Labs Parties
          harmless from and against any and all claims, liabilities, damages
          (actual and consequential), losses and expenses (including attorneys’
          fees) arising from or in any way related to any claims relating to (a)
          your use of the Services (including any actions taken by a third party
          using your account), and (b) your violation of these Terms.
        </p>
        <p>
          <strong>Assignment.</strong> You may not assign, delegate or transfer
          these Terms or your rights or obligations hereunder, or your Services
          account, in any way (by operation of law or otherwise) without Open
          Labs’ prior written consent. We may transfer, assign, or delegate
          these Terms and our rights and obligations without consent.
        </p>
        <p>
          <strong>Choice of Law.</strong> These Terms are governed by and will
          be construed under the Federal Arbitration Act, applicable federal
          law, and the laws of the State of Delaware, without regard to the
          conflicts of laws provisions thereof.
        </p>
        <p>
          <strong>Arbitration Agreement.</strong> Please read the following
          ARBITRATION AGREEMENT carefully because it requires you to arbitrate
          certain disputes and claims with Open Labs and limits the manner in
          which you can seek relief from Open Labs. Both you and Open Labs
          acknowledge and agree that for the purposes of any dispute arising out
          of or relating to the subject matter of these Terms, Open Labs’
          officers, directors, employees and independent contractors
          (“Personnel”) are third-party beneficiaries of these Terms, and that
          upon your acceptance of these Terms, Personnel will have the right
          (and will be deemed to have accepted the right) to enforce these Terms
          against you as the third-party beneficiary hereof.
        </p>
        <section>
          <ul>
            <li>
              <em>
                Arbitration Rules; Applicability of Arbitration Agreement.
              </em>
              &nbsp; The parties shall use their best efforts to settle any
              dispute, claim, question, or disagreement arising out of or
              relating to the subject matter of these Terms directly through
              good-faith negotiations, which shall be a precondition to either
              party initiating arbitration. If such negotiations do not resolve
              the dispute, it shall be finally settled by binding arbitration in
              New Castle County, Delaware. The arbitration will proceed in the
              English language, in accordance with the JAMS Streamlined
              Arbitration Rules and Procedures (the “Rules”) then in effect, by
              one commercial arbitrator with substantial experience in resolving
              intellectual property and commercial contract disputes. The
              arbitrator shall be selected from the appropriate list of JAMS
              arbitrators in accordance with such Rules. Judgment upon the award
              rendered by such arbitrator may be entered in any court of
              competent jurisdiction.
            </li>
            <li>
              <em>Costs of Arbitration.</em> The Rules will govern payment of
              all arbitration fees. Open Labs will pay all arbitration fees for
              claims less than seventy-five thousand ($75,000) dollars. Open
              Labs will not seek its attorneys’ fees and costs in arbitration
              unless the arbitrator determines that your claim is frivolous.
            </li>
            <li>
              <em>Small Claims Court; Infringement.</em> Either you or Open Labs
              may assert claims, if they qualify, in small claims court in New
              Castle County, Delaware or any United States county where you live
              or work. Furthermore, notwithstanding the foregoing obligation to
              arbitrate disputes, each party shall have the right to pursue
              injunctive or other equitable relief at any time, from any court
              of competent jurisdiction, to prevent the actual or threatened
              infringement, misappropriation or violation of a party’s
              copyrights, trademarks, trade secrets, patents or other
              intellectual property rights.
            </li>
            <li>
              <em>Waiver of Jury Trial.</em> YOU AND OPEN LABS WAIVE ANY
              CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A
              TRIAL IN FRONT OF A JUDGE OR JURY. You and Open Labs are instead
              choosing to have claims and disputes resolved by arbitration.
              Arbitration procedures are typically more limited, more efficient,
              and less costly than rules applicable in court and are subject to
              very limited review by a court. In any litigation between you and
              Open Labs over whether to vacate or enforce an arbitration award,
              YOU AND OPEN LABS WAIVE ALL RIGHTS TO A JURY TRIAL, and elect
              instead to have the dispute be resolved by a judge.
            </li>
            <li>
              <em>Waiver of Class or Consolidated Actions.</em> ALL CLAIMS AND
              DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
              ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
              BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
              ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY
              OTHER CUSTOMER OR USER. If however, this waiver of class or
              consolidated actions is deemed invalid or unenforceable, neither
              you nor Open Labs is entitled to arbitration; instead all claims
              and disputes will be resolved in a court as set forth in (g)
              below.
            </li>
            <li>
              <em>Opt-out.</em> You have the right to opt out of the provisions
              of this Section by sending written notice of your decision to opt
              out to the following address: 1800 M Street NW, FRNT 1, #33127,
              Washington, District of Columbia, 20036 postmarked within thirty
              (30) days of first accepting these Terms. You must include (i)
              your name and residence address, (ii) the email address and/or
              telephone number associated with your account, and (iii) a clear
              statement that you want to opt out of these Terms’ arbitration
              agreement.
            </li>
            <li>
              <em>Exclusive Venue.</em> If you send the opt-out notice in (f),
              and/or in any circumstances where the foregoing arbitration
              agreement permits either you or Open Labs to litigate any dispute
              arising out of or relating to the subject matter of these Terms in
              court, then the foregoing arbitration agreement will not apply to
              either party, and both you and Open Labs agree that any judicial
              proceeding (other than small claims actions) will be brought in
              the state or federal courts located in, respectively, New Castle
              County, Delaware, or the federal district in which that county
              falls.
            </li>
            <li>
              <em>Severability.</em> If the prohibition against class actions
              and other claims brought on behalf of third parties contained
              above is found to be unenforceable, then all of the preceding
              language in this Arbitration Agreement section will be null and
              void. This arbitration agreement will survive the termination of
              your relationship with Open Labs.
            </li>
          </ul>
        </section>
        <p>
          <strong>Miscellaneous.</strong> You will be responsible for paying,
          withholding, filing, and reporting all taxes, duties, and other
          governmental assessments associated with your activity in connection
          with the Services, provided that the Open Labs may, in its sole
          discretion, do any of the foregoing on your behalf or for itself as it
          sees fit. The failure of either you or us to exercise, in any way, any
          right herein shall not be deemed a waiver of any further rights
          hereunder. If any provision of these Terms are found to be
          unenforceable or invalid, that provision will be limited or
          eliminated, to the minimum extent necessary, so that these Terms shall
          otherwise remain in full force and effect and enforceable. You and
          Open Labs agree that these Terms are the complete and exclusive
          statement of the mutual understanding between you and Open Labs, and
          that these Terms supersede and cancel all previous written and oral
          agreements, communications and other understandings relating to the
          subject matter of these Terms. You hereby acknowledge and agree that
          you are not an employee, agent, partner, or joint venture of Open
          Labs, and you do not have any authority of any kind to bind Open Labs
          in any respect whatsoever.
        </p>
        <p>
          Except as expressly set forth in the section above regarding the
          arbitration agreement, you and Open Labs agree there are no
          third-party beneficiaries intended under these Terms.
        </p>
      </section>
    </div>
  );
};

export default Terms;
