import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="block font-source_serif_pro text-[40px] leading-[56px] text-neutral-170 mb-10">
        Error 404 - Page not found
      </div>

      <div className="block font-inter text-xl text-neutral-160 mb-10">
        <p className="mb-8">
          The page you requested may have been deleted or moved, or the address
          (URL) may not be correct.
        </p>
        <p className="mb-4">
          <a
            onClick={() => navigate(-1)}
            className="font-light text-[22px] leading-[31px] text-green-120 cursor-pointer"
          >
            Click here to go back
          </a>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
