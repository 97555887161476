import React from 'react';
import FilterAccordionItem from './FilterAccordionItem';
import cx from 'classnames';

interface BlueRoseProps {
  current: number;
  change: (value: number) => void;
}

const FilterItemBlueRose: React.FC<BlueRoseProps> = ({ current, change }) => {
  return (
    <FilterAccordionItem label="Blue Rose Score">
      {/* Radio buttons options */}
      <div className="flex items-start flex-col gap-4">
        <label className="flex items-center gap-x-3 cursor-pointer">
          <input
            className="w-4 h-4 rounded-full border-neutral-110 text-green-100 focus:ring-0 focus:outline-0 focus:ring-offset-0"
            type="radio"
            checked={current === 0}
            onChange={() => change(0)}
          />{' '}
          Show all
        </label>
        <label className="flex items-center gap-x-3 cursor-pointer">
          <input
            className="w-4 h-4 rounded-full border-neutral-110 text-green-100 focus:ring-0 focus:outline-0 focus:ring-offset-0"
            type="radio"
            checked={current === 75}
            onChange={() => change(75)}
          />{' '}
          75% and above only
        </label>
        <label className="flex items-center gap-x-3 cursor-pointer">
          <input
            className="w-4 h-4 rounded-full border-neutral-110 text-green-100 focus:ring-0 focus:outline-0 focus:ring-offset-0"
            type="radio"
            checked={current === 50}
            onChange={() => change(50)}
          />{' '}
          50% and above only
        </label>
        <label className="flex items-center gap-x-3 cursor-pointer">
          <input
            className="w-4 h-4 rounded-full border-neutral-110 text-green-100 focus:ring-0 focus:outline-0 focus:ring-offset-0"
            type="radio"
            checked={current === 25}
            onChange={() => change(25)}
          />{' '}
          25% and above only
        </label>
      </div>
      {/* Radio buttons options */}
    </FilterAccordionItem>
  );
};

export default FilterItemBlueRose;
