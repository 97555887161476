const Trending: React.FC = () => {
  return (
    <div className="block mb-8">
      <span className="block mb-4 font-source_serif_pro text-xl text-neutral-190">
        Trending searches
      </span>

      <div className="flex gap-3 flex-wrap">
        <div className="transition-colors cursor-pointer flex gap-x-2 items-center py-2 px-3 rounded-[10px] border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-neutral-110">
          <span>Election</span>
          <span>511</span>
        </div>

        <div className="transition-colors cursor-pointer flex gap-x-2 items-center py-2 px-3 rounded-[10px] border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-neutral-110">
          <span>Social media regulation</span>
          <span>297</span>
        </div>

        <div className="transition-colors cursor-pointer flex gap-x-2 items-center py-2 px-3 rounded-[10px] border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-neutral-110">
          <span>US Congress</span>
          <span>285</span>
        </div>

        <div className="transition-colors cursor-pointer flex gap-x-2 items-center py-2 px-3 rounded-[10px] border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-neutral-110">
          <span>Big tech</span>
          <span>226</span>
        </div>

        <div className="transition-colors cursor-pointer flex gap-x-2 items-center py-2 px-3 rounded-[10px] border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-neutral-110">
          <span>Kamala Harris</span>
          <span>213</span>
        </div>

        <div className="transition-colors cursor-pointer flex gap-x-2 items-center py-2 px-3 rounded-[10px] border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-neutral-110">
          <span>Trump</span>
          <span>132</span>
        </div>
      </div>
    </div>
  );
};

export default Trending;
