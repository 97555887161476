import React from 'react';

interface InputRangeProps {
  label: string;
  min: number;
  max: number;
  step: number;
  units: string;
  value: number;
  onChange: (value: number) => void;
}

const InputRangeComponent: React.FC<InputRangeProps> = ({
  label,
  min,
  max,
  step,
  value,
  units,
  onChange,
}) => {
  const fillPercent = ((value - min) / (max - min)) * 100,
    inputStyle = {
      background: `linear-gradient(to right, #356657 0%, #356657 ${fillPercent}%, #FFFFFF ${fillPercent}%)`,
    };

  return (
    <div className="inputRange relative w-[230px] mb-5">
      <div className="relative font-inter text-sm text-neutral-140">
        {label}:{' '}
        <output>
          {value} {units}
        </output>
      </div>
      <input
        style={inputStyle}
        type="range"
        name="inputName"
        id="inputName"
        value={value}
        min={min.toString()}
        max={max.toString()}
        step={step.toString()}
        onChange={(evt) => onChange(parseFloat(evt.target.value))}
        className="relative appearance-none outline-none shadow-none w-full rounded-full h-2 m-0 p-0 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:cursor-grab [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-100 [&::-webkit-slider-thumb]:shadow-md [&::-webkit-slider-thumb]:rounded-full"
      />
    </div>
  );
};

export default InputRangeComponent;
