import React from 'react';
import Utterance from 'src/interfaces/UtteranceWithId';
import { useEditorConfigContext } from 'src/contexts/editorConfigProvider';
import secondsToMinutes from 'src/utils/secondsToMinutes';
import FLAGS from 'src/featureFlags';

interface TranscriptProps {
  utterances: Utterance[];
  jumpToClip: (start_time: number) => void;
  searchQuery: string;
  showTimeStamp: boolean;
}

const Transcript: React.FC<TranscriptProps> = ({
  utterances,
  jumpToClip,
  searchQuery,
  showTimeStamp,
}) => {
  const clickHandler = (start_time: number) => {
      jumpToClip(start_time);
    },
    { selectedUtterances, toggleUtterance } = useEditorConfigContext(),
    hasSpeakerInfo = utterances.some((utterance) => utterance.speaker != null);

  return utterances.map((utterance, i) => {
    const checked = !!selectedUtterances.find((u) => u.id === utterance.id);
    const regex = new RegExp(searchQuery, 'gi');
    const shouldDisplay = searchQuery === '' || regex.test(utterance.text);
    const disabledClass = 'disabled pointer-events-none opacity-30';
    return (
      <div key={i} className={shouldDisplay ? 'block' : 'hidden'}>
        <div
          className={`flex w-full px-5 py-2 border-b border-neutral-110 font-inter text-sm text-neutral-190 hover:bg-neutral-50 ${checked ? 'bg-neutral-40' : 'bg-white'}`}
        >
          <div className="w-9 shrink-0">
            <input
              checked={checked}
              onChange={toggleUtterance.bind(this, utterance)}
              className="w-4 h-4 rounded border-neutral-110 text-green-100 focus:ring-0 focus:outline-0 focus:ring-offset-0"
              type="checkbox"
            />
          </div>
          <div
            className={`shrink-0 w-28 hover:text-green-100 ${showTimeStamp ? 'block' : 'hidden'}`}
          >
            <span>
              {secondsToMinutes(utterance.start_time)} -{' '}
              {secondsToMinutes(utterance.end_time)}
            </span>
          </div>
          {hasSpeakerInfo && (
            <div className="shrink-0 w-28">{utterance.speaker}</div>
          )}
          <div className="grow relative group/clip cursor-pointer">
            {utterance.text}
            {/* Tooltip actions */}
            <div className="hidden absolute top-0 w-auto rounded-md bg-neutral-190/85 overflow-hidden group-hover/clip:block">
              <ul>
                <li
                  onClick={clickHandler.bind(this, utterance.start_time)}
                  className="block font-inter text-xs text-white py-1.5 px-2 hover:bg-white/20"
                >
                  Jump to clip
                </li>
              </ul>
            </div>
            {/* Tooltip actions */}
          </div>
          {FLAGS.ASSET_LIBRARY.TRANSCRIPT_BLUE_ROSE_SCORES && (
            <div className="shrink-0 w-11 text-right">
              <span className="bg-green-100/50 rounded-full text-white text-sm py-1 px-2">
                50
              </span>
            </div>
          )}
        </div>
      </div>
    );
  });
};

export default Transcript;
