import React, { useEffect, useState } from 'react';
import FilterAccordionItem from './FilterAccordionItem';
import EventBus from 'src/utils/eventBus';
import cx from 'classnames';

interface FilterAccordionItemProps {
  eventDomain: string;
  label: string;
  pool: string[];
  selected: string[];
}

const FilterItemTags: React.FC<FilterAccordionItemProps> = ({
  eventDomain,
  label,
  pool,
  selected,
}) => {
  const [search, setSearch] = useState('');

  const tagOptions = pool.map((tag) => {
    return (
      <div
        key={tag}
        className={cx(
          'transition-colors cursor-pointer flex gap-x-2 items-center py-2 px-3 rounded-[10px] border border-neutral-110 font-inter text-base tracking-wide',
          {
            'bg-green-100 border-green-100 text-white': selected.includes(tag),
            'bg-white hover:bg-green-100 hover:border-green-100 hover:text-white':
              !selected.includes(tag),
            hidden:
              !tag.toLowerCase().includes(search.toLowerCase()) &&
              search.length > 0,
          }
        )}
        onClick={() => {
          if (selected.includes(tag)) {
            EventBus.$emit(
              eventDomain,
              selected.filter((t) => t !== tag)
            );
          } else {
            EventBus.$emit(eventDomain, [...selected, tag]);
          }
        }}
      >
        <span>{tag}</span>
      </div>
    );
  });

  useEffect(() => {
    setSearch('');
  }, [pool]);

  return (
    <FilterAccordionItem label={label}>
      <div className="pb-5">
        <input
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
          className="w-full rounded-md border border-neutral-110 py-2 px-4 font-inter text-sm text-neutral-140 outline-0 ring-0 focus:border-green-100"
          type="search"
          placeholder="Search topics"
        />
      </div>
      <div className="h-80 scrollbar scrollbar-track-neutral-100 scrollbar-thumb-neutral-160/20 overflow-y-scroll">
        {/* Tags options */}
        <div className="flex items-start flex-col gap-2">{tagOptions}</div>
        {/* Tags options */}
      </div>
    </FilterAccordionItem>
  );
};

export default FilterItemTags;
