import Spinner from '@/components/Spinner';
import TermsAcknowledgement from '@/components/TermsAcknowledgement';
import ZFilters from '@/components/zeitgeist/ZFilters';
import AssetLibraryParams from '@/interfaces/AssetLibraryParams';
import Cluster, { Article, CLUSTER_SORT, Run } from '@/interfaces/Cluster';
import '@/styles/zeitgeist.scss';
import { getAuth } from '@firebase/auth';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import ClusterCard from 'src/components/zeitgeist/ClusterCard';
import { FIRESTORE_COLLECTION_NAMES } from 'src/defaults';
import EventBus from 'src/utils/eventBus';
import { db } from '../firebase';
import Analytics from 'src/utils/analytics';

const Zeitgeist: React.FC = () => {
  const auth = getAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  // Runs
  const [allRuns, setAllRuns] = useState<Run[]>();
  const [selectedRun, setSelectedRun] = useState<Run>();

  // Clusters
  const [allClusters, setAllClusters] = useState<Cluster[]>([]);
  const [displayClusters, setDisplayClusters] = useState<Cluster[]>([]);
  const [clusterSort, setClusterSort] = useState<CLUSTER_SORT>(
    CLUSTER_SORT.ARTICLE_COUNT
  );

  // DB
  const clusterCollectionName = FIRESTORE_COLLECTION_NAMES.CLUSTERS;
  const runsCollectionName = FIRESTORE_COLLECTION_NAMES.RUNS;

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openCardIndex, setOpenCardIndex] = useState<number>();

  // topic filter
  const [allTopics, setAllTopics] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [params, setParams] = useState<AssetLibraryParams>({
    topics: [],
    vibes: [],
    speakers: [],
    brs: 0,
    sort: 'rel',
  });

  useEffect(() => {
    if (!allRuns?.length) {
      fetchRuns();
    }
  }, []);

  useEffect(() => {
    sortClusters();
  }, [clusterSort]);

  useEffect(() => {
    if (selectedRun) {
      setIsLoading(true);

      if (allRuns) {
        setRunSelection(allRuns);
      }

      setParams((oldParams) => {
        const newParams = { ...oldParams };
        newParams.topics = [];
        return newParams;
      });

      fetchClusters();
    }
  }, [selectedRun]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY + 1 >=
        document.body.offsetHeight
      ) {
        Analytics.log('zeitgeist_page_scroll');
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    filterClustersByTopic();
  }, [params]);

  useEffect(() => {
    EventBus.$on('params:topics', (value: string[]) => {
      setParams((curParams) => {
        const newParams = { ...curParams };
        newParams.topics = value;

        return newParams;
      });
    });

    EventBus.$on('params:clear', () => {
      setParams((curParams) => {
        const newParams = { ...curParams };
        newParams.topics = [];

        return newParams;
      });
    });

    return () => {
      EventBus.$off('params:topics');
      EventBus.$off('params:clear');
    };
  }, [params]);

  const fetchClusters = async () => {
    if (!userExists()) {
      return;
    }

    const _clusters: Cluster[] = [];

    const clustersRef = collection(
      db,
      `${runsCollectionName}/${selectedRun?.id}/${clusterCollectionName}`
    );

    const clusterQuery = query(clustersRef, orderBy('article_count', 'desc'));
    const clustersSnapshot = await getDocs(clusterQuery);

    clustersSnapshot.forEach((clusterDocument) => {
      const cluster = {
        ...clusterDocument.data(),
        id: clusterDocument.id,
      } as Cluster;
      filterArticles(cluster);
      _clusters.push(cluster);
    });

    setAllClusters(_clusters);
    setDisplayClusters(_clusters);
    setIsLoading(false);
  };

  const fetchRuns = async () => {
    if (!userExists()) {
      return;
    }

    if (!runsCollectionName) {
      console.error('No collection name provided');
      return;
    }

    const windows = [1, 3, 7];
    const runsList: Run[] = [];

    for (let i = 0; i < windows.length; i++) {
      // Get runs
      const windowSize = windows[i];
      const runsRef = collection(db, runsCollectionName);
      const runsQuery = query(
        runsRef,
        orderBy('timestamp', 'desc'),
        where('window', '==', windowSize),
        limit(1)
      );
      const runSnapshot = await getDocs(runsQuery);
      const runDoc = runSnapshot.docs[0];
      runsList.push({ ...runDoc.data(), id: runDoc.id } as Run);
    }

    setAllRuns(runsList);
    setRunSelection(runsList);
  };

  const filterArticles = (cluster: Cluster) => {
    const articleSet = new Set();
    const newArticles: Article[] = [];

    cluster.articles.forEach((article: Article) => {
      if (!articleSet.has(article.url)) {
        articleSet.add(article.url);
        newArticles.push(article);
      }
    });

    cluster.articles = newArticles;
  };

  const filterClustersByTopic = async () => {
    if (params.topics.length) {
      const filteredClusters = allClusters.filter((cluster) =>
        cluster.topics.some((item) => params.topics.includes(item))
      );
      setDisplayClusters(filteredClusters);
    } else {
      setDisplayClusters(allClusters);
    }
  };

  const setRunSelection = (runsList: Run[]) => {
    let runSelection: Run;
    const requestedRun = searchParams.get('run');

    if (requestedRun) {
      const selectedRun = runsList.find((run: Run) => run.id === requestedRun);

      if (selectedRun) {
        runSelection = selectedRun;
      } else {
        runSelection = runsList[0];
      }
    } else {
      runSelection = runsList[0];
    }

    setAllTopics(runSelection.canonical_topics.sort());
    setSelectedRun(runSelection);
  };

  const sortClusters = () => {
    let sortFn: (a: Cluster, b: Cluster) => number;

    switch (clusterSort) {
      case CLUSTER_SORT.SCORE:
        sortFn = (clusterA: Cluster, clusterB: Cluster) =>
          clusterA.interpretation.grade > clusterB.interpretation.grade
            ? -1
            : 1;
        break;
      case CLUSTER_SORT.ARTICLE_COUNT:
      default:
        sortFn = (clusterA: Cluster, clusterB: Cluster) =>
          clusterA.article_count > clusterB.article_count ? -1 : 1;
        break;
    }

    const sortedClusters = [...displayClusters];
    sortedClusters.sort(sortFn);

    setDisplayClusters(sortedClusters);
  };

  const userExists = () => {
    const user = auth.currentUser;

    if (!user) {
      console.error('No user available');
    }

    return user;
  };

  const handleOpenCard = (index: number) => {
    if (index !== undefined) {
      setOpenCardIndex(index);
      Analytics.log('zeitgeist_trend_view', {
        user_id: auth.currentUser.uid,
        cluster_id: index,
      });
    }
  };

  // Interface
  const clusterCards = displayClusters.map((cluster, index) => (
    <ClusterCard
      key={cluster.id}
      index={index}
      isOpen={openCardIndex === index}
      cluster={cluster}
      run={selectedRun}
      setOpenIndex={handleOpenCard}
    />
  ));

  const loadingScreen = (
    <div className="loading">
      <Spinner />
    </div>
  );

  const getTooltipAnchor = (text: string) => {
    return (
      <div
        className="info bg-ico-info"
        data-tooltip-id="tooltip"
        data-tooltip-content={text}
      ></div>
    );
  };

  return (
    <div className="zeitgeist_wrapper">
      <p className="top_header">Recent media trends and analysis</p>
      <p className="subheader">
        Discover recent trending topics, complete with sourced articles and
        relevant video assets from our continuously updated library.
      </p>

      <div className="filter_wrapper">
        <div className="selects">
          <select
            name="run_selection"
            className="bg-ico-sort"
            id="run_selection"
            disabled={isLoading}
            value={selectedRun?.id}
            onChange={(event) => {
              setSelectedRun(
                allRuns?.find((run: Run) => run.id === event.target.value)
              );
              setSearchParams(new URLSearchParams(`run=${event.target.value}`));
            }}
          >
            {allRuns?.map((run) => (
              <option key={run.id} value={run.id}>
                {run.window === 1 ? 'Last 24 hours' : `Last ${run.window} days`}
              </option>
            ))}
          </select>

          <select
            onChange={(event) => {
              setClusterSort(parseInt(event.target.value) as CLUSTER_SORT);
            }}
            disabled={isLoading}
            className="recency bg-ico-sort"
          >
            <option value={CLUSTER_SORT.ARTICLE_COUNT}>By article count</option>
            <option value={CLUSTER_SORT.SCORE}>By usefulness</option>
          </select>
        </div>
        <div className="filters" onClick={() => setShowFilters(true)}>
          <span className="hover:text-green-100 cursor-pointer">
            Filter by Topic&nbsp;
          </span>
          <i className="block w-6 h-6 bg-ico-filters bg-center bg-no-repeat"></i>
        </div>
      </div>

      <ZFilters
        filteredClusters={displayClusters}
        params={params}
        showFilters={showFilters}
        topics={allTopics}
        setShowFilters={setShowFilters}
      />

      <div className="results">
        <div className="headers">
          <div className="subject">Subject</div>
          <div className="topics">Topics</div>
          <div className="articles">
            Articles
            {getTooltipAnchor(
              'The more articles there are about a subject, the higher it will be listed.'
            )}
          </div>
          <div className="videos">
            {selectedRun?.window !== 1 &&
              `Articles in the Past ${selectedRun?.window} days`}
            {/* {getTooltipAnchor(
              'Video files from our database relevant to the topics.'
            )} */}
          </div>

          <Tooltip arrowColor="transparent" id="tooltip" place="bottom-start" />
        </div>
        {isLoading ? loadingScreen : clusterCards}
      </div>
      <TermsAcknowledgement />
    </div>
  );
};

export default Zeitgeist;
