/* eslint-disable @typescript-eslint/no-unsafe-function-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import emitter from 'tiny-emitter/instance';

const EventBus = {
  $on: (event: string, callback: Function) => emitter.on(event, callback),
  $once: (event: string, callback: Function) => emitter.once(event, callback),
  $off: (event: string, callback?: Function) => {
    if (callback) {
      emitter.off(event, callback);
      return;
    }
    emitter.off(event);
  },
  $emit: (event: string, ...args: any[]) => emitter.emit(event, ...args),
};

export default EventBus;
