import React, { useState, useEffect } from 'react';
import EventBus from 'src/utils/eventBus';
import cx from 'classnames';

const Modal: React.FC = () => {
  const [visible, setVisible] = useState(false),
    [title, setTitle] = useState(''),
    [text, setText] = useState('');

  useEffect(() => {
    EventBus.$on('modal:edit:show', (text: string) => {
      setVisible(true);
      switch (text) {
        case 'edit_submitted':
          setTitle('Edit submitted');
          setText(
            'Your video is being edited. When it’s finished you’ll see a \
            notification in the sidebar. You can download your edit in the \
            Notifications page.'
          );
          break;
        case 'error':
          setTitle('Error');
          setText('An error has occurred. Please try again later.');
          break;
      }
    });
    return () => {
      EventBus.$off('modal:edit:show');
    };
  }, []);

  return (
    <div
      className={cx(
        { hidden: !visible },
        'fixed flex justify-center items-center z-50 top-0 left-0 w-full h-screen bg-neutral-60/70'
      )}
    >
      <div className="w-[484px] rounded-[10px] bg-neutral-40 shadow-lg overflow-hidden">
        <div className="h-16 px-6 flex justify-between items-center border-b border-neutral-110 bg-neutral-80">
          <span className="font-source_serif_pro text-xl text-neutral-170">
            {title}
          </span>
          <i
            onClick={() => setVisible(false)}
            className="block w-4 h-4 bg-ico-close bg-center bg-no-repeat bg-contain cursor-pointer"
          ></i>
        </div>
        <div className="py-5 px-7">
          <p className="font-inter text-base text-neutral-180">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
