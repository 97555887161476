import ClusterDetail from '@/components/zeitgeist/ClusterDetail';
import Cluster, { Run } from '@/interfaces/Cluster';
import '@/styles/components/clusterCard.scss';
import { Dispatch, SetStateAction } from 'react';
import { Tooltip } from 'react-tooltip';
import ArticleGraph from './ArticleGraph';

interface ClusterCard {
  cluster: Cluster;
  index: number;
  isOpen: boolean;
  run: Run | undefined;
  setOpenIndex: Dispatch<SetStateAction<number | undefined>>;
}

const ClusterCard: React.FC<ClusterCard> = ({
  cluster,
  index,
  isOpen,
  run,
  setOpenIndex,
}) => {
  const moreNum: number = cluster.topics.length - 2;
  const topicsPreview =
    cluster.topics.length > 2 ? cluster.topics.slice(0, 2) : cluster.topics;

  const tooltipHTML = `${cluster.topics.slice(2).join(',<br/>')}`;
  const tooltipId = `more-topics-${cluster.id}`;

  return (
    <div className="cluster_card">
      <div
        className={`display_info ${isOpen ? '' : 'collapsed'}`}
        onClick={() => {
          setOpenIndex(isOpen ? undefined : index);
        }}
      >
        <div className="subject">
          <span>{cluster.interpretation.subject}</span>
        </div>
        <div className="topics list">
          {topicsPreview.map((topic) => (
            <span key={`${cluster.id}-${topic}`} className="topic">
              {topic}
            </span>
          ))}
          {moreNum > 0 && (
            <div className="tooltip">
              <span
                data-tooltip-id={tooltipId}
                data-tooltip-html={tooltipHTML}
                className="view_more"
              >
                {moreNum} more
              </span>
              <Tooltip
                arrowColor="transparent"
                id={tooltipId}
                place="bottom-start"
                clickable
                opacity={1}
              />
            </div>
          )}
        </div>

        <div className="articles">
          <span>{cluster.articles.length} articles</span>
        </div>
        <div className="videos list">
          {run?.window !== 1 && <ArticleGraph cluster={cluster} run={run} />}
        </div>
      </div>
      <ClusterDetail cluster={cluster} runName={run?.id} isOpen={isOpen} />
    </div>
  );
};

export default ClusterCard;
