/*
 *
 *
 * @param {string} text
 * @param {number} maxLength
 * @returns {string}
 * This function truncates the text to the given length and adds an ellipsis at the end, if necessary,
 * preserving whole words.
 *
 */
export default function truncateText(
  text: string | undefined,
  maxLength: number
): string {
  if (!text) {
    return '';
  }
  if (text.length <= maxLength) {
    return text;
  }
  const truncated = text.substr(0, maxLength);
  return truncated.slice(0, truncated.lastIndexOf(' ')) + '...';
}
