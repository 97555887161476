import { EditRequestNotification } from '@/interfaces/EditRequestNotification';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNotificationsContext } from 'src/contexts/notificationsProvider';

const NotificationBanner: React.FC = () => {
  const notificationsCtx = useNotificationsContext();
  const location = useLocation();
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    const unreadCompletedNotifications = notificationsCtx.notifications.filter(
      (noti: EditRequestNotification) =>
        !noti.read && noti.status === 'completed',
      0
    );

    setIsShown(
      !!unreadCompletedNotifications.length &&
        location.pathname !== '/notifications'
    );
  }, [notificationsCtx.notifications, location]);

  return (
    <>
      {isShown && (
        <div className="flex absolute top-0 left-0 right-0 bg-left bg-[#d7e0dd]">
          <div className="bg-green-120 flex-[0_0_4px]"></div>
          <div className="flex flex-1 items-center mx-4 my-5 justify-between">
            <div className="flex gap-x-2 items-center">
              <i className="block w-6 h-6 bg-ico-check-circle"></i>
              <div className="font-inter text-neutral-180">
                Your video is ready to download!
              </div>
            </div>
            <div className="flex gap-x-4 items-center">
              <Link to={'/notifications'}>Go to notifications</Link>
              <i
                className="block w-6 h-6 bg-ico-close bg-no-repeat bg-center cursor-pointer"
                onClick={() => setIsShown(false)}
              ></i>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationBanner;
