import React from 'react';

interface TopicPillProps {
  label: string;
}

const TopicPill: React.FC<TopicPillProps> = ({ label }) => {
  return (
    <span className="transition-colors py-1 px-2 rounded-md border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-xs tracking-wide">
      {label}
    </span>
  );
};

export default TopicPill;
