import React, { useState, useEffect } from 'react';
import FLAGS from 'src/featureFlags';
import FilterItemBlueRose from './FilterItemBlueRose';
import FilterItemTags from './FilterItemTags';
import SelectedFilterPills from './SelectedFilterPills';
import EventBus from 'src/utils/eventBus';
import Params, {
  AssetLibraryFilterSet,
} from 'src/interfaces/AssetLibraryParams';
import Asset from 'src/interfaces/Asset';
import pluralize from 'pluralize';
import featureFlags from 'src/featureFlags';

interface FiltersProps {
  assets: { [key: string]: Asset };
  filteredAssets: Asset[];
  metadataLoaded: boolean;
  params: Params;
  percentLoaded: number;
  setParams: (params: Params) => void;
  showAnnotations: boolean;
  setShowAnnotations: (showAnnotations: boolean) => void;
  statusMessage: React.ReactNode;
}

const Filters: React.FC<FiltersProps> = ({
  assets,
  filteredAssets,
  metadataLoaded,
  params,
  percentLoaded,
  setParams,
  showAnnotations,
  setShowAnnotations,
  statusMessage,
}) => {
  const annotationToggle = () => setShowAnnotations(!showAnnotations);
  const [filtersLayer, setFiltersLayer] = useState(false);
  const [filters, setFilters] = useState<AssetLibraryFilterSet>({
      topics: [],
      vibes: [],
      speakers: [],
      brs: [0, 25, 50, 75],
    }),
    extractUniqueValues = (key: 'topics' | 'vibes' | 'speakers') => {
      return Object.values(
        Object.values(assets).reduce(
          (acc, asset) => {
            if (asset.annotations) {
              if (asset.annotations[key]) {
                Object.values(asset.annotations[key])
                .filter((value): value is string => value !== null)
                .forEach((value) => {
                  acc[value.toLocaleLowerCase()] = value;
                });
              }
            }
            return acc;
          },
          {} as { [key: string]: string }
        )
      ).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
    },
    updateBlueRoseParam = (value: number) => {
      setParams({ ...params, brs: value });
    },
    sortLabels = {
      rel: 'relevance',
      date: 'date added',
      brs: 'Blue Rose score',
    },
    filtersActive =
      params.topics.length > 0 ||
      params.vibes.length > 0 ||
      params.speakers.length > 0 ||
      params.brs > 0;

  useEffect(() => {
    if (metadataLoaded) {
      const topics = extractUniqueValues('topics'),
        vibes = extractUniqueValues('vibes'),
        speakers = extractUniqueValues('speakers');
      setFilters({ ...filters, topics, vibes, speakers });
    }
  }, [metadataLoaded]);

  return (
    <div>
      {/* Filters Layer */}
      <div
        className={`transition-all overflow-y-scroll duration-300 fixed z-50 top-0 pl-10 pr-20 pt-28 w-96 h-screen bg-neutral-40 shadow-filters ${filtersLayer ? 'right-0' : '-right-full'}`}
      >
        <div className="pb-10 border-b border-neutral-110">
          <div className="w-full flex items-center gap-x-4">
            <span className="font-source_serif_pro text-xl">All Filters</span>
            <span className="font-inter text-base text-neutral-140">
              {pluralize('result', filteredAssets.length, true)}
            </span>
            <i
              onClick={() => setFiltersLayer(!filtersLayer)}
              className="ml-auto block w-4 h-4 bg-ico-close bg-center bg-no-repeat bg-contain cursor-pointer"
            ></i>
          </div>

          <SelectedFilterPills params={params} />
        </div>

        <div className="flex flex-col">
          <FilterItemTags
            pool={filters.topics}
            label="Topic"
            selected={params.topics}
            eventDomain="params:topics"
          />
          <FilterItemTags
            pool={filters.vibes}
            label="Vibe"
            selected={params.vibes}
            eventDomain="params:vibes"
          />
          <FilterItemTags
            pool={filters.speakers}
            label="Speaker"
            selected={params.speakers}
            eventDomain="params:speakers"
          />
          {!featureFlags.ASSET_SEARCH.SCORE && (
            <FilterItemBlueRose
              current={params.brs}
              change={updateBlueRoseParam}
            />
          )}
        </div>
      </div>
      {/* Filters Layer */}

      {filtersActive && (
        <div className="my-10">
          <div className="text-neutral-190 text-[22px] font-source_serif_pro">
            Filters
            <a
              className="cursor-pointer inline-block font-inter ml-10 text-[16px] text-green-100"
              onClick={() => EventBus.$emit('params:clear')}
            >
              Clear all
            </a>
          </div>
          <SelectedFilterPills params={params} />
        </div>
      )}

      <div className="flex items-center justify-between font-inter text-base text-neutral-170 mb-2.5">
        <div className="flex max-w-96 gap-x-1">{statusMessage}</div>
        <div className="shrink-0 flex items-center gap-x-6">
          {FLAGS.ASSET_LIBRARY.SHOW_ANNOTATIONS_SWITCH && (
            <div
              onClick={annotationToggle}
              className="flex items-center gap-x-3 cursor-pointer hover:text-green-100"
            >
              <span>Expand all</span>
              <button
                className={`w-10 h-5 rounded-full flex items-center transition duration-300 focus:outline-none ${
                  showAnnotations ? 'bg-green-100' : 'bg-neutral-120'
                }`}
              >
                <div
                  className={`w-4 h-4 relative rounded-full transition duration-500 transform bg-white ${
                    showAnnotations ? 'translate-x-[22px]' : 'translate-x-0.5'
                  }`}
                ></div>
              </button>
            </div>
          )}

          {FLAGS.ASSET_LIBRARY.SHOW_FILTERS_SWITCH && (
            <div
              onClick={() => metadataLoaded && setFiltersLayer(!filtersLayer)}
              className={`flex items-center gap-x-3 cursor-pointer ${!metadataLoaded ? 'text-neutral-140' : 'text-neutral-180'} hover:text-green-100`}
            >
              <span>Show filters</span>
              {metadataLoaded && (
                <i className="block w-6 h-6 bg-ico-filters bg-center bg-no-repeat"></i>
              )}
              {!metadataLoaded && (
                <>
                  <svg
                    className="animate-spin h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {percentLoaded}%{' '}
                </>
              )}
            </div>
          )}

          {FLAGS.ASSET_LIBRARY.SORT_BY && (
            <div className="group cursor-pointer text-neutral-170 relative">
              <div className="flex items-center justify-between gap-x-3">
                <span className="font-inter text-base">
                  Sort by {sortLabels[params.sort]}
                </span>
                <i className="block w-4 h-4 bg-ico-arrow-down bg-center bg-no-repeat"></i>
              </div>

              {/* dropdown */}
              <div className="absolute w-auto whitespace-nowrap hidden z-50 rounded-b-md top-full right-0 bg-neutral-60 border border-neutral-110 border-t-0 group-hover:block">
                <ul className="w-full">
                  {FLAGS.ASSET_LIBRARY.SORT_BY_RELEVANCE && (
                    <li
                      className="transition-colors block font-inter text-base text-neutral-140 py-3 pl-4 pr-11 border-t border-neutral-110 hover:bg-neutral-80 hover:text-neutral-170"
                      onClick={() => setParams({ ...params, sort: 'rel' })}
                    >
                      Relevance
                    </li>
                  )}
                  <li
                    className="transition-colors block font-inter text-base text-neutral-140 py-3 pl-4 pr-11 border-t border-neutral-110 hover:bg-neutral-80 hover:text-neutral-170"
                    onClick={() => setParams({ ...params, sort: 'date' })}
                  >
                    Date Added
                  </li>
                  <li
                    className="transition-colors block font-inter text-base text-neutral-140 py-3 pl-4 pr-11 border-t border-neutral-110 hover:bg-neutral-80 hover:text-neutral-170"
                    onClick={() => setParams({ ...params, sort: 'brs' })}
                  >
                    Blue Rose Score
                  </li>
                </ul>
              </div>
              {/* //dropdown */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filters;
