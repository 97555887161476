import Spinner from '@/components/Spinner';
import TermsAcknowledgement from '@/components/TermsAcknowledgement';
import ZFilters from '@/components/zeitgeist/ZFilters';
import AssetLibraryParams from '@/interfaces/AssetLibraryParams';
import EICluster, { Article, CLUSTER_SORT, Run } from '@/interfaces/Cluster';
import '@/styles/zeitgeist.scss';
import { getAuth } from '@firebase/auth';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import EIClusterCard from 'src/components/zeitgeist/EIClusterCard';
import Analytics from 'src/utils/analytics';
import EventBus from 'src/utils/eventBus';
import { db } from '../firebase';


const EDayZeitgeist: React.FC = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Search
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [timeRange, setTimeRange] = useState<string>('');
  const [searchScore, setSearchScore] = useState<string>('');

  // Runs
  const [allRuns, setAllRuns] = useState<Run[]>();
  const [selectedRun, setSelectedRun] = useState<Run>();

  // Clusters
  const [allClusters, setAllClusters] = useState<EICluster[]>([]);
  const [displayClusters, setDisplayClusters] = useState<EICluster[]>([]);
  const [clusterSort, setClusterSort] = useState<CLUSTER_SORT>(
    CLUSTER_SORT.ARTICLE_COUNT
  );

  // DB
  const clusterCollectionName = "ei_clusters";
  const runsCollectionName = "ei_cluster_runs";

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openCardIndex, setOpenCardIndex] = useState<number>();

  // topic filter
  const [allTopics, setAllTopics] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [params, setParams] = useState<AssetLibraryParams>({
    topics: [],
    vibes: [],
    speakers: [],
    brs: 0,
    sort: 'rel',
  });

  useEffect(() => {
    if (!allRuns?.length) {
      fetchRuns();
    }
  }, []);

  useEffect(() => {
    sortClusters();
  }, [clusterSort]);

  useEffect(() => {
    if (selectedRun) {
      setIsLoading(true);

      if (allRuns) {
        setRunSelection(allRuns);
      }

      setParams((oldParams) => {
        const newParams = { ...oldParams };
        newParams.topics = [];
        return newParams;
      });

      fetchClusters();
    }
  }, [selectedRun]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY + 1 >=
        document.body.offsetHeight
      ) {
        Analytics.log('election_page_scroll');
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    filterClustersByTopic();
  }, [params]);

  useEffect(() => {
    EventBus.$on('params:topics', (value: string[]) => {
      setParams((curParams) => {
        const newParams = { ...curParams };
        newParams.topics = value;

        return newParams;
      });
    });

    EventBus.$on('params:clear', () => {
      setParams((curParams) => {
        const newParams = { ...curParams };
        newParams.topics = [];

        return newParams;
      });
    });

    return () => {
      EventBus.$off('params:topics');
      EventBus.$off('params:clear');
    };
  }, [params]);

  const fetchClusters = async () => {
    if (!userExists()) {
      return;
    }

    const _clusters: EICluster[] = [];

    const clustersRef = collection(
      db,
      `${runsCollectionName}/${selectedRun?.id}/${clusterCollectionName}`
    );

    const clusterQuery = query(clustersRef, orderBy('article_count', 'desc'));
    const clustersSnapshot = await getDocs(clusterQuery);

    clustersSnapshot.forEach((clusterDocument) => {
      const cluster = {
        ...clusterDocument.data(),
        id: clusterDocument.id,
      } as EICluster;
      filterArticles(cluster);
      _clusters.push(cluster);
    });

    setAllClusters(_clusters);
    setDisplayClusters(_clusters);
    setIsLoading(false);
  };

  const fetchRuns = async () => {
    if (!userExists()) {
      return;
    }

    if (!runsCollectionName) {
      console.error('No collection name provided');
      return;
    }

    const windows = [1, 3, 7];
    const runsList: Run[] = [];

    for (let i = 0; i < windows.length; i++) {
      // Get runs
      const windowSize = windows[i];
      const runsRef = collection(db, runsCollectionName);
      const runsQuery = query(
        runsRef,
        orderBy('timestamp', 'desc'),
        limit(1)
      );
      const runSnapshot = await getDocs(runsQuery);
      const runDoc = runSnapshot.docs[0];
      runsList.push({ ...runDoc.data(), id: runDoc.id } as Run);
    }

    setAllRuns(runsList);
    setRunSelection(runsList);
  };

  const filterArticles = (cluster: EICluster) => {
    const articleSet = new Set();
    const newArticles: Article[] = [];

    cluster.articles.forEach((article: Article) => {
      if (!articleSet.has(article.url)) {
        articleSet.add(article.url);
        newArticles.push(article);
      }
    });

    cluster.articles = newArticles;
  };

  const filterClustersByTopic = async () => {
    if (params.topics.length) {
      const filteredClusters = allClusters.filter((cluster) =>
        cluster.topics.some((item) => params.topics.includes(item))
      );
      setDisplayClusters(filteredClusters);
    } else {
      setDisplayClusters(allClusters);
    }
  };

  const setRunSelection = (runsList: Run[]) => {
    let runSelection: Run;
    const requestedRun = searchParams.get('run');

    if (requestedRun) {
      const selectedRun = runsList.find((run: Run) => run.id === requestedRun);

      if (selectedRun) {
        runSelection = selectedRun;
      } else {
        runSelection = runsList[0];
      }
    } else {
      runSelection = runsList[0];
    }

    setAllTopics(runSelection.canonical_topics.sort());
    setSelectedRun(runSelection);
  };

  const sortClusters = () => {
    let sortFn: (a: EICluster, b: EICluster) => number;

    switch (clusterSort) {
      case CLUSTER_SORT.SCORE:
        sortFn = (clusterA: EICluster, clusterB: EICluster) =>
          clusterA.interpretation.grade > clusterB.interpretation.grade
            ? -1
            : 1;
        break;
      case CLUSTER_SORT.ARTICLE_COUNT:
      default:
        sortFn = (clusterA: EICluster, clusterB: EICluster) =>
          clusterA.article_count > clusterB.article_count ? -1 : 1;
        break;
    }

    const sortedClusters = [...displayClusters];
    sortedClusters.sort(sortFn);

    setDisplayClusters(sortedClusters);
  };

  const userExists = () => {
    const user = auth.currentUser;

    if (!user) {
      console.error('No user available');
    }

    return user;
  };

  const handleOpenCard = (index: number) => {
    if (index !== undefined) {
      setOpenCardIndex(index);
      Analytics.log('election_trend_view', {
        user_id: auth.currentUser.uid,
        cluster_id: index,
      });
    }
  };

  // Interface
  const clusterCards = displayClusters.map((cluster, index) => (
    <EIClusterCard
      key={cluster.id}
      index={index}
      isOpen={openCardIndex === index}
      cluster={cluster}
      run={selectedRun}
      setOpenIndex={handleOpenCard}
    />
  ));

  const loadingScreen = (
    <div className="loading">
      <Spinner />
    </div>
  );

  const getTooltipAnchor = (text: string) => {
    return (
      <div
        className="info bg-ico-info"
        data-tooltip-id="tooltip"
        data-tooltip-content={text}
      ></div>
    );
  };

  const handleQuickSearch = (query: string, timeRange: string) => {
    if (!query?.trim()) {
      return;
    }

    Analytics.log('election_quick_search', {
      user_id: auth.currentUser?.uid,
      query,
      timeRange
    });

    const searchParams = new URLSearchParams();

    searchParams.set('timeRange', timeRange);

    const searchPath = `/search/${encodeURIComponent(query)}${searchParams.toString() ? '?' + searchParams.toString() : ''}`;

    navigate(searchPath);
  };


  const DefaultSearchTile = (term: string, title: string, timeRange: string): JSX.Element => {
    return (
      <div
        className="px-4 py-3 rounded-lg bg-neutral-100 border border-neutral-110 text-neutral-160 font-inter leading-[1.4rem] cursor-pointer"
        onClick={() => {
          handleQuickSearch(term, timeRange);
        }}
      >
        {title}
      </div>
    );
  };

  const presidentialQueries = [
    { region: "Arizona", query: "Arizona election" },
    { region: "Georgia", query: "Georgia election" },
    { region: "Michigan", query: "Michigan election" },
    { region: "Nevada", query: "Nevada election" },
    { region: "North Carolina", query: "North Carolina election" },
    { region: "Pennsylvania", query: "Pennsylvania election" },
    { region: "Wisconsin", query: "Wisconsin election" },
    { region: "Minnesota", query: "Minnesota election" }
  ];

  presidentialQueries.sort((a, b) => a.region.localeCompare(b.region));

  const senateQueries = [
    { region: "Maryland", query: "Maryland Angela Alsobrooks" },
    { region: "Wisconsin", query: "Wisconsin Tammy Baldwin" },
    { region: "Ohio", query: "Ohio Sherrod Brown" },
    { region: "Texas", query: "Texas Colin Allred" },
    { region: "Montana", query: "Montana Jon Tester" },
    { region: "Nebraska", query: "Nebraska Preston Love Jr." },
    { region: "Florida", query: "Florida Debbie Mucarsel-Powell" },
    { region: "Pennsylvania", query: "Pennsylvania Bob Casey" },
    { region: "Michigan", query: "Michigan Elissa Slotkin" }
  ];

  senateQueries.sort((a, b) => a.region.localeCompare(b.region));

  type StateQuery = {
  region: string;
  query: string;
  };

  // Transform presidential queries into rows of 8
  const presRows = presidentialQueries.reduce((rows: StateQuery[][], item: StateQuery, index: number) => {
    if (index % 10 === 0) {
      rows.push([item]);
    } else {
      rows[rows.length - 1].push(item);
    }
    return rows;
  }, []);

  // Transform senate queries into rows of 8
  const senateRows = senateQueries.reduce((rows: StateQuery[][], item: StateQuery, index: number) => {
    if (index % 10 === 0) {
      rows.push([item]);
    } else {
      rows[rows.length - 1].push(item);
    }
    return rows;
  }, []);

  return (
    <div className="zeitgeist_wrapper">
      <p className="top_header">Election Dashboard</p>
      <p className="subheader">
        Discover recent trending topics, complete with sourced articles and
        relevant video assets from our curated election day coverage.
      </p>
      <div className="mt-12 text-neutral-190 font-source_serif_pro text-xl">
        Quick Search
      </div>
      <div className="flex mt-4 gap-x-[10px]">
        {DefaultSearchTile('collection:election-day', "Curated E-Day Content", "1")}
      </div>
      <div className="mt-8 text-neutral-190 font-source_serif_pro text-xl">
        Battleground States
      </div>
      <div className="flex flex-col gap-y-5 mt-4">
      {presRows.map((row, rowIndex) => (
        <div key={rowIndex} className="flex gap-x-5">
          {row.map((stateQuery: StateQuery, index: number) => (
            <div key={index}>
              {DefaultSearchTile(stateQuery.query, stateQuery.region, "1")}
            </div>
          ))}
        </div>
      ))}
      </div>
      <div className="mt-8 text-neutral-190 font-source_serif_pro text-xl">
        Senate Races
      </div>
      <div className="flex flex-col gap-y-5 mt-4">
      {senateRows.map((row, rowIndex) => (
        <div key={rowIndex} className="flex gap-x-5">
          {row.map((stateQuery: StateQuery, index: number) => (
            <div key={index}>
              {DefaultSearchTile(stateQuery.query, stateQuery.region, "30")}
            </div>
          ))}
        </div>
      ))}
      </div>

      <div className="filter_wrapper">
        <div className="selects">
        </div>
        <div className="filters" onClick={() => setShowFilters(true)}>
          <span className="hover:text-green-100 cursor-pointer">
            Filter by Topic&nbsp;
          </span>
          <i className="block w-6 h-6 bg-ico-filters bg-center bg-no-repeat"></i>
        </div>
      </div>

      <ZFilters
        filteredClusters={displayClusters}
        params={params}
        showFilters={showFilters}
        topics={allTopics}
        setShowFilters={setShowFilters}
      />

      <div className="results">
        <div className="headers">
          <div className="subject">Subject</div>
          <div className="topics">Topics</div>
          <div className="articles">
            Articles
            {getTooltipAnchor(
              'The more articles there are about a subject, the higher it will be listed.'
            )}
          </div>
          <div className="videos">
            {selectedRun?.window !== 1 &&
              `Articles in the Past ${selectedRun?.window} days`}
            {/* {getTooltipAnchor(
              'Video files from our database relevant to the topics.'
            )} */}
          </div>

          <Tooltip arrowColor="transparent" id="tooltip" place="bottom-start" />
        </div>
        {isLoading ? loadingScreen : clusterCards}
      </div>
      <TermsAcknowledgement />
    </div>
  );
};

export default EDayZeitgeist;
