import React, { useState, useEffect, useCallback } from 'react';
import { useNotificationsContext } from 'src/contexts/notificationsProvider';
import NotificationCard from './NotificationCard';
import { EditRequestNotification } from 'src/interfaces/EditRequestNotification';
import Switch from 'src/routes/shared-components/Switch';
import { doc, writeBatch } from 'firebase/firestore';
import { db } from 'src/firebase';
import FLAGS from 'src/featureFlags';
import { FIRESTORE_COLLECTION_NAMES } from 'src/defaults';

const defaultNotificationCountsByStatus = () => ({
  all: 0,
  completed: 0,
  processing: 0,
  submitted: 0,
  queued: 0,
});

const Notifications: React.FC = () => {
  const [notificationCountsByStatus, setNotificationCountsByStatus] = useState<{
      [key: string]: number;
    }>(defaultNotificationCountsByStatus()),
    [showUnreadOnly, setShowUnreadOnly] = useState(false),
    { notifications } = useNotificationsContext(),
    [checkedNotifications, setCheckedNotifications] = useState<
      EditRequestNotification[]
    >([]),
    filteredNotifications = showUnreadOnly
      ? notifications.filter((notification) => !notification.read)
      : notifications,
    notificationCheckChangeHandler = useCallback(
      (notification: EditRequestNotification) => {
        setCheckedNotifications((prev) => {
          if (prev.includes(notification)) {
            return prev.filter((i) => i !== notification);
          } else {
            return [...prev, notification];
          }
        });
      },
      []
    ),
    toggleAllNotifications = (value: boolean) => {
      if (value) {
        setCheckedNotifications(filteredNotifications);
      } else {
        setCheckedNotifications([]);
      }
    },
    markAsRead = async () => {
      try {
        const batch = writeBatch(db);
        checkedNotifications.forEach((notification) => {
          if (!notification.read) {
            const notificationRef = doc(
              db,
              FIRESTORE_COLLECTION_NAMES.EDIT_REQUESTS,
              notification.id
            );
            batch.update(notificationRef, { read: true });
          }
        });
        await batch.commit();
        console.log(`${checkedNotifications.length} marked as read`);
        setCheckedNotifications([]);
      } catch (error) {
        console.error('Error marking notifications as read:', error);
      }
    },
    deleteNotifications = async () => {
      try {
        const batch = writeBatch(db);
        checkedNotifications.forEach((notification) => {
          if (!notification.dismissed) {
            const notificationRef = doc(
              db,
              FIRESTORE_COLLECTION_NAMES.EDIT_REQUESTS,
              notification.id
            );
            batch.update(notificationRef, { dismissed: true });
          }
        });
        await batch.commit();
        console.log(`${checkedNotifications.length} dismissed`);
        setCheckedNotifications([]);
      } catch (error) {
        console.error('Error marking notifications as dismissed:', error);
      }
    };

  useEffect(() => {
    setNotificationCountsByStatus(defaultNotificationCountsByStatus());
    notifications.forEach((notification) => {
      setNotificationCountsByStatus((prev) => {
        return {
          ...prev,
          [notification.status]: (prev[notification.status] || 0) + 1,
        };
      });
    });
  }, [notifications]);

  return (
    <div>
      <div className="block font-source_serif_pro text-xl text-neutral-170 mb-4">
        Notifications
      </div>
      {/* Tags */}
      {FLAGS.NOTIFICATIONS.FILTERS && (
        <div className="flex items-center gap-x-3 mb-11">
          <div className="transition-colors flex items-center gap-x-2 cursor-pointer py-2 px-3 rounded-md border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-green-100 hover:text-white">
            <span>All</span> <span>{notificationCountsByStatus.all}</span>
          </div>

          {notificationCountsByStatus.completed > 0 && (
            <div className="transition-colors flex items-center gap-x-2 cursor-pointer py-2 px-3 rounded-md border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-green-100 hover:text-white">
              <span>Complete</span>{' '}
              <span>{notificationCountsByStatus.completed}</span>
            </div>
          )}

          {notificationCountsByStatus.submitted > 0 && (
            <div className="transition-colors flex items-center gap-x-2 cursor-pointer py-2 px-3 rounded-md border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-green-100 hover:text-white">
              <span>Submitted</span>{' '}
              <span>{notificationCountsByStatus.submitted}</span>
            </div>
          )}

          {notificationCountsByStatus.queued > 0 && (
            <div className="transition-colors flex items-center gap-x-2 cursor-pointer py-2 px-3 rounded-md border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-green-100 hover:text-white">
              <span>Queued</span>{' '}
              <span>{notificationCountsByStatus.queued}</span>
            </div>
          )}

          {notificationCountsByStatus.processing > 0 && (
            <div className="transition-colors flex items-center gap-x-2 cursor-pointer py-2 px-3 rounded-md border border-neutral-110 bg-neutral-80 font-inter text-neutral-160 text-base tracking-wide hover:bg-green-100 hover:text-white">
              <span>Processing</span>{' '}
              <span>{notificationCountsByStatus.processing}</span>
            </div>
          )}
        </div>
      )}
      {/* //Tags */}

      {/* Filters? */}
      <div className="flex items-center gap-x-5 font-inter text-base text-neutral-170 mb-3">
        <div>
          <label className="flex items-center gap-x-2 cursor-pointer">
            <input
              checked={
                filteredNotifications.length === checkedNotifications.length &&
                checkedNotifications.length != 0
              }
              className="w-4 h-4 rounded border-neutral-110 text-green-100 focus:ring-0 focus:outline-0 focus:ring-offset-0"
              type="checkbox"
              onChange={(evt) => toggleAllNotifications(evt.target.checked)}
            />{' '}
            Select all
          </label>
        </div>
        {checkedNotifications.length > 0 && (
          <div className="flex items-center gap-x-5">
            <div
              className="flex items-center gap-x-2 cursor-pointer"
              onClick={markAsRead}
            >
              <i className="block w-[18px] h-[18px] bg-no-repeat bg-contain bg-center bg-ico-mark-read"></i>
              <span className="text-green-100">Mark as read</span>
            </div>
            <div
              className="flex items-center gap-x-2 cursor-pointer"
              onClick={deleteNotifications}
            >
              <i className="block w-[18px] h-[18px] bg-no-repeat bg-contain bg-center bg-ico-delete"></i>
              <span className="text-green-100">Delete</span>
            </div>
          </div>
        )}

        <div className="shrink-0 ml-auto">
          <Switch
            className="mt-2"
            value={showUnreadOnly}
            label="Only show unread"
            onChange={() => setShowUnreadOnly(!showUnreadOnly)}
          />
        </div>
      </div>
      {/* //Filters? */}

      {/* Notifications list */}
      <div className="block">
        {filteredNotifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            notification={notification}
            checked={checkedNotifications.includes(notification)}
            onChange={notificationCheckChangeHandler}
          />
        ))}
      </div>
      {/* //Notifications list */}
    </div>
  );
};

export default Notifications;
