import React from 'react';

interface PublicContainerProps {
  children: React.ReactNode;
}

const PublicContainer: React.FC<PublicContainerProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-neutral-50">
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {children}
      </main>
    </div>
  );
};

export default PublicContainer;
