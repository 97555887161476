export const DEFAULT_FIRESTORE_COLLECTION_NAMES = {
  ASSETS: process.env.ASSETS_FIRESTORE_COLLECTION || 'prod-assets',
  CLUSTERS: process.env.CLUSTERS_FIRESTORE_COLLECTION || 'clusters',
  EDIT_REQUESTS:
    process.env.EDIT_REQUESTS_FIRESTORE_COLLECTION || 'edit_requests',
  HUMAN_ANNOTATIONS:
    process.env.HUMAN_ANNOTATIONS_FIRESTORE_COLLECTION || 'human_annotations',
  MEMBERSHIPS: process.env.MEMBERSHIPS_FIRESTORE_COLLECTION || 'memberships',
  ORGANIZATIONS:
    process.env.ORGANIZATIONS_FIRESTORE_COLLECTION || 'organizations',
  RUNS: process.env.REACT_APP_RUNS_FIRESTORE_COLLECTION || 'cluster_runs',
  SCORES: process.env.SCORES_FIRESTORE_COLLECTION || 'scores-prod',
};

function isValidCollectionName(
  key: string
): key is keyof typeof DEFAULT_FIRESTORE_COLLECTION_NAMES {
  return key in DEFAULT_FIRESTORE_COLLECTION_NAMES;
}

export const FIRESTORE_COLLECTION_NAMES = {
  ...DEFAULT_FIRESTORE_COLLECTION_NAMES,
};

const V1_LIBRARIES = ['assets', 'assets-dev'];

export const ASSET_LIBRARY_VERSION = () =>
  FIRESTORE_COLLECTION_NAMES.ASSETS in V1_LIBRARIES ? 'v1' : 'v2';

export const FIRESTORE_COLLECTION_NAME_OVERRIDES: Partial<
  Record<keyof typeof DEFAULT_FIRESTORE_COLLECTION_NAMES, string>
> = {};

window.FIRESTORE_COLLECTION_NAMES = FIRESTORE_COLLECTION_NAMES;

if (
  window.location.search.toUpperCase().includes('FIRESTORE_COLLECTION_NAMES')
) {
  const urlParams = new URLSearchParams(window.location.search);
  for (const [key, value] of urlParams) {
    // if the key is FIRESTORE_COLLECTION_NAMES
    const upperKey = key.toUpperCase();
    if (upperKey.startsWith('FIRESTORE_COLLECTION_NAMES.')) {
      const [, name] = upperKey.split('.', 2);
      if (isValidCollectionName(name)) {
        // parse the value and assign it to FIRESTORE_COLLECTION_NAMES
        FIRESTORE_COLLECTION_NAMES[name] = FIRESTORE_COLLECTION_NAME_OVERRIDES[
          name
        ] = value;
      } else {
        console.warn(
          "Unknown collection name '%s' in FIRESTORE_COLLECTION_NAMES query string",
          name
        );
      }
    }
  }
}

console.log(
  '****** Default collection names in use. Inspect window global FIRESTORE_COLLECTION_NAMES for details.'
);
console.log(
  '****** FIRESTORE_COLLECTION_NAMES is a live variable and can be modified in the console.'
);
console.log(
  '****** You can also set a query parameter in the URL to override the default collection names.'
);
console.log(
  '****** Example: ?FIRESTORE_COLLECTION_NAMES.ASSETS=custom_assets_collection_name'
);

const defaultConfig = {
  editConfigOptions: {
    targetResolution: {
      width: {
        min: 480,
        max: 3840,
        step: 1,
      },
      height: {
        min: 360,
        max: 2160,
        step: 1,
      },
    },
    clipBuffer: {
      min: 0,
      max: 1,
      step: 0.1,
    },
  },
  editConfigSelections: {
    face_tracking: false,
    captioning: false,
    scaling: 'letterbox',
    target_resolution: [608, 1080],
    maintain_height: true,
    tracking_resolution: [1280, 720],
    buffer: 0.6,
    slideshow: false,
  },
  FIRESTORE_COLLECTION_NAMES,
  search: {
    type: 'similarity',
    localK: 250,
    localKOptions: [10, 50, 100, 250, 500],
  },
};

export default defaultConfig;
