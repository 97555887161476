import cx from 'classnames';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AssetCollection } from 'shared-models';
import Switch from 'src/routes/shared-components/Switch';
import { useAuth } from './authProvider';

type CollectionsProviderProps = {
  children: ReactNode;
};

type CollectionsContextType = {
  showCollectionsModalForAsset: (assetId: string) => void;
  editableCollections: AssetCollection[];
};

const initialContext: CollectionsContextType = {
  async showCollectionsModalForAsset() {
    throw new Error('not yet available.');
  },
  editableCollections: [],
};

const CollectionsContext =
  createContext<CollectionsContextType>(initialContext);

export const CollectionsProvider: React.FC<CollectionsProviderProps> = ({
  children,
}) => {
  const [activeAsset, setActiveAsset] = useState<string | null>(null);
  const [editableCollections, setEditableCollections] = useState<
    AssetCollection[]
  >([]);
  const { user, machineApi } = useAuth();
  useEffect(() => {
    if (!user) {
      setEditableCollections([]);
      return;
    }

    let ignore = false;

    (async () => {
      try {
        const { results } = await machineApi.getCollections({
          onlyEditable: true,
        });
        if (!ignore) setEditableCollections(results);
      } catch (err) {
        console.error('Failed to load editable collections', err);
        throw err;
      }
    })();

    return () => {
      ignore = true;
    };
  }, [user, machineApi]);
  const toggleAsset = useCallback(
    async (
      {
        collectionId,
        assetId,
      }: {
        collectionId: string;
        assetId: string;
      },
      value: boolean
    ) => {
      if (value) {
        await machineApi.addVideosToCollection(collectionId, [assetId]);
      } else {
        await machineApi.removeVideosFromCollection(collectionId, [assetId]);
      }
      const { results } = await machineApi.getCollections({
        onlyEditable: true,
      });
      setEditableCollections(results);
    },
    [user, machineApi]
  );
  const showCollectionsModalForAsset = async (assetId: string) => {
    setActiveAsset(assetId);
  };
  const value: CollectionsContextType = {
    showCollectionsModalForAsset,
    editableCollections,
  };
  return (
    <CollectionsContext.Provider value={value}>
      <CollectionsModal
        activeAsset={activeAsset}
        onClose={() => setActiveAsset(null)}
        editableCollections={editableCollections}
        toggleAsset={toggleAsset}
      />
      {children}
    </CollectionsContext.Provider>
  );
};

const CollectionsModal: React.FC<{
  activeAsset: string | null;
  onClose: () => void;
  editableCollections: AssetCollection[];
  toggleAsset: (args: { collectionId: string; assetId: string }) => unknown;
}> = ({ activeAsset, onClose, editableCollections, toggleAsset }) => {
  return (
    <div
      className={cx(
        { hidden: !activeAsset },
        'fixed flex justify-center items-center z-50 top-0 left-0 w-full h-screen bg-neutral-60/70'
      )}
    >
      <div className="w-[484px] rounded-[10px] bg-neutral-40 shadow-lg overflow-hidden">
        <div className="h-16 px-6 flex justify-between items-center border-b border-neutral-110 bg-neutral-80">
          <span className="font-source_serif_pro text-xl text-neutral-170">
            Manage collections
          </span>
          <i
            onClick={onClose}
            className="block w-4 h-4 bg-ico-close bg-center bg-no-repeat bg-contain cursor-pointer"
          ></i>
        </div>
        <div className="py-5 px-7">
          {activeAsset &&
            editableCollections.map((collection) => (
              <Switch
                key={collection.id}
                value={collection.asset_ids.includes(activeAsset)}
                label={collection.title}
                onChange={toggleAsset.bind(this, {
                  assetId: activeAsset,
                  collectionId: collection.id,
                })}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export const useCollectionsContext = (): CollectionsContextType => {
  const context = useContext(CollectionsContext);
  if (context === undefined) {
    throw new Error(
      'useCollectionsContext must be used within a CollectionsProvider'
    );
  }
  return context;
};
