import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

const schema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  topic: yup.string().required('Please select a topic'),
  message: yup.string().required('Message is required'),
});

type FormData = yup.InferType<typeof schema>;

const Contact: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    setIsSubmitting(true);
    setSubmitError(null);
    setSubmitSuccess(false);

    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (file) {
        formData.append('attachment', file);
      }

      await axios.post(
        process.env.REACT_APP_CONTACTUS_FORM_ENDPOINT!,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      setSubmitSuccess(true);
      reset();
      setFile(null);
    } catch (error) {
      setSubmitError(
        'An error occurred while submitting the form. Please try again.'
      );
      console.error('Form submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <div>
      <div className="block font-source_serif_pro text-xl text-neutral-170 mb-10">
        Contact us
      </div>
      <div className="flex justify-between gap-x-32">
        <div className="w-full max-w-96 shrink">
          <span className="block font-source_serif_pro text-[40px] leading-[48px] text-neutral-170 mb-3">
            We'd love to hear from you
          </span>
          <p className="font-inter text-lg text-neutral-180">
            Whether it's feedback, a question about The Machine or bug
            reporting, you can use this form to get in touch. We'll get back to
            you as soon as possible.
          </p>
        </div>
        <div className="grow w-full max-w-[484px] shrink-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex gap-x-5 justify-between mb-8">
              <label className="w-1/2" htmlFor="first_name">
                <span className="block font-inter text-base text-neutral-190 mb-2">
                  First name*
                </span>
                <input
                  {...register('first_name')}
                  className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                  type="text"
                  id="first_name"
                  placeholder="John"
                />
                {errors.first_name && (
                  <span className="text-red-500">
                    {errors.first_name.message}
                  </span>
                )}
              </label>

              <label className="w-1/2" htmlFor="last_name">
                <span className="block font-inter text-base text-neutral-190 mb-2">
                  Last name*
                </span>
                <input
                  {...register('last_name')}
                  className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                  type="text"
                  id="last_name"
                  placeholder="Doe"
                />
                {errors.last_name && (
                  <span className="text-red-500">
                    {errors.last_name.message}
                  </span>
                )}
              </label>
            </div>

            <div className="block mb-8">
              <label className="w-full" htmlFor="email">
                <span className="block font-inter text-base text-neutral-190 mb-2">
                  Email*
                </span>
                <input
                  {...register('email')}
                  className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                  type="email"
                  id="email"
                  placeholder="johndoe@example.com"
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email.message}</span>
                )}
              </label>
            </div>

            <div className="block mb-8">
              <label className="w-full" htmlFor="topic">
                <span className="block font-inter text-base text-neutral-190 mb-2">
                  Topic
                </span>
                <select
                  {...register('topic')}
                  className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                  id="topic"
                >
                  <option value="">Select a topic</option>
                  <option value="feedback">Feedback</option>
                  <option value="question">Question</option>
                  <option value="bug">Bug Report</option>
                </select>
                {errors.topic && (
                  <span className="text-red-500">{errors.topic.message}</span>
                )}
              </label>
            </div>

            <div className="block mb-8">
              <label className="w-full" htmlFor="message">
                <span className="block font-inter text-base text-neutral-190 mb-2">
                  Message*
                </span>
                <textarea
                  {...register('message')}
                  className="transition-all w-full h-28 bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                  id="message"
                  placeholder="Tell us what's on your mind"
                ></textarea>
                {errors.message && (
                  <span className="text-red-500">{errors.message.message}</span>
                )}
              </label>
            </div>

            <div className="block mb-8">
              <label className="w-full">
                <span className="block font-inter text-base text-neutral-190 mb-2">
                  Attachments
                </span>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="transition-all w-full bg-white py-3 px-4 border border-dashed border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                />
              </label>
            </div>

            <div className="block mb-8">
              <button
                type="submit"
                disabled={isSubmitting}
                className="transition-colors flex justify-between items-center gap-x-3 rounded-[10px] bg-green-100 py-3 px-4 hover:bg-green-120 disabled:bg-gray-400"
              >
                <span className="font-inter text-base text-white">
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </span>
                <i className="block w-5 h-5 bg-ico-arrow-right bg-center bg-no-repeat bg-contain"></i>
              </button>
            </div>
          </form>

          {submitError && <p className="text-red-500 mt-4">{submitError}</p>}
          {submitSuccess && (
            <p className="text-green-500 mt-4">Form submitted successfully!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
