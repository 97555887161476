import VideoTile from '@/components/zeitgeist/videoTile';
import EICluster, { Article, Asset } from '@/interfaces/Cluster';
import '@/styles/components/clusterDetail.scss';
import { Dispatch, SetStateAction, useState } from 'react';
import featureFlags from 'src/featureFlags';

interface EIClusterDetail {
  cluster: EICluster;
  isOpen: boolean;
  runName?: string;
}

const EIClusterDetail: React.FC<EIClusterDetail> = ({
  cluster,
  runName = '',
  isOpen,
}) => {
  const [showFullArticles, setShowFullArticles] = useState<boolean>(false);
  const [showFullNarrativeFacts, setShowFullNarrativeFacts] = useState<boolean>(false);
  const [showFullNarrativeLeft, setShowFullNarrativeLeft] = useState<boolean>(false);
  const [showFullNarrativeRight, setShowFullNarrativeRight] = useState<boolean>(false);
  const [showFullTopics, setShowFullTopics] = useState<boolean>(false);
  const [showFullAssets, setShowFullAssets] = useState<boolean>(false);
  const narrative = cluster.interpretation.narrative;
  const showDebug = featureFlags.ZEITGEIST.DEBUG || process.env.ZEITGEIST_DEBUG;

  const articleIconMap = new Map([
    ['AP', 'bg-source-ap'],
    ['Breitbart', 'bg-source-breitbart'],
    ['Business Insider', 'bg-source-bi'],
    ['CBS', 'bg-source-cbs'],
    ['CNN', 'bg-source-cnn'],
    ['Daily Caller', 'bg-source-dc'],
    ['Daily Kos', 'bg-source-dk'],
    ['Daily Wire', 'bg-source-dw'],
    ['Fox News', 'bg-source-fox'],
    ['HuffPost', 'bg-source-hp'],
    ['Mother Jones', 'bg-source-mj'],
    ['MSNBC', 'bg-source-msnbc'],
    ['New York Times', 'bg-source-nyt'],
    ['Newsmax', 'bg-source-newsmax'],
    ['Newsweek', 'bg-source-newsweek'],
    ['NPR', 'bg-source-npr'],
    ['Reuters', 'bg-source-reuters'],
    ['The Washington Post', 'bg-source-wapo'],
    ['TIME', 'bg-source-time'],
  ]);

  const getDisplayValues = <T,>(
    truncated: boolean,
    value: T[],
    truncatedLength: number
  ): T[] => (truncated ? value.slice(0, truncatedLength) : value);

  const truncatedLengths = {
    articles: 10,
    narrative: 200, // Shorter per section since we now have three
    topics: 12,
    assets: 6,
  };

  const isTruncated = {
    articles: !showFullArticles && cluster.articles.length > truncatedLengths.articles,
    narrativeFacts: narrative.facts.length > truncatedLengths.narrative,
    narrativeLeft: narrative.left.length > truncatedLengths.narrative,
    narrativeRight: narrative.right.length > truncatedLengths.narrative,
    topics: !showFullTopics && cluster.topics.length > truncatedLengths.topics,
    assets: !showFullAssets && cluster.assets.length > truncatedLengths.assets,
  };

  const getTruncatedText = (text: string, showFull: boolean): string => {
    if (text.length <= truncatedLengths.narrative || showFull) {
      return text;
    }
    return text.slice(0, truncatedLengths.narrative) + '...';
  };

  const displayValues = {
    articles: getDisplayValues<Article>(
      isTruncated.articles,
      cluster.articles,
      truncatedLengths.articles
    ),
    topics: getDisplayValues<string>(
      isTruncated.topics,
      cluster.topics,
      truncatedLengths.topics
    ),
    assets: getDisplayValues<Asset>(
      isTruncated.assets,
      cluster.assets,
      truncatedLengths.assets
    ),
  };

  const getDisplayButton = (
    truncated: boolean,
    setShow: Dispatch<SetStateAction<boolean>>,
    fullLength: number,
    maxLength: number
  ) => {
    return fullLength > maxLength ? (
      <span
        className="show_more"
        onClick={() => {
          if (truncated) {
            setShow(true);
          } else {
            setShow(false);
          }
        }}
      >
        {truncated ? 'Show all' : 'Show fewer'}
      </span>
    ) : (
      <></>
    );
  };

  const getArticleIcon = (article: Article) => {
    const fetchedIconClass = articleIconMap.get(article.source);
    return fetchedIconClass ? fetchedIconClass : 'bg-ico-newspaper';
  };

  const NarrativeSection = ({ 
    title, 
    content, 
    showFull, 
    setShowFull, 
    isTruncated 
  }: { 
    title: string;
    content: string;
    showFull: boolean;
    setShowFull: Dispatch<SetStateAction<boolean>>;
    isTruncated: boolean;
  }) => (
    <div className="narrative-section">
      <h4 className="narrative-title">{title}</h4>
      <p className="narrative-content">
        {getTruncatedText(content, showFull)}
        {isTruncated && !showFull && (
          <span className="show_more" onClick={() => setShowFull(true)}>
            Show more
          </span>
        )}
      </p>
    </div>
  );

  return isOpen ? (
    <div className={`cluster_detail ${isOpen ? '' : 'collapsed'}`}>
      {showDebug && (
        <a
          target="_blank"
          href={`/cluster-debug/${runName}/${cluster.id}`}
          className="debug"
        >
          Debug
        </a>
      )}
      <div className="detail_content">
        <div className="section">
          <div className="subject">{cluster.interpretation.subject}</div>
          <div className="summary section_content">
            <NarrativeSection
              title="Key Facts"
              content={narrative.facts}
              showFull={showFullNarrativeFacts}
              setShowFull={setShowFullNarrativeFacts}
              isTruncated={isTruncated.narrativeFacts}
            />
            <div className="narrative-perspectives">
              <NarrativeSection
                title="Left Perspective"
                content={narrative.left}
                showFull={showFullNarrativeLeft}
                setShowFull={setShowFullNarrativeLeft}
                isTruncated={isTruncated.narrativeLeft}
              />
              <NarrativeSection
                title="Right Perspective"
                content={narrative.right}
                showFull={showFullNarrativeRight}
                setShowFull={setShowFullNarrativeRight}
                isTruncated={isTruncated.narrativeRight}
              />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="section_header">
            Topics
            {getDisplayButton(
              isTruncated.topics,
              setShowFullTopics,
              cluster.topics.length,
              truncatedLengths.topics
            )}
          </div>
          <div className="topics_list section_content">
            {displayValues.topics.map((topic) => (
              <div key={`${cluster.id}-${topic}`} className="topic_pill">
                {topic}
              </div>
            ))}
          </div>
        </div>

        <div className="section">
          <div className="section_header">
            Articles ({cluster.articles.length})
            {getDisplayButton(
              isTruncated.articles,
              setShowFullArticles,
              cluster.articles.length,
              truncatedLengths.articles
            )}
          </div>

          <div className="articles section_content">
            <div className="articles_list">
              {displayValues.articles.map((article, index) => (
                <a
                  href={article.url}
                  key={`${cluster.id}-${article.id}-${index}`}
                  className={`article_pill ${getArticleIcon(article)}`}
                  target="_blank"
                >
                  {article.headline}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="section">
          <div className="section_header">
            Related Videos ({cluster.assets.length})
            {getDisplayButton(
              isTruncated.assets,
              setShowFullAssets,
              cluster.assets.length,
              truncatedLengths.assets
            )}
          </div>
          <div className="videos">
            <div className="videos_list">
              {displayValues.assets.map((video) => (
                <VideoTile key={`${cluster.id}-${video.id}`} video={video} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default EIClusterDetail;
