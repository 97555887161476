import React from 'react';
import Asset from 'src/interfaces/Asset';

interface Props {
  metadata: Asset;
  jumpToClip: (start_time: number) => void;
}

const TranscriptHighlights: React.FC<Props> = ({ metadata, jumpToClip }) => {
  if (!metadata?.annotations?.key_moments?.length) {
    return null;
  }

  return (
    <div className="block font-inter text-xs text-neutral-190 flex flex-wrap gap-1 mb-2.5">
      <span className="shrink-0 font-bold">Transcript highlights: </span>
      {metadata.annotations.key_moments.map((moment, index) => (
        <span 
          key={index}
          className="underline text-neutral-170 group/transcript cursor-pointer relative"
        >
          {moment.description}
          <div className="hidden absolute top-0 w-auto rounded-md bg-neutral-190/85 overflow-hidden group-hover/transcript:block">
            <ul>
              <li
                onClick={() => jumpToClip(moment.timestamp)}
                className="block font-inter text-xs text-white py-1.5 px-2 hover:bg-white/20"
              >
                Jump to clip
              </li>
            </ul>
          </div>
        </span>
      ))}
    </div>
  );
};

export default TranscriptHighlights;
