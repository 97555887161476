import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  videoUrl: yup.string().url('Invalid URL').required('Video URL is required'),
  additionalContext: yup.string(),
});

type FormData = yup.InferType<typeof schema>;

const VideoSubmission: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    setIsSubmitting(true);
    setSubmitError(null);
    setSubmitSuccess(false);

    try {
      await axios.post(
        process.env.REACT_APP_VIDEO_SUBMISSION_FORM_ENDPOINT!,
        data,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      setSubmitSuccess(true);
      reset();
    } catch (error) {
      setSubmitError(
        'An error occurred while submitting the form. Please try again.'
      );
      console.error('Form submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="block font-source_serif_pro text-xl text-neutral-170 mb-10">
        Video Submission
      </div>
      <div className="flex justify-between gap-x-32">
        <div className="w-full max-w-96 shrink">
          <span className="block font-source_serif_pro text-[40px] leading-[48px] text-neutral-170 mb-3">
            {submitSuccess ? 'Video Submitted' : 'Submit Your Video'}
          </span>
          <p className="font-inter text-lg text-neutral-180">
            {submitSuccess
              ? 'Thanks for submitting! We will get this loaded into the machine.'
              : "Please provide your details and the URL of your video submission. You can also add any additional context about your video if you'd like."}
          </p>
        </div>

        <div className="grow w-full max-w-[484px] shrink-0">
          {!submitSuccess && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="block mb-8">
                <label className="w-full" htmlFor="name">
                  <span className="block font-inter text-base text-neutral-190 mb-2">
                    Name*
                  </span>
                  <input
                    {...register('name')}
                    className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                    type="text"
                    id="name"
                    placeholder="Jane Doe"
                  />
                  {errors.name && (
                    <span className="text-red-500">{errors.name.message}</span>
                  )}
                </label>
              </div>

              <div className="block mb-8">
                <label className="w-full" htmlFor="email">
                  <span className="block font-inter text-base text-neutral-190 mb-2">
                    Email*
                  </span>
                  <input
                    {...register('email')}
                    className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                    type="email"
                    id="email"
                    placeholder="janedoe@example.com"
                  />
                  {errors.email && (
                    <span className="text-red-500">{errors.email.message}</span>
                  )}
                </label>
              </div>

              <div className="block mb-8">
                <label className="w-full" htmlFor="videoUrl">
                  <span className="block font-inter text-base text-neutral-190 mb-2">
                    Video URL*
                  </span>
                  <input
                    {...register('videoUrl')}
                    className="transition-all w-full bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                    type="url"
                    id="videoUrl"
                    placeholder="https://youtube.com/your-video"
                  />
                  {errors.videoUrl && (
                    <span className="text-red-500">
                      {errors.videoUrl.message}
                    </span>
                  )}
                </label>
              </div>

              <div className="block mb-8">
                <label className="w-full" htmlFor="additionalContext">
                  <span className="block font-inter text-base text-neutral-190 mb-2">
                    Additional Context (Optional)
                  </span>
                  <textarea
                    {...register('additionalContext')}
                    className="transition-all w-full h-28 bg-white py-3 px-4 border border-neutral-110 rounded-md font-inter text-base text-neutral-140 focus:ring-0 focus:border-green-100"
                    id="additionalContext"
                    placeholder="Provide any additional information about your video submission"
                  ></textarea>
                  {errors.additionalContext && (
                    <span className="text-red-500">
                      {errors.additionalContext.message}
                    </span>
                  )}
                </label>
              </div>

              <div className="block mb-8">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="transition-colors flex justify-between items-center gap-x-3 rounded-[10px] bg-green-100 py-3 px-4 hover:bg-green-120 disabled:bg-gray-400"
                >
                  <span className="font-inter text-base text-white">
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </span>
                  <i className="block w-5 h-5 bg-ico-arrow-right bg-center bg-no-repeat bg-contain"></i>
                </button>
              </div>
            </form>
          )}

          {submitError && <p className="text-red-500 mt-4">{submitError}</p>}
        </div>
      </div>
    </div>
  );
};

export default VideoSubmission;
