import React from 'react';
import TopicPill from './TopicPill';

interface VibesProps {
  vibes: string[] | undefined;
  topics: string[] | undefined;
}

const Vibes: React.FC<VibesProps> = ({ vibes, topics }) => {
  const pills: string[] = [];

  if (vibes && vibes.length > 0) {
    const vibesToAdd = vibes.slice(0, 3);
    vibesToAdd.forEach((vibe) => {
      const capitalizedVibe = vibe.charAt(0).toUpperCase() + vibe.slice(1);
      pills.push(capitalizedVibe);
    });
  }

  if (topics && topics.length > 0) {
    const topicsToAdd = topics.slice(0, 3);
    topicsToAdd.forEach((topic) => {
      if (pills.includes(topic)) return;
      pills.push(topic);
    });
  }

  return (
    <div className="flex gap-2 flex-wrap">
      {pills.map((pill) => (
        <TopicPill key={pill} label={pill} />
      ))}
    </div>
  );
};

export default Vibes;
