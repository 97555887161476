import { getAuth, onAuthStateChanged } from 'firebase/auth';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import EnforceLogin from './components/EnforceLogin';
import PageContainer from './components/PageContainer';
import PublicContainer from './components/PublicContainer';
import { AuthProvider } from './contexts/authProvider';
import { EditorConfigProvider } from './contexts/editorConfigProvider';
import { NotificationsProvider } from './contexts/notificationsProvider';
const Root = lazy(() => import('./routes/root'));
const Zeitgeist = lazy(() => import('./routes/zeitgeist'));
const EDayZeitgeist = lazy(() => import('./routes/eday_zeitgeist'));
const ClusterDebug = lazy(() => import('./routes/cluster_debug'));
const RequestAccess = lazy(() => import('./routes/request_access'));
const Contact = lazy(() => import('./routes/contact'));
const Notifications = lazy(() => import('./routes/notifications'));
const HowTo = lazy(() => import('./routes/how-to'));
const Terms = lazy(() => import('./routes/terms'));
const VideoSubmission = lazy(() => import('./routes/video_submission'));
const NotFound = lazy(() => import('./routes/not-found'));

// Todo: figure out fails with privacy info
import { CollectionsProvider } from './contexts/collectionsProvider';
import { PrivacyInfo } from './routes';
import Analytics from './utils/analytics';

const App: React.FC = () => {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        Analytics.setUser(user);
        Analytics.log('login', {
          method: user.providerData[0]?.providerId || 'unknown',
        });
      } else {
        Analytics.log('logout');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthProvider>
      <PostHogProvider client={posthog}>
        <NotificationsProvider>
          <EditorConfigProvider>
            <CollectionsProvider>
              <Router>
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    {/* Public routes */}
                    <Route
                      path="/request_access"
                      element={
                        <PublicContainer>
                          <RequestAccess />
                        </PublicContainer>
                      }
                    />

                    {/* Protected routes */}
                    <Route
                      path="/"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <Root />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/search/:searchQuery"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <Root />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/zeitgeist"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <Zeitgeist />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/election"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <EDayZeitgeist />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/cluster-debug/:runId/:clusterId"
                      element={
                        <EnforceLogin>
                          <ClusterDebug />
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/notifications"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <Notifications />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/how-to"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <HowTo />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/privacy"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <PrivacyInfo />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/terms"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <Terms />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/video_submission"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <VideoSubmission />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="/contact"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <Contact />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />

                    <Route
                      path="*"
                      element={
                        <EnforceLogin>
                          <PageContainer>
                            <NotFound />
                          </PageContainer>
                        </EnforceLogin>
                      }
                    />
                  </Routes>
                </Suspense>
              </Router>
            </CollectionsProvider>
          </EditorConfigProvider>
        </NotificationsProvider>
      </PostHogProvider>
    </AuthProvider>
  );
};

export default App;
