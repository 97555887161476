import { doc, getDoc } from 'firebase/firestore';
import ForerunnerDB from 'forerunnerdb';
import {
  ASSET_LIBRARY_VERSION,
  FIRESTORE_COLLECTION_NAMES,
} from 'src/defaults';
import { db } from 'src/firebase';
import Asset from 'src/interfaces/Asset';
import ScoreData from 'src/interfaces/ScoreData';
import Utterance from 'src/interfaces/UtteranceWithId';

const frn = new ForerunnerDB(),
  fdb = frn.db('machine');

export const videoMetaDataDb = fdb.collection('videoMetaData', {
  primaryKey: 'id',
});

window.fdb = videoMetaDataDb;

async function fetchAsset(id: string, relevance: number): Promise<Asset> {
  // insert a record with the ID immediately even if the data is not yet available
  // relevance is also stored, but will be overwritten on successive pinecone fetches
  videoMetaDataDb.insert({ id, relevance });

  const [machine_annotations, human_annotations, score_data] =
    await Promise.all([
      getMachineAnnotations(id),
      ASSET_LIBRARY_VERSION() == 'v1'
        ? getHumanAnnotations(id)
        : Promise.resolve(undefined),
      ASSET_LIBRARY_VERSION() == 'v1'
        ? getScores(id)
        : Promise.resolve(undefined),
    ]);
  const asset = {
    relevance,
    ...machine_annotations,
  };
  asset.score_data ??= score_data;
  asset.human_annotations ??= human_annotations;

  videoMetaDataDb.update({ id }, asset);
  return asset;
}

async function getMachineAnnotations(id: string) {
  const docRef = doc(db, FIRESTORE_COLLECTION_NAMES.ASSETS, id),
    docSnap = await getDoc(docRef);
  const docSnapData = docSnap.data();
  if (!docSnapData) {
    console.warn('Received missing document ID %s', id);
    return undefined as unknown as Asset;
  }

  const version = ASSET_LIBRARY_VERSION();

  // Temporary hack for unique IDs on utterances
  for (const transcription of [
    docSnapData.transcription,
    docSnapData.source_asset_transcription,
  ]) {
    transcription?.forEach((utterance: Utterance) => {
      utterance.id = `${id}|${Math.round(utterance.start_time * 1000)}|${Math.round(utterance.end_time * 1000)}`;
      utterance.assetId = id;
      utterance.word_timings = undefined; // save memory by removing word timings

      // For v2 assets, we need to pull in the speaker name from the speaker ID.
      if (utterance.speaker_id) {
        utterance.speaker =
          docSnapData.annotations.speakers?.[utterance.speaker_id];
      }
    });
  }

  // Sometimes speaker arrays have duplicate 'Unknowns'
  if (docSnapData.annotations && docSnapData.annotations.speakers) {
    if (version === 'v1') {
      docSnapData.annotations.speakers = Array.from(
        new Set(
          docSnapData.annotations.speakers.filter(
            (speaker: string) => speaker !== 'Unknown'
          )
        )
      );
    }
    if (version === 'v2') {
      docSnapData.annotations.speakers = Array.from(
        new Set(Object.values(docSnapData.annotations.speakers))
      );
    }
  }

  return docSnapData as Asset;
}

async function getHumanAnnotations(id: string) {
  const { unusedPreview, ...human_annotations } =
    (
      await getDoc(doc(db, FIRESTORE_COLLECTION_NAMES.HUMAN_ANNOTATIONS, id))
    ).data() ?? {};
  return human_annotations;
}

async function getScores(id: string) {
  try {
    const scoreDocRef = doc(db, FIRESTORE_COLLECTION_NAMES.SCORES, id);
    const scoreDocSnap = await getDoc(scoreDocRef);

    if (scoreDocSnap.exists()) {
      const data = scoreDocSnap.data() as ScoreData;
      // console.log(`Scoredata found for asset ${id}:`, data);
      return data;
    } else {
      // console.warn(`No document found for asset ${id}`);
    }
  } catch (error) {
    console.error('Error fetching scores:', error);
    // Optionally, implement retry logic here
  }
}

// Relevance is passed in here, based on the index of the assetID in the latest
// pinecone search results. We use that to order results for queries.
export const getVideoMetadata = async (videoId: string, relevance: number) => {
  // check the videoMetaData DB for the video metadata
  const videoMetaDataRecord = videoMetaDataDb.findById(videoId);
  if (videoMetaDataRecord) {
    // update the relevance of the video metadata
    videoMetaDataDb.updateById(videoId, { relevance });
    videoMetaDataRecord.relevance = relevance;
    return videoMetaDataRecord;
  }
  return await fetchAsset(videoId, relevance);
};
