import React from 'react';
import Asset from 'src/interfaces/Asset';
import TopicPill from './TopicPill';

interface Props {
  metadata: Asset;
}

const AnnotationsTab: React.FC<Props> = ({ metadata }) => {
  const annotations = metadata.annotations;
  if (!annotations) {
    return null;
  }
  const score_data = metadata.score_data;
  return (
    <>
      <div className="flex items-start border-b border-neutral-110 p-4">
        <div className="w-1/3 font-inter font-semibold text-sm">
          Title
        </div>
        <div className="w-2/3 font-inter font-normal text-sm">
          {annotations.title}
        </div>
      </div>

      <div className="flex items-start border-b border-neutral-110 p-4">
        <div className="w-1/3 font-inter font-semibold text-sm">
          Description
        </div>
        <div className="w-2/3 font-inter font-normal text-sm">
          {annotations.description}
        </div>
      </div>

      <div className="flex items-start border-b border-neutral-110 p-4">
        <div className="w-1/3 font-inter font-semibold text-sm">Topics</div>
        <div className="w-2/3">
          <div className="flex gap-2 flex-wrap">
            {annotations.topics &&
              annotations.topics.map((topic, index) => (
                <TopicPill key={index} label={topic} />
              ))}
          </div>
        </div>
      </div>

      <div className="flex items-start border-b border-neutral-110 p-4">
        <div className="w-1/3 font-inter font-semibold text-sm">Subtopics</div>
        <div className="w-2/3">
          <div className="flex gap-2 flex-wrap">
            {annotations.subtopics &&
              annotations.subtopics.map((subtopic, index) => (
                <TopicPill key={index} label={subtopic} />
              ))}
          </div>
        </div>
      </div>

      <div className="flex items-start border-b border-neutral-110 p-4">
        <div className="w-1/3 font-inter font-semibold text-sm">Speakers</div>
        <div className="w-2/3">
          <div className="flex gap-2 flex-wrap">
            {annotations.speakers &&
              annotations.speakers.map((speaker, index) => (
                <TopicPill key={index} label={speaker} />
              ))}
          </div>
        </div>
      </div>

      <div className="flex items-start border-b border-neutral-110 p-4">
        <div className="w-1/3 font-inter font-semibold text-sm">Vibes</div>
        <div className="w-2/3">
          <div className="flex gap-2 flex-wrap">
            {annotations.vibes &&
              annotations.vibes.map((vibe, index) => (
                <TopicPill key={index} label={vibe} />
              ))}
          </div>
        </div>
      </div>

      {score_data && (
        <div className="flex items-start p-4">
          <div className="w-1/3 font-inter font-semibold text-sm">
            Blue Rose Score
          </div>
          <div className="w-2/3">
            <span className="bg-green-100 rounded-full text-white text-sm py-1 px-2">
              {score_data.percentiles.everyone}/100
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AnnotationsTab;
