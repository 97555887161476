import React from 'react';
import EventBus from 'src/utils/eventBus';
import AssetLibraryParams from 'src/interfaces/AssetLibraryParams';

interface SelectedFilterPillsProps {
  params: AssetLibraryParams;
}

interface PillProps {
  params: AssetLibraryParams;
  field: 'topics' | 'vibes' | 'speakers';
  value: string;
}

const Pill: React.FC<PillProps> = ({ params, field, value }) => {
  const clickHandler = () => {
    EventBus.$emit(
      `params:${field}`,
      params[field].filter((val: string) => val !== value)
    );
  };
  return (
    <div
      className="transition-colors cursor-pointer flex gap-x-2.5 items-center py-2 px-3 rounded-[10px] border border-green-100 bg-green-100 font-inter text-white text-base tracking-wide"
      onClick={clickHandler}
    >
      <span>{value}</span>
      <i className="block w-3 h-3 bg-ico-close-white bg-center bg-no-repeat bg-cover cursor-pointer"></i>
    </div>
  );
};

const SelectedFilterPills: React.FC<SelectedFilterPillsProps> = ({
  params,
}) => {
  const topicPills = params.topics.map((topic) => (
      <Pill
        key={`topic:${topic}`}
        field="topics"
        value={topic}
        params={params}
      />
    )),
    vibePills = params.vibes.map((vibe) => (
      <Pill key={`vibes:${vibe}`} field="vibes" value={vibe} params={params} />
    )),
    speakerPills = params.speakers.map((speaker) => (
      <Pill
        key={`speakers:${speaker}`}
        field="speakers"
        value={speaker}
        params={params}
      />
    )),
    clearBrs = () => {
      EventBus.$emit(`params:brs`, 0);
    },
    brsPill = (
      <div
        className="transition-colors cursor-pointer flex gap-x-2.5 items-center py-2 px-3 rounded-[10px] border border-green-100 bg-green-100 font-inter text-white text-base tracking-wide"
        onClick={clearBrs}
      >
        <span>{params.brs}% or above</span>
        <i className="block w-3 h-3 bg-ico-close-white bg-center bg-no-repeat bg-cover cursor-pointer"></i>
      </div>
    );

  return (
    <div className="w-full flex flex-wrap items-start gap-2 pt-3">
      {topicPills}
      {vibePills}
      {speakerPills}
      {params.brs > 0 && brsPill}
    </div>
  );
};

export default SelectedFilterPills;
