import TermsAcknowledgement from '@/components/TermsAcknowledgement';
import pluralize from 'pluralize';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEditorConfigContext } from 'src/contexts/editorConfigProvider';
import DEFAULTS from 'src/defaults';
import { default as featureFlags, default as FLAGS } from 'src/featureFlags';
import Asset from 'src/interfaces/Asset';
import {
  default as AssetLibraryParams,
  default as Params,
} from 'src/interfaces/AssetLibraryParams';
import Analytics from 'src/utils/analytics';
import { getActiveAssetLibrary } from 'src/utils/api';
import EventBus from 'src/utils/eventBus';
import { OptionalVideoIdSearchParams } from 'src/utils/machineApi';
import { useAuth } from '../../contexts/authProvider';
import { getVideoMetadata, videoMetaDataDb } from '../../utils/videoMetadataDb';
import AdvancedSettings from './components/AdvancedSettings';
import EditDialog from './components/edit-dialog';
import Filters from './components/Filters';
import Loading from './components/Loading';
import Modal from './components/Modal';
import QuickSearch from './components/QuickSearch';
import SearchDropdown from './components/searchDropdown';
import Trending from './components/Trending';
import VideoList from './components/VideoList';

const Root: React.FC = () => {
  /**
   *
   * Assets are tracked in three states:
   * - assetIds is an array of all pinecone asset IDs returned by the keyword
   * - assets is an object with asset IDs as keys and optional metadata as values
   * - filteredAssets is an array of assets with optional metadata
   */


  const timeFilterOptions = [
    { name: 'Anytime', value: '' },
    { name: 'Last 24 hours', value: '1' },
    { name: 'Last 3 days', value: '3' },
    { name: 'Last 7 days', value: '7' },
    { name: 'Last month', value: '30' },
  ];

  const searchScoreOptions = [
    {
      name: 'Any Blue Rose Score',
      value: '',
    },
    {
      name: '75% and above',
      value: '75',
    },
    {
      name: '50% and above',
      value: '50',
    },
    {
      name: '25% and above',
      value: '25',
    },
  ];

  const defaultParams: AssetLibraryParams = {
      topics: [],
      vibes: [],
      speakers: [],
      brs: 0,
      sort: 'rel',
    },
    idMatcher = /^[a-f0-9]{64}$/,
    collectionMatcher = /^collection:([-\w]+)$/,
    navigate = useNavigate(),
    location = useLocation(),
    searchParams = new URLSearchParams(location.search),
    { searchQuery: searchQueryInRoute } = useParams(),
    isSearchPath = searchQueryInRoute !== undefined,
    [assetIds, setAssetIds] = useState<string[]>([]),
    [isSingleId, setIsSingleId] = useState<boolean>(
      !!(searchQueryInRoute || '').match(idMatcher)
    ),
    [assets, setAssets] = useState<{ [key: string]: Asset }>({}),
    [filteredAssets, setFilteredAssets] = useState<Asset[]>([]),
    [metadataLoaded, setMetadataLoaded] = useState(false),
    [params, setParams] = useState<Params>({ ...defaultParams }),
    [percentLoaded, setPercentLoaded] = useState(0),
    [searchQuery, setSearchQuery] = useState(searchQueryInRoute || ''),
    [doQuickSearch, setDoQuickSearch] = useState<boolean>(false),
    [timeRange, setTimeRange] = useState<string>(''),
    [searchScore, setSearchScore] = useState<string>(''),
    [lastSearchQuery, setLastSearchQuery] = useState(''),
    [localSearchType, setLocalSearchType] = useState<string>(
      DEFAULTS.search.type
    ),
    [localK, setLocalK] = useState<number>(DEFAULTS.search.localK),
    [loading, setLoading] = useState(false),
    [settingsOpen, setSettings] = useState(false),
    [globallyExpanded, setGlobalExpanded] = useState(false),
    { machineApi } = useAuth();

  const editorConfig = useEditorConfigContext(),
     handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault();

      // Create URL with search parameters
      const params = new URLSearchParams();
      if (timeRange) {
        params.set('timeRange', timeRange);
      }
      if (searchScore) {
        params.set('searchScore', searchScore);
      }

      const searchPath = `/search/${encodeURIComponent(searchQuery)}${
        params.toString() ? '?' + params.toString() : ''
      }`;

      navigate(searchPath);
    },
    //handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    //  evt.preventDefault();
    //  navigate(`/search/${searchQuery}`);
    //},
    doSearch = async () => {
      console.log('Form submitted');
      const theSingleVideo = { id: searchQuery };
      const isSingle = !!searchQuery.match(idMatcher);
      setIsSingleId(isSingle); // we need this outside this function as well

      setLoading(true);
      setAssetIds([]);
      setAssets({});
      setLastSearchQuery(searchQuery);
      setMetadataLoaded(false);
      setParams({ ...defaultParams });
      setPercentLoaded(0);
      editorConfig.resetConfig();
      if (loading) return;

      try {
        let results = null;
        if (isSingle) {
          results = [theSingleVideo];
        } else {
          const collectionMatch = searchQuery.match(collectionMatcher);
          if (collectionMatch) {
            const collection = await machineApi.getCollection(
              collectionMatch[1]
            );
            if (collection.asset_library != getActiveAssetLibrary()) {
              console.error(
                "Collection '%s' uses asset library '%s' but active asset library is '%s'. Some assets may not render correctly.",
                collection.id,
                collection.asset_library,
                getActiveAssetLibrary()
              );
            }
            results = collection.asset_ids.map((id) => ({ id }));
          } else {
            results = await machineApi.fetchVideoIds({
              searchQuery,
              localSearchType,
              localK,
              ...getQueryParameters(),
            });
          }
        }
        setLoading(false);
        setAssetIds(results.map((asset) => asset.id));
        setAssets(
          results.reduce(
            (acc, asset) => {
              acc[asset.id] = { id: asset.id };
              return acc;
            },
            {} as { [key: string]: Asset }
          )
        );
        Promise.all(
          results.map((asset, index) =>
            getVideoMetadata(asset.id, index).then((metadata: Asset) => {
              setAssets((prev) => ({ ...prev, [asset.id]: metadata }));
            })
          )
        ).then(() => {
          setMetadataLoaded(true);
          if (results.length === 1) {
            setGlobalExpanded(true);
          }
        });

        if (results.length > 0) {
          Analytics.log('search', { searchQuery, localSearchType, localK });
        } else {
          Analytics.log('search_error', { searchQuery, error: 'No results' });
        }
      } catch (err) {
        Analytics.log('search_error', { searchQuery, error: err });
        setLoading(false);
        setAssetIds([]);
      }
    },
    handleSettings = (evt: React.MouseEvent) => {
      evt.preventDefault();
      setSettings(!settingsOpen);
    };

  // Update percent loaded for use in filter component
  useEffect(() => {
    if (assetIds.length === 0) {
      setPercentLoaded(0);
      return;
    }
    const metaLoaded = Object.values(assets).filter(
      (obj) => obj.annotations
    ).length;
    setPercentLoaded(Math.round((metaLoaded / assetIds.length) * 100));
  }, [assets]);

  // The actual filtering of assets is performed here
  useEffect(() => {
    // If there is no search query, we return early, to prevent
    // the entire metadataDb from being shown when switching back
    // to this component after a search
    if (assetIds.length === 0) {
      return;
    }
    let query = [{ id: { $in: assetIds } }] as any;
    const opts = {} as any;

    if (params.brs !== 0) {
      query.push({
        score_data: { percentiles: { everyone: { $gte: params.brs } } },
      });
    }
    if (params.topics.length > 0) {
      query.push({
        annotations: {
          topics: { $in: params.topics.map((topic) => new RegExp(topic, 'i')) },
        },
      });
    }
    if (params.vibes.length > 0) {
      query.push({
        annotations: {
          vibes: { $in: params.vibes.map((vibe) => new RegExp(vibe, 'i')) },
        },
      });
    }
    if (params.speakers.length > 0) {
      query.push({
        annotations: {
          speakers: {
            $in: params.speakers.map((speaker) => new RegExp(speaker, 'i')),
          },
        },
      });
    }

    switch (params.sort) {
      case 'brs':
        opts.$orderBy = { score_data: { percentiles: { everyone: -1 } } };
        break;
      case 'date':
        opts.$orderBy = { date_added: -1 };
        break;
      default:
        opts.$orderBy = { relevance: 1 };
        break;
    }
    query = { $and: query };
    const result = videoMetaDataDb.find(query, opts);
    console.log('Ran forerunner query: ', query, opts);
    setFilteredAssets(result);
  }, [params, assets]);

  let statusMessage = <></>;
  if (filteredAssets.length > 0) {
    if (isSingleId) {
      statusMessage = (
        <>
          Video&nbsp;
          <code className="overflow-ellipsis overflow-hidden">
            {lastSearchQuery}
          </code>
        </>
      );
    } else {
      statusMessage = (
        <>{`${pluralize('result', filteredAssets.length, true)} for "${lastSearchQuery}" found.`}</>
      );
    }
  }

  const updateParams = useCallback(
    (key: string, value: string[] | number) => {
      const newParams = { ...params, [key]: value };
      setParams(newParams);
    },
    [params]
  );

  useEffect(() => {
    EventBus.$on('params:topics', (value: string[]) => {
      updateParams('topics', value);
    });
    EventBus.$on('params:vibes', (value: string[]) => {
      updateParams('vibes', value);
    });
    EventBus.$on('params:speakers', (value: string[]) =>
      updateParams('speakers', value)
    );
    EventBus.$on('params:brs', (value: number) => updateParams('brs', value));
    EventBus.$on('params:clear', () => setParams({ ...defaultParams }));

    EventBus.$on('reset:search', () => {
      setSearchQuery('');
      setAssetIds([]);
      setAssets({});
      setParams({ ...defaultParams });
    });

    return () => {
      EventBus.$off('params:topics');
      EventBus.$off('params:vibes');
      EventBus.$off('params:speakers');
      EventBus.$off('params:brs');
      EventBus.$off('params:clear');
      EventBus.$off('reset:search');
    };
  }, [params]);

  // Lastly, handle the fetch if this is a search path
  //useEffect(() => {
  //  if (isSearchPath) {
  //    doSearch();
  //  }
  //}, [location]);

  useEffect(() => {
    if (isSearchPath) {
      // Get time range and search score from URL if present
      const urlTimeRange = searchParams.get('timeRange');
      const urlSearchScore = searchParams.get('searchScore');

      // Update state if URL parameters exist
      if (urlTimeRange) {
        setTimeRange(urlTimeRange);
      }
      if (urlSearchScore) {
        setSearchScore(urlSearchScore);
      }

      doSearch();
    }
  }, [location]);

  useEffect(() => {
    if (doQuickSearch) {
      setDoQuickSearch(false);
      doSearch();
    }
  }, [doQuickSearch]);

  //function getQueryParameters(): OptionalVideoIdSearchParams {
  //  const params = {} as OptionalVideoIdSearchParams;

  // // if (timeRange) {
  //    params.since = getEpochSeconds(timeRange).toString();
  //  }
  //  if (searchScore) {
  //    params.with_score = searchScore;
  //  }

  //  return params;
  //}

  function getQueryParameters(): OptionalVideoIdSearchParams {
    const params = {} as OptionalVideoIdSearchParams;

    // Get timeRange from either state or URL
    const effectiveTimeRange = searchParams.get('timeRange') || timeRange;
    const effectiveSearchScore = searchParams.get('searchScore') || searchScore;

    if (effectiveTimeRange) {
      params.since = getEpochSeconds(effectiveTimeRange).toString();
    }
    if (effectiveSearchScore) {
      params.with_score = effectiveSearchScore;
    }

    return params;
  }

  function getEpochSeconds(strVal: string) {
    let seconds = 0;
    const val = parseInt(strVal);

    if (val) {
      const diffInSeconds = 60 * 60 * 24 * val;
      const enwDate = new Date().getTime() / 1000;

      seconds = enwDate - diffInSeconds;
    }

    return seconds;
  }

  return (
    <div>
      <Modal />

      {editorConfig.active && <EditDialog />}

      {!editorConfig.active && (
        <>
          {/* Search */}
          <p className="text-neutral-190 text-[22px] mb-6 font-source_serif_pro">
            Search our video database for the clips you need
          </p>

          <AdvancedSettings
            localK={localK}
            setK={setLocalK}
            searchType={localSearchType}
            setSearchType={setLocalSearchType}
            open={settingsOpen}
          />

          <div className="mb-16">
            <div className="block relative">
              <form onSubmit={handleSubmit}>
                <input
                  type="search"
                  name="query"
                  value={searchQuery}
                  onChange={(evt) => setSearchQuery(evt.target.value)}
                  className="block mb-3 w-full rounded-[10px] border border-neutral-190 appearance-auto hover:appearance-none py-6 pl-16 pr-20 bg-white outline-0 ring-0 text-[22px] leading-none font-inter text-neutral-190 placeholder:text-neutral-190"
                  placeholder="Search a keyword to find relevant clips"
                />
                <button
                  type="button"
                  onClick={handleSettings}
                  className="block absolute top-1/2 left-4 -translate-y-1/2 w-8 h-8 bg-ico-search bg-no-repeat bg-center"
                ></button>
                <button
                  type="submit"
                  className="transition-colors block absolute top-1.5 right-1.5 w-[72px] h-16 rounded-[10px] bg-green-100 bg-ico-arrow-right bg-center bg-no-repeat hover:bg-green-120"
                ></button>
              </form>
            </div>

            { assetIds.length === 0 && metadataLoaded && (
              <div className="font-inter text-[20px] leading-[28px] text-neutral-160">
                No results were found for “{searchQuery}”. Try a different search or remove filters.
              </div>
            )}

            <div className="flex gap-x-2">
              {featureFlags.ASSET_SEARCH.DATE && assetIds.length > 0 && (
                <SearchDropdown
                  onChange={(value: string) => setTimeRange(value)}
                  selected={timeRange}
                  options={timeFilterOptions}
                />
              )}
              {featureFlags.ASSET_SEARCH.SCORE && assetIds.length > 0 && (
                <SearchDropdown
                  onChange={(value: string) => setSearchScore(value)}
                  selected={searchScore}
                  options={searchScoreOptions}
                />
              )}
            </div>
            <QuickSearch
              isSearching={!!assetIds.length}
              doQuickSearch={() => setDoQuickSearch(true)}
              setSearchScore={setSearchScore}
              setSearchQuery={setSearchQuery}
              setTimeRange={setTimeRange}
            />
          </div>

          {/* //Search */}

          {FLAGS.ASSET_LIBRARY.TRENDING && assetIds.length > 0 && <Trending />}

          {assetIds.length > 0 && (
            <Filters
              assets={assets}
              filteredAssets={filteredAssets}
              metadataLoaded={metadataLoaded}
              percentLoaded={percentLoaded}
              params={params}
              setParams={setParams}
              statusMessage={statusMessage}
              showAnnotations={globallyExpanded}
              setShowAnnotations={setGlobalExpanded}
            />
          )}

          {!loading && !!filteredAssets.length && !assetIds.length && (
            <div className="text-neutral-190 font-source_serif_pro text-xl mb-4">
              Your previous search
            </div>
          )}

          {/* Results */}
          <div className="flex flex-wrap gap-3">
            {loading && <Loading />}
            {!loading && assetIds.length > 0 && (
              <VideoList
                assets={filteredAssets}
                forceExpanded={globallyExpanded}
              />
            )}
          </div>
          {/* //Results */}
        </>
      )}
      <TermsAcknowledgement />
    </div>
  );
};

export default Root;
