import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { EditRequestNotification } from 'src/interfaces/EditRequestNotification';
import { useAuth } from 'src/contexts/authProvider';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { FIRESTORE_COLLECTION_NAMES } from 'src/defaults';

type NotificationsContextType = {
  notifications: EditRequestNotification[];
};

const initialContext: NotificationsContextType = {
  notifications: [],
};

const NotificationsContext =
  createContext<NotificationsContextType>(initialContext);

type NotificationsProviderProps = {
  children: ReactNode;
};

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<EditRequestNotification[]>(
      []
    ),
    { user } = useAuth();

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (user) {
      const notificationQuery = query(
        collection(db, FIRESTORE_COLLECTION_NAMES.EDIT_REQUESTS),
        where('userId', '==', user.uid)
      );

      unsubscribe = onSnapshot(
        notificationQuery,
        (querySnapshot) => {
          const newNotifications: EditRequestNotification[] = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (!data.dismissed) {
              newNotifications.push({
                id: doc.id,
                assetId: data.assetId,
                title: data.title || null,
                status: data.status,
                createdAt: data.createdAt,
                read: data.read || false,
                editedVideoUrl: data.editedVideoUrl || null,
                gcsUri: data.gcsUri || null,
                dismissed: data.dismissed || false,
              });
            }
          });

          setNotifications(
            newNotifications.sort(
              (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()
            )
          );
        },
        (error) => {
          console.error('Error fetching notifications:', error);
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  const value: NotificationsContextType = {
    notifications,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = (): NotificationsContextType => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationsContext must be used within an NotificationsProvider'
    );
  }
  return context;
};
