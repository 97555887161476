import Spinner from './Spinner';
import { useAuth } from '../contexts/authProvider';
import Login from './Login';
import { useEffect } from 'react';
import Analytics from '../utils/analytics';
import { useLocation } from 'react-router-dom';

const EnforceLogin: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, loading } = useAuth();

  /**
   *
   * Log pageviews on route changes
   *
   */
  const location = useLocation();

  useEffect(() => {
    Analytics.log('page_view', {
      page_path: location.pathname,
      page_title: document.title,
    });
  }, [location]);

  if (loading) {
    return <Spinner />;
  }

  if (!user) {
    return <Login />;
  }

  return <>{children}</>;
};

export default EnforceLogin;
