import Spinner from '@/components/Spinner';
import Cluster from '@/interfaces/Cluster';
import { getAuth } from 'firebase/auth';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FIRESTORE_COLLECTION_NAMES } from 'src/defaults';
import { db } from 'src/firebase';
import QuickSearchTile from './QuickSearchTile';

interface QuickSearch {
  isSearching: boolean;
  doQuickSearch: () => void;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  setSearchScore: Dispatch<SetStateAction<string>>;
  setTimeRange: Dispatch<SetStateAction<string>>;
}

const QuickSearch: React.FC<QuickSearch> = ({
  isSearching,
  doQuickSearch,
  setSearchQuery,
  setSearchScore,
  setTimeRange,
}) => {
  const auth = getAuth();
  const [clusters, setClusters] = useState<Cluster[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const clusterCollectionName = FIRESTORE_COLLECTION_NAMES.CLUSTERS;
  const runsCollectionName = FIRESTORE_COLLECTION_NAMES.RUNS;

  useEffect(() => {
    fetchLatestRun();
  }, []);

  useEffect(() => {
    if (clusters) {
      setIsLoading(false);
    }
  }, [clusters]);

  const fetchClusters = async (runId: string) => {
    const _clusters: Cluster[] = [];

    const clustersRef = collection(
      db,
      `${runsCollectionName}/${runId}/${clusterCollectionName}`
    );

    const clusterQuery = query(
      clustersRef,
      orderBy('article_count', 'desc'),
      where('article_count', '>', 3),
      limit(3)
    );

    const clustersSnapshot = await getDocs(clusterQuery);

    clustersSnapshot.forEach((clusterDocument) => {
      const cluster = {
        ...clusterDocument.data(),
        id: clusterDocument.id,
      } as Cluster;

      _clusters.push(cluster);
    });

    setClusters(_clusters);
  };

  const fetchLatestRun = async () => {
    if (!auth.currentUser) {
      return;
    }

    if (!runsCollectionName) {
      console.error('No collection name provided');
      return;
    }

    // Get run
    const runsRef = collection(db, runsCollectionName);
    const runsQuery = query(
      runsRef,
      orderBy('timestamp', 'desc'),
      where('window', '==', 1),
      limit(1)
    );
    const runSnapshot = await getDocs(runsQuery);

    if (runSnapshot.size > 0) {
      await fetchClusters(runSnapshot.docs[0].id);
    }
  };

  const DefaultSearchTile = (term: string): JSX.Element => {
    return (
      <div
        className="px-4 py-3 rounded-lg bg-neutral-100 border border-neutral-110 text-neutral-160 font-inter leading-[1.4rem] cursor-pointer"
        onClick={() => {
          setSearchQuery(term);
          setTimeRange('1');
          setSearchScore('');
          doQuickSearch();
        }}
      >
        {term} last 24 hours
      </div>
    );
  };

  const content = !isLoading ? (
    <div>
      <div className="mt-12 text-neutral-190 font-source_serif_pro text-xl">
        Quick Search
      </div>
      <div className="flex mt-4 gap-x-[10px]">
        {DefaultSearchTile('Kamala Harris')}
        {DefaultSearchTile('Donald Trump')}
      </div>
      <div className="flex gap-x-5 mt-8">
        {clusters?.map((cluster, index) => (
          <QuickSearchTile
            key={cluster.id}
            cluster={cluster}
            index={index}
            doQuickSearch={doQuickSearch}
            setSearchQuery={setSearchQuery}
            setSearchScore={setSearchScore}
            setTimeRange={setTimeRange}
          />
        ))}
      </div>
    </div>
  ) : (
    <Spinner />
  );

  return isSearching ? <></> : content;
};

export default QuickSearch;
