import AssetLibraryParams from '@/interfaces/AssetLibraryParams';
import Cluster from '@/interfaces/Cluster';
import pluralize from 'pluralize';
import React, { Dispatch, SetStateAction } from 'react';
import FilterItemTags from 'src/routes/root/components/FilterItemTags';
import SelectedFilterPills from 'src/routes/root/components/SelectedFilterPills';
import EventBus from 'src/utils/eventBus';

interface ZFilters {
  filteredClusters: Cluster[];
  params: AssetLibraryParams;
  showFilters: boolean;
  topics: string[];
  setShowFilters: Dispatch<SetStateAction<boolean>>;
}

const ZFilters: React.FC<ZFilters> = ({
  filteredClusters,
  params,
  showFilters,
  topics,
  setShowFilters,
}) => {
  const filtersActive = !!params.topics.length;

  return (
    <div>
      {/* Filters Layer */}
      <div
        className={`transition-all overflow-y-scroll duration-300 fixed z-50 top-0 pl-10 pr-20 pt-28 w-96 h-screen bg-neutral-40 shadow-filters ${showFilters ? 'right-0' : '-right-full'}`}
      >
        <div className="pb-10 border-b border-neutral-110">
          <div className="w-full flex items-center gap-x-4">
            <span className="font-source_serif_pro text-xl">All Filters</span>
            <span className="font-inter text-base text-neutral-140">
              {pluralize('result', filteredClusters.length, true)}
            </span>
            <i
              onClick={() => setShowFilters(!showFilters)}
              className="ml-auto block w-4 h-4 bg-ico-close bg-center bg-no-repeat bg-contain cursor-pointer"
            ></i>
          </div>

          <SelectedFilterPills params={params} />
        </div>

        <div className="flex flex-col">
          <FilterItemTags
            pool={topics}
            label="Topic"
            selected={params.topics}
            eventDomain="params:topics"
          />
        </div>
      </div>
      {/* Filters Layer */}

      {filtersActive && (
        <div className="my-10">
          <div className="text-neutral-190 text-[22px] font-source_serif_pro">
            Filters
            <a
              className="cursor-pointer inline-block font-inter ml-10 text-[16px] text-green-100"
              onClick={() => EventBus.$emit('params:clear')}
            >
              Clear all
            </a>
          </div>
          <SelectedFilterPills params={params} />
        </div>
      )}

      {filtersActive && (
        <div className="flex items-center justify-between font-inter text-base text-neutral-170 mb-2.5">
          <div className="flex max-w-96 gap-x-1">
            {filteredClusters.length} results
          </div>
          <div className="shrink-0 flex items-center gap-x-6"></div>
        </div>
      )}
    </div>
  );
};

export default ZFilters;
