import Cluster from '@/interfaces/Cluster';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FIRESTORE_COLLECTION_NAMES } from 'src/defaults';
import { db } from 'src/firebase';

interface ZProps {}

interface KeyValuePair {
  [key: string]: unknown;
}

const ClusterDebug: React.FC<ZProps> = () => {
  const runsCollectionName = FIRESTORE_COLLECTION_NAMES.RUNS;
  const params = useParams();
  const [cluster, setCluster] = useState<Cluster>();

  useEffect(() => {
    const fetchCluster = async () => {
      const clustersRef = doc(
        db,
        `${runsCollectionName}/${params.runId}/clusters/${params.clusterId}`
      );

      const document = await getDoc(clustersRef);
      const data = document.data() as Cluster;

      // Put full articles into the existing object
      for (const article of data.articles) {
        const fullArticleRef = doc(db, `articles/${article.id}`);
        const fullArticle = await getDoc(fullArticleRef);
        article.fullArticle = fullArticle.data();
      }

      const cluster: Cluster = {
        ...data,
      } as Cluster;

      setCluster(cluster);
    };

    fetchCluster();
  }, []);

  const css = `
    pre {
      max-width: 100vw;
      word-wrap: break-word;
      white-space: break-spaces;
    }
  `;

  const replacer = (key: string, value: string | number | [] | object) => {
    const replaceDate = ['date_added', 'pub_date'];
    if (replaceDate.includes(key) && typeof value === 'number') {
      value = new Date(value).toString();
    } else if (value instanceof Object && !(value instanceof Array)) {
      value = Object.keys(value)
        .sort()
        .reduce((sorted, key) => {
          sorted[key] = (value as KeyValuePair)[key];
          return sorted;
        }, {} as KeyValuePair);
    }

    return value;
  };

  return (
    <div
      className="cluster_debug"
      style={{ padding: '16px', maxWidth: '100vw' }}
    >
      <style>{css}</style>

      <pre>{JSON.stringify(cluster, replacer, 4)}</pre>
    </div>
  );
};

export default ClusterDebug;
